import ClientService from 'services/api/ClientService';
import { AppThunk, AppDispatch } from 'store/store';
import {
  getAccountManagersRequest,
  getAccountManagersSucceess,
  getAccountManagersFailure,
  getAccountManagerByIdRequest,
  getAccountManagerByIdSucceess,
  getAccountManagerByIdFailure,
  setEnableAccountManagerRequest,
  setEnableAccountManagerSucceess,
  setEnableAccountManagerFailure,
  createAccountManagerRequest,
  createAccountManagerSucceess,
  createAccountManagerFailure,
  isClientExistsRequest,
  isClientExistsSucceess,
  isClientExistsFailure,
  updateAccountManagerRequest,
  updateAccountManagerSucceess,
  updateAccountManagerFailure,
  deleteAccountManagerRequest,
  deleteAccountManagerSucceess,
  deleteAccountManagerFailure,
  getAccountManagersBaseSucceess,
  getAccountManagersBaseRequest,
  getAccountManagersBaseFailure,
} from './accountManagerSlice';
import AccountManagerService from 'services/api/AccountManagerService';
import { AccountManagerForm } from 'core/account-manager/AccountManager';

const getAccountManagers = (page: number, itemsPerPage: number): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getAccountManagersRequest());
    const response = await AccountManagerService.getAccountManagers(page, itemsPerPage);
    dispatch(getAccountManagersSucceess({
      accountManagers: response.data.data,
      totalItems: response.data.totalItems
    }));
  } catch (err) {
    dispatch(getAccountManagersFailure(err.toString()));
  }
};

const getAccountManagersBase = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getAccountManagersBaseRequest());
    const response = await AccountManagerService.getAccountManagersBase();
    dispatch(getAccountManagersBaseSucceess({
      accountManagers: response.data,
    }));
  } catch (err) {
    dispatch(getAccountManagersBaseFailure(err.toString()));
  }
};

const getAccountManagerById = (id: string): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getAccountManagerByIdRequest());
    const response = await AccountManagerService.getAccountManagerById(id);
    dispatch(getAccountManagerByIdSucceess(response.data));
  } catch (err) {
    dispatch(getAccountManagerByIdFailure(err.toString()));
  }
};

const createAccountManager = (data: AccountManagerForm) => async (dispatch: AppDispatch) => {
  try {
    dispatch((createAccountManagerRequest()));
    const response = await AccountManagerService.createAccountManager(data);
    dispatch(createAccountManagerSucceess({ message: response.data.message || '' }));
  } catch (err) {
    dispatch(createAccountManagerFailure(err.message));
  }
};


const updateAccountManager = (data: AccountManagerForm, id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(updateAccountManagerRequest());
    const response = await AccountManagerService.updateAccountManager(data, id);
    dispatch(updateAccountManagerSucceess({ message: response.data.message || '' }));
  } catch (err) {
    dispatch(updateAccountManagerFailure(err.message));
  }
};

const deleteAccountManager = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(deleteAccountManagerRequest());
    await AccountManagerService.deleteAccountManager(id);
    dispatch(deleteAccountManagerSucceess());
  } catch (err) {
    dispatch(deleteAccountManagerFailure(err.message));
  }
};

const setEnableAccountManager = (id: string, isActive: boolean) => async (dispatch: AppDispatch) => {
  try {
    dispatch((setEnableAccountManagerRequest()));
    await AccountManagerService.setEnable(id, isActive);
    dispatch(setEnableAccountManagerSucceess());
  } catch (err) {
    dispatch(setEnableAccountManagerFailure());
  }
};

const checkIsClientExist = (email: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isClientExistsRequest());
    const response = await ClientService.getClientByEmail(email);
    dispatch(isClientExistsSucceess(response.data.data[0] || null));
  } catch (err) {
    dispatch(isClientExistsFailure(err.message));
  }
};

export {
  getAccountManagers,
  getAccountManagersBase,
  getAccountManagerById,
  createAccountManager,
  updateAccountManager,
  setEnableAccountManager,
  deleteAccountManager,
  checkIsClientExist,
};
