import ApiMethods from 'core/shared/enum/ApiMethod';

import http from './request';
import ActivityStatus from 'core/shared/enum/ActivityStatus';
import { Client, ClientForm } from 'core/client/Client';
import { GetClientsResponse, GetClientByIdResponse } from 'core/client/ClientResponse';


const getClients = (accountManager: string, status: ActivityStatus, organization: string, page: number, itemsPerPage: number): Promise<GetClientsResponse> => {
  const offset = itemsPerPage * (page - 1);
  const accountManagerId = (accountManager === 'ALL' || accountManager === 'MY') ? '' : `&accountManagerId=${accountManager}`;
  const organizationId = (organization === 'ALL') ? '' : `&organizationId=${organization}`;
  const url = `/users/clients?my=${accountManager === 'MY'}&active=${status === ActivityStatus.ACTIVE}${accountManagerId}${organizationId}`;

  return http.request({
    url: `${url}&max=${itemsPerPage}&offset=${offset}`,
    method: ApiMethods.GET,
  });
};

const getClientByEmail = (email: string): Promise<GetClientsResponse> => {
  let url = `/users/clients?email=${email}`;

  return http.request({
    url: url.replace('+', '%2B'),
    method: ApiMethods.GET,
  });
};

const getClientById = (id: string): Promise<GetClientByIdResponse> => {
  return http.request({
    url: `/users/clients/${id}`,
    method: ApiMethods.GET,
  });
};

const createClient = (data: ClientForm): Promise<{ data: { data: Client, message: string | null }}> => {
  return http.request({
    url: '/users/clients',
    method: ApiMethods.POST,
    data,
  });
};

const updateClient = (data: ClientForm, id: string): Promise<{ data: { data: Client, message: string | null }}> => {
  return http.request({
    url: `/users/clients/${id}`,
    method: ApiMethods.PUT,
    data,
  });
};

const setEnableClient = (id: string, isActive: boolean): Promise<{ data: Client }> => {
  return http.request({
    url: `/users/clients/${id}/enable?enabled=${isActive}`,
    method: ApiMethods.POST,
  });
};

const deleteClient = (id: string): Promise<{}> => {
  return http.request({
    url: `/users/clients/${id}`,
    method: ApiMethods.DELETE,
  });
};

const ClientService = {
  getClients,
  getClientById,
  setEnableClient,
  createClient,
  updateClient,
  deleteClient,
  getClientByEmail
};

export default ClientService;
