import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { PageHeader, Layout, Table, Button, Space, Form, Select, Radio } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { Organization } from 'core/organization/Organization';
import RequestStatus from 'core/shared/enum/RequestStatus';
import UserRole from 'core/shared/enum/UserRole';
import ActivityStatus from 'core/shared/enum/ActivityStatus';
import { selectOrganizations, selectOrganizationsItemsPerPage, selectOrganizationsPage, selectOrganizationsStatus, selectOrganizationsTotalItems } from 'store/organization/organizationSelector';
import { selectAccountManagersBase, selectAccountManagersBaseStatus } from 'store/account-manager/accountManagerSelector';
import { selectUserData } from 'store/user/userSelector';
import { selectOrganizationStatusOF, selectAccountManagerOF } from 'store/filter/filterSelector';
import { setOrganizationStatusOF, setAccountManagerOF, resetOrganizationFilters } from 'store/filter/filterSlice';
import { getOrganizations } from 'store/organization/organizationThunk';
import { getAccountManagersBase } from 'store/account-manager/accountManagerThunk';
import { updateOrganizationsPage, updateOrganizationsItemsPerPage, resetGetOrganizations } from 'store/organization/organizationSlice';
import { resetGetAccountManagersBase } from 'store/account-manager/accountManagerSlice';

import { useAppConfig } from 'components/context/AppConfigContext';

const { Content } = Layout;

const columns: ColumnsType<Organization> = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text, record) => (
      <Link to={`organizations/${record.id}`}>{record.name}</Link>
    ),
    fixed: 'left',
  },
  {
    title: 'Status',
    key: 'active',
    render: (text, record) => (
      <>
        <Space size="middle" style={{ wordBreak: "normal"}}>
          <ActiveData isActive={record.active}>{record.active ? 'Active' : 'Not active'}</ActiveData>
        </Space>
      </>
    ),
  },
  {
    title: 'Licenses count',
    dataIndex: 'licenses',
    key: 'licenses',
  },
  {
    title: 'Managed by',
    dataIndex: 'managedBy',
    render: (text, record) => (
      <span>
        <Link to={`/account-managers/${record.managedBy.id}`}>{record.managedBy.firstName} {record.managedBy.lastName}</Link>
        {' '}
        ({record.managedBy.email})
      </span>
    ),
  },
];

const OrganizationsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { routes } = useAppConfig();
  
  const userData = useSelector(selectUserData);

  const filterSetting = userData.userRole === UserRole.ROLE_ACCOUNT_MANAGER ? "MY" : "ALL";

  const status = useSelector(selectOrganizationStatusOF);
  const accountManager = useSelector(selectAccountManagerOF);

  const accountManagersStatus = useSelector(selectAccountManagersBaseStatus);
  const accountManagers = useSelector(selectAccountManagersBase);

  const organizationsStatus = useSelector(selectOrganizationsStatus);
  const organizations = useSelector(selectOrganizations);
  const organizationsPage = useSelector(selectOrganizationsPage);
  const organizationsItemsPerPage = useSelector(selectOrganizationsItemsPerPage);
  const organizationsTotalItems = useSelector(selectOrganizationsTotalItems);

  useEffect(() => {
    dispatch(getAccountManagersBase());
    return () => {
      dispatch(resetGetAccountManagersBase());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!accountManager) {
      dispatch(setAccountManagerOF(filterSetting));
    }
  }, [dispatch, filterSetting, accountManager]);

  useEffect(() => {
    if (accountManager) {
      dispatch(getOrganizations(accountManager, status, organizationsPage, organizationsItemsPerPage));
    }
  }, [dispatch, status, accountManager, organizationsPage, organizationsItemsPerPage]);

  useEffect(() => {
    return () => {
      dispatch(resetGetOrganizations());
    }
  }, [dispatch]);

  const onPageChange = (page: number) => dispatch(updateOrganizationsPage(page));

  return (
    <>
      <PageHeader
        title="Organizations"
        ghost={false}
      >
        
      </PageHeader>
      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          backgroundColor: '#fff',
        }}
      >
        <Form layout="horizontal">
          <Form.Item label="Account Managers">
            <Select
              value={accountManager}
              style={{ minWidth: '250px', maxWidth: '300px' }}
              onChange={(data) => { dispatch(updateOrganizationsPage(1)); dispatch(setAccountManagerOF(data.toString()))}} 
              loading={accountManagersStatus === RequestStatus.LOADING}
            >
              {userData.userRole === UserRole.ROLE_ACCOUNT_MANAGER && (
                <Select.Option key="MY" value="MY">
                Me
                </Select.Option>
              )}
              <Select.Option key="ALL" value="ALL">
                All
              </Select.Option>
              {accountManagers.map(item => (
                <Select.Option key={item.id} value={item.id} style={{ color: item.active ? 'inherit' : '#FF4D4F'}}>
                  {item.firstName + ' ' + item.lastName}{!item.active && ' [not active]'}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Status" style={{ marginBottom: '0' }}>
            <Radio.Group value={status} onChange={(data) => { dispatch(updateOrganizationsPage(1)); dispatch(setOrganizationStatusOF(data.target.value))}}>
              <Radio.Button value={ActivityStatus.ACTIVE}>Active</Radio.Button>
              <Radio.Button value={ActivityStatus.ALL}>All</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Button
            style={{ marginTop: '30px' }}
            type="dashed"
            onClick={() => {
              dispatch(updateOrganizationsPage(1));
              dispatch(resetOrganizationFilters());
            }}
          >
            Clear filters
            </Button>
        </Form>
      </Content>
      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          minHeight: 280,
          backgroundColor: '#fff',
        }}
      >
        <Table
          scroll={{ x: "max-content" }}
          title={() => (<TitleContainer>
            <h2>Organizations</h2>
            {userData.userRole === UserRole.ROLE_ACCOUNT_MANAGER &&
            <Link to={routes.organizationsCreate.path}>
              <Button
                type="primary"
                size="large"
              >
                <PlusOutlined />
                Add new
              </Button>
            </Link>}
          </TitleContainer>)}
          loading={organizationsStatus === RequestStatus.LOADING}
          rowKey={record => record.id}
          pagination={{
            defaultPageSize: organizationsItemsPerPage,
            current: organizationsPage,
            total: organizationsTotalItems,
            onChange: onPageChange,
            showSizeChanger: true,
            onShowSizeChange: (current, size) => {dispatch(updateOrganizationsPage(1)); dispatch(updateOrganizationsItemsPerPage(size))},
            showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`
          }}
          columns={columns}
          dataSource={organizations}
        />
      </Content>
    </>
  );
};

export default OrganizationsScreen;

const ActiveData = styled.span<{ isActive: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? '#52C41A' : '#FF4D4F')}; 
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
