import styled from '@emotion/styled/macro';
import { Button, Input, Form  } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { signIn } from 'store/user/userThunk';
import { selectAuthStatus } from 'store/user/userSelector';
import BaseLayout from 'components/layouts/BaseLayout';
import { useAppConfig } from 'components/context/AppConfigContext';

const SignInScreen: React.FC = () => {
  const dispatch = useDispatch();
  const authStatus = useSelector(selectAuthStatus);
  const { routes } = useAppConfig();

  const onFinish = (values: {username: string, password: string}) => {
    dispatch(signIn({ username: values.username, password: values.password }));
  };

  return (
    <BaseLayout>
      <Container>
        <Header1>SamanTree Medical Admin Panel</Header1>
        <Header2>License Management System</Header2>
        <Form onFinish={onFinish}>
        <Form.Item name="username" rules={[{ required: true }]} noStyle>
          <UserName
            placeholder="email"
            prefix={<UserOutlined style={{ color: '#1890FF' }} />}
            size="large"
          />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true }]} noStyle>
          <Password
            visibilityToggle={true}
            placeholder="password"
            prefix={<LockOutlined style={{ color: '#1890FF' }}/>}
            size="large"
            autoComplete="on"
          />
        </Form.Item>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={authStatus === RequestStatus.LOADING}
          block
        >
          Sign In
        </Button>
        </Form>
        <Link style={{ margin: "10px 10px", padding: "5px" }} to={routes.requestRecoverPassword.path}>
          Forgot password?
        </Link>
      </Container>
    </BaseLayout>
  );
};

export default SignInScreen;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  margin: 0 auto;
`;

const Header1 = styled.h1`
  font-size: 38px;
  margin-bottom: 12px;
  font-weight: 750;
  line-height: 1;
  color: #000000CC;
  text-align: center;
`;

const Header2 = styled.h2`
  letter-spacing: 0.005em;
  margin-bottom: 38px;
  color: #00000073;
`;

// const Form = styled.form`
//   width: 100%;
// `;

const UserName = styled(Input)`
  margin-bottom: 28px;
  font-size: 16px;
  line-height: 24px;
`;

const Password = styled(Input.Password)`
  margin-bottom: 22px;
  font-size: 16px;
  line-height: 24px;
`;