import Routes from './Route';

export interface AppConfigInterface {
  baseApiUrl: string;
  reactAppVersion: string,
  routes: typeof Routes;
  elementsPerPage: number;
}

const AppConfig: AppConfigInterface = {
  baseApiUrl: process.env.REACT_APP_API_URL,
  reactAppVersion: process.env.REACT_APP_VERSION,
  routes: Routes,
  elementsPerPage: 10,
};

export default AppConfig;
