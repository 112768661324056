import { License,LicenseForm } from 'core/license/License';
import { GetLicensesResponse } from 'core/license/LicenseResponse';
import { GetLicenseStatisticResponse } from 'core/license/LicenseResponse';
import ActivityStatus from 'core/shared/enum/ActivityStatus';
import ApiMethods from 'core/shared/enum/ApiMethod';

import http from './request';

const getLicensesFiltered = (accountManagerId: string, status: ActivityStatus, organizationId: string, productId: string, page: number, itemsPerPage: number): Promise<GetLicensesResponse> => {
  const offset = itemsPerPage * (page - 1);
  const accountManagerIdUrl = (accountManagerId === 'ALL' || accountManagerId === 'MY') ? '' : `&accountManagerId=${accountManagerId}`;
  const organizationIdUrl = (organizationId === 'ALL') ? '' : `&organizationId=${organizationId}`;
  const productIdUrl = (productId === 'ALL') ? '' : `&productId=${productId}`;
  const url = `/licenses?my=${accountManagerId === 'MY'}&active=${status === ActivityStatus.ACTIVE}${accountManagerIdUrl}${organizationIdUrl}${productIdUrl}`;

  return http.request({
    url: `${url}&max=${itemsPerPage}&offset=${offset}`,
    method: ApiMethods.GET,
  });
};

const getLicenseById = (id: string): Promise<{data: License}> => {
  return http.request({
    url: `/licenses/${id}`,
    method: ApiMethods.GET,
  });
};

const getLicenseStatistic = (id: string, page: number, itemsPerPage: number): Promise<GetLicenseStatisticResponse> => {
  const offset = itemsPerPage * (page - 1);
  return http.request({
    url: `/licenses/${id}/download-statistic?max=${itemsPerPage}&offset=${offset}`,
    method: ApiMethods.GET,
  });
};

const setEnableLicense = (id: string, isActive: boolean): Promise<{data: License}> => {
  return http.request({
    url: `/licenses/${id}/enable?enabled=${isActive}`,
    method: ApiMethods.POST,
  });
};

const createLicense = (data: LicenseForm): Promise<{ data: { data: License, message: string | null }}> => {
  const formattedData = {
    baseConfiguration: {
      count: data.baseProductCount,
      days: data.baseProductDays,
      productId: data.baseProductId,
    },
    configurations: data.configurations.map(config => {
      const { permanent, ...rest } = config;
      return {
        ...rest
      };
    }),
    description: data.description,
    name: data.name,
    organizationId: data.organizationId,
  };
  return http.request({
    url: '/licenses',
    method: ApiMethods.POST,
    data: formattedData,
  });
};

const deleteLicense = (id: string): Promise<{}> => {
  return http.request({
    url: `/licenses/${id}`,
    method: ApiMethods.DELETE,
  });
};

const LicenseService = {
  getLicensesFiltered,
  getLicenseById,
  getLicenseStatistic,
  setEnableLicense,
  createLicense,
  deleteLicense,
};

export default LicenseService;
