import { RootState } from 'store/store';

export const selectGetLicenseByOrganization = (state: RootState) => state.license.licenseList.licenses;
export const selectGetLicenseByOrganizationStatus= (state: RootState) => state.license.licenseList.getLicenseStatus;

export const selectGetLicenses = (state: RootState) => state.license.licenseList.licenses;
export const selectGetLicensesStatus = (state: RootState) => state.license.licenseList.getLicenseStatus;
export const selectLicensesPage = (state: RootState) => state.license.licenseList.page;
export const selectLicensesTotalItems = (state: RootState) => state.license.licenseList.totalItems;
export const selectLicensesItemsPerPage = (state: RootState) => state.license.licenseList.itemsPerPage;
export const selectGetLicenseByOrganizationError = (state: RootState) => state.license.licenseList.error;

export const selectGetLicenseByIdStatus = (state: RootState) => state.license.licenseById.getLicenseByIdStatus;
export const selectLicenseById = (state: RootState) => state.license.licenseById.license;
export const selectGetLicenseByIdError = (state: RootState) => state.license.licenseById.error;

export const selectGetLicenseStatisticStatus = (state: RootState) => state.license.licenseStatistic.getLicenseStatisticStatus;
export const selectLicenseStatistic = (state: RootState) => state.license.licenseStatistic.statistic;
export const selectLicenseStatisticPage = (state: RootState) => state.license.licenseStatistic.page;
export const selectLicenseStatisticTotalItems = (state: RootState) => state.license.licenseStatistic.totalItems;
export const selectLicenseStatisticPerPage = (state: RootState) => state.license.licenseStatistic.itemsPerPage;
export const selectGetLicenseStatisticError = (state: RootState) => state.license.licenseStatistic.error;

export const selectCreateLicenseStatus = (state: RootState) => state.license.licenseActions.createLicenseStatus;
export const selectSetEnableLicenseStatus = (state: RootState) => state.license.licenseActions.setEnableLicenseStatus;
export const selectDeleteLicenseStatus = (state: RootState) => state.license.licenseActions.deleteLicenseStatus;
export const selectLicenseActionsMessage = (state: RootState) => state.license.licenseActions.message;
export const selectLicenseActionsError = (state: RootState) => state.license.licenseActions.error;