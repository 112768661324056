import { useCallback, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, notification, Table, Popconfirm, Space, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link, useHistory, useParams } from "react-router-dom";
import { Typography } from 'antd';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { License } from 'core/license/License';
import UserRole from 'core/shared/enum/UserRole';
import { selectDeleteOrganizationStatus, selectOrganizationById, selectSetEnableOrganizationStatus } from 'store/organization/organizationSelector';
import { deleteOrganization, getOrganizationById, setEnableOrganization } from 'store/organization/organizationThunk';
import { resetDeleteOrganizationStatus, resetGetOrganizationById, resetSetEnableOrganizationStatus } from 'store/organization/organizationSlice';
import { selectUserData } from 'store/user/userSelector';
import { getLicensesByOrganization } from 'store/license/licenseThunk';
import { selectGetLicenseByOrganizationStatus, selectGetLicenseByOrganization, selectLicensesPage, selectLicensesTotalItems, selectLicensesItemsPerPage } from 'store/license/licenseSelector';
import { resetGetLicenses, updateLicensesItemsPerPage, updateLicensesPage } from 'store/license/licenseSlice';
import PageContainer from 'components/layouts/PageContainer';

const { Title } = Typography;

const columns: ColumnsType<License> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Status',
    key: 'active',
    render: (text, record) => (
      <>
        <Space size="middle">
          <ActiveData isActive={record.active}>{record.active ? 'Active' : 'Not active'}</ActiveData>
        </Space>
      </>
    ),
  },
  {
    title: 'Configuration',
    dataIndex: 'configuration',
      render: (text, record) => (
        <div>
          <ProductType>{record.baseConfiguration.productName}{' '}</ProductType>
          (<span>
            {!record.baseConfiguration.count && !record.baseConfiguration.days && <span>Permanent</span>}
            {record.baseConfiguration.count && <span>Count of uses: {record.baseConfiguration.count};</span>}
            {record.baseConfiguration.count && record.baseConfiguration.days && ' '}
            {record.baseConfiguration.days && <span>Days: {record.baseConfiguration.days};</span>}
          </span>)
          <span>
            {
              record.configurations.map((config, index) => (
                <div key={index}>
                  <ProductType>+ {config.productName}{' '}</ProductType>
                  (<span>
                    {!config.count && !config.days && <span>Permanent</span>}
                    {config.count && <span>Count of uses: {config.count};</span>}
                    {config.count && config.days && ' '}
                    {config.days && <span>Days: {config.days};</span>}
                  </span>)
                </div>
              ))
            }
          </span>
        </div>
      ),
  },
];

const OrganizationProfileScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams<{ id: string }>()

  const userData = useSelector(selectUserData);
  const organization = useSelector(selectOrganizationById)
  const setEnableOrganizationStatus = useSelector(selectSetEnableOrganizationStatus);
  const deleteOrganizationStatus = useSelector(selectDeleteOrganizationStatus);

  const isLoading = setEnableOrganizationStatus === RequestStatus.LOADING;
  const isSuccess = setEnableOrganizationStatus === RequestStatus.SUCCEESS;
  const isDeleteOrganizationSuccess = deleteOrganizationStatus === RequestStatus.SUCCEESS;

  const licenses = useSelector(selectGetLicenseByOrganization);
  const getLicenseByOrganizationStatus = useSelector(selectGetLicenseByOrganizationStatus);
  const licensesPage = useSelector(selectLicensesPage);
  const licensesTotalItems = useSelector(selectLicensesTotalItems);
  const licensesItemsPerPage = useSelector(selectLicensesItemsPerPage);

  const setActiveHandler = async (id: string, isActive: boolean) => {
    dispatch(setEnableOrganization(id, isActive))
  };

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: `Success`,
        description: 'Organization status changed successfully.',
        placement: 'bottomRight',
      });
      dispatch(resetSetEnableOrganizationStatus());
      dispatch(getOrganizationById(id));
      dispatch(updateLicensesPage(1));
    }
  }, [dispatch, isSuccess, id, licensesPage]);

  useEffect(() => {
    if (isDeleteOrganizationSuccess) {
      notification.success({
        message: `Success`,
        description: 'Organization successfully deleted',
        placement: 'bottomRight',
      });
      dispatch(resetDeleteOrganizationStatus());
      history.goBack();
    }
  }, [dispatch, history, isDeleteOrganizationSuccess]);

  useEffect(() => {
    dispatch(getOrganizationById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (userData.userRole === UserRole.ROLE_ACCOUNT_MANAGER) {
      dispatch(getLicensesByOrganization(id, licensesPage, licensesItemsPerPage));
    }
  }, [dispatch, id, userData.userRole, licensesPage, licensesItemsPerPage]);

  useEffect(() => {
    return () => {
      dispatch(resetGetOrganizationById());
      dispatch(resetGetLicenses());
      dispatch(updateLicensesPage(1));
    }
  }, [dispatch]);

  const onClose = useCallback(() => {
    if (history.length < 3) {
      history.push("/organizations");
    } else {
      history.goBack();
    }
  }, [history]);

  const onPageChange = (page: number) => dispatch(updateLicensesPage(page));

  return (
    <PageContainer title="Organization Profile">
      {organization &&
        <>
          <Profile>
            <Title level={3}>Organization <b>{organization.name}</b></Title>
            <Line>
              <Label>Name:</Label>
              <Info>{organization.name || '<No info>'}</Info>
            </Line>
            <Line>
              <Label>Status:</Label>
              <Info><ActiveData isActive={!!organization.active}>{!!organization.active ? 'Active' : 'Not active'}</ActiveData></Info>
            </Line>
            <Line>
              <Label>Managed by:</Label>
              <Info>
                <span>
                  <Link to={`/account-managers/${organization.managedBy.id}`}>{organization.managedBy.firstName} {organization.managedBy.lastName}</Link>
                  {' '}({organization.managedBy.email})
                  </span>
              </Info>
            </Line>
          </Profile>
          <ButtonContainer>
            {userData.userRole === UserRole.ROLE_ACCOUNT_MANAGER && (
              <>
                <Link style={{ margin: '0 10px' }} to={`${organization.id}/update`}>
                  <Button type="primary" size="middle">Update</Button>
                </Link>
                <Popconfirm
                  title="Are you sure to delete this organization?"
                  onConfirm={() => dispatch(deleteOrganization(organization.id))}
                  okText="Yes"
                  cancelText="No"
                  disabled={organization.active || organization.system}
                >
                  <Tooltip
                    title={organization.system ? "You can't delete system organization." :
                      organization.active ? "An Organization can be deleted if it was previously deactivated." : ""}
                    trigger="hover"
                    color="white"
                    overlayInnerStyle={{ color: "#000000d9", padding: "12px 16px", textAlign: "justify" }}
                  >
                    <Button
                      type="primary"
                      size="middle"
                      style={{ margin: '0 10px' }}
                      danger
                      disabled={organization.active || organization.system}
                    >
                      Delete
                    </Button>
                  </Tooltip>
                </Popconfirm>
                <Popconfirm
                  title={organization.system ? "You can't deactivate system organization."
                    : `Are you sure to ${organization?.active ? 'deactivate' : 'activate'} this organization?`}
                  onConfirm={() => setActiveHandler(organization.id, !organization.active)}
                  okText="Yes"
                  cancelText="No"
                  disabled={organization.system}
                >
                  <Tooltip
                    title={organization.system ? "You can't deactivate system organization." : ""}
                    trigger="hover"
                    color="white"
                    overlayInnerStyle={{ color: "#000000d9", padding: "12px 16px", textAlign: "justify" }}
                  >
                    <Button
                      loading={isLoading}
                      type="primary"
                      size="middle"
                      style={{
                        margin: '0 10px',
                        backgroundColor: organization.system ? 'default' : '#52C41A',
                        color: organization.system ? 'default' : 'white',
                      }}
                      disabled={organization.system}
                    >
                      {organization.active ? 'Deactivate' : 'Activate'}
                    </Button>
                  </Tooltip>
                </Popconfirm>
              </>
            )}
            <Button type="default" size="middle" style={{ margin: '0 10px' }} onClick={onClose}>Close</Button>
          </ButtonContainer>
        </>
      }
      {userData.userRole === UserRole.ROLE_ACCOUNT_MANAGER && (
        <Table
          title={() => (
            <h2>Licenses</h2>
          )}
          loading={getLicenseByOrganizationStatus === RequestStatus.LOADING}
          rowKey={record => record.id}
          pagination={{
            defaultPageSize: licensesItemsPerPage,
            current: licensesPage,
            total: licensesTotalItems,
            onChange: onPageChange,
            showSizeChanger: true,
            onShowSizeChange: (current, size) => { dispatch(updateLicensesPage(1)); dispatch(updateLicensesItemsPerPage(size)) },
            showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`
          }}
          columns={columns}
          dataSource={licenses}
        />
      )}
    </PageContainer>
  );
};

export default OrganizationProfileScreen;

const Profile = styled.div`
  width: 400px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ActiveData = styled.span<{ isActive: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? '#52C41A' : '#FF4D4F')}; 
`;

const ButtonContainer = styled.div`
  max-width: 400px;
  display: flex;
  justify-content: flex-end;
  margin: 10px auto;
`;

const Line = styled.div`
  display: flex;
`;

const Label = styled.div`
  width: 150px;
  font-weight: bold;
  line-height: 22px;
  border-bottom: 1px solid #F0F0F0;
  margin-right: 10px;
  padding: 10px 0 10px;
  text-align: right;
`;

const Info = styled.div`
  width: 240px;
  text-align: left;
  line-height: 22px;
  border-bottom: 1px solid black;
  padding: 10px 0 10px;
  border-bottom: 1px solid #F0F0F0;
  max-width: 240px;
  line-break: auto;
  word-break: break-word;
`;

const ProductType = styled.span`
  font-weight: 500;
  font-style: italic;
`;
