import { useCallback, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, notification, Popconfirm } from 'antd';
import { Link, useHistory, useParams } from "react-router-dom";
import { Typography } from 'antd';

import { selectSuperuserById, selectSetEnableSuperuserStatus, selectDeleteSuperuserStatus } from 'store/superuser/superuserSelector';
import { selectUserData } from 'store/user/userSelector';
import RequestStatus from 'core/shared/enum/RequestStatus';
import { deleteSuperuser, getSuperuserById, setEnable } from 'store/superuser/superuserThunk';
import { resetDeleteSuperuserStatus, resetGetSuperuserById, resetSetEnableSuperuserStatus } from 'store/superuser/superuserSlice';
import PageContainer from 'components/layouts/PageContainer';

const { Title } = Typography;

const SuperuserProfileScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams<{ id: string }>()

  const superuser = useSelector(selectSuperuserById);
  const userData = useSelector(selectUserData);
  const setEnableSuperuserStatus = useSelector(selectSetEnableSuperuserStatus);
  const deleteSuperuserStatus = useSelector(selectDeleteSuperuserStatus);

  const isSetEnableSuperuserLoading = setEnableSuperuserStatus === RequestStatus.LOADING;
  const isSetEnableSuperuserSuccess = setEnableSuperuserStatus === RequestStatus.SUCCEESS;
  const isDeleteSuperuserSuccess = deleteSuperuserStatus === RequestStatus.SUCCEESS;

  const setActiveHandler = async (id: string, isActive: boolean) => {
    dispatch(setEnable(id, isActive))
  };

  useEffect(() => {
    if (isSetEnableSuperuserSuccess) {
      notification.success({
        message: `Success`,
        description: 'Superuser status changed successfully.',
        placement: 'bottomRight',
      });
      dispatch(resetSetEnableSuperuserStatus());
      dispatch(getSuperuserById(id));
    }
  }, [dispatch, setEnableSuperuserStatus, isSetEnableSuperuserSuccess, id]);

  useEffect(() => {
    if (isDeleteSuperuserSuccess) {
      notification.success({
        key: 'superuser',
        message: `Success`,
        description: 'Superuser successfully deleted',
        placement: 'bottomRight',
      });
      dispatch(resetDeleteSuperuserStatus());
      history.goBack();
    }
  }, [dispatch, history, isDeleteSuperuserSuccess]);

  useEffect(() => {
    dispatch(getSuperuserById(id));
    return () => {
      dispatch(resetGetSuperuserById());
    }
  }, [dispatch, id]);

  const onClose = useCallback(() => {
    if (history.length < 3) {
      history.push("/superusers");
    } else {
      history.goBack();
    }
  }, [history]);

  return (
    <PageContainer title="Superser Profile">
      {superuser &&
        <>
          <Profile>
            <Title level={3}>Superuser <b>{superuser.username}</b></Title>
            <Line>
              <Label>Username:</Label>
              <Info>{superuser.username || '<No info>'}</Info>
            </Line>
            <Line>
              <Label>First name:</Label>
              <Info>{superuser.firstName || '<No info>'}</Info>
            </Line>
            <Line>
              <Label>Last name:</Label>
              <Info>{superuser.lastName || '<No info>'}</Info>
            </Line>
            <Line>
              <Label>Email:</Label>
              <Info>{superuser.email || '<No info>'}</Info>
            </Line>
            <Line>
              <Label>Status:</Label>
              <Info><ActiveData isActive={!!superuser.active}>{!!superuser.active ? 'Active' : 'Not active'}</ActiveData></Info>
            </Line>
            <Line>
              <Label>Contact Address:</Label>
              <Info>{superuser.contactAddress || 'No info'}</Info>
            </Line>
            <Line>
              <Label>Phone Number:</Label>
              <Info>{superuser.phoneNumber || 'No info'}</Info>
            </Line>
          </Profile>
          <ButtonContainer>
            <Link style={{ margin: '0 10px' }} to={`${superuser.id}/update`}>
              <Button type="primary" size="middle">Update</Button>
            </Link>
            {
              userData.userId !== superuser.id && (
                <>
                  <Popconfirm
                    title={`Are you sure to delete this superuser?`}
                    onConfirm={() => dispatch(deleteSuperuser(superuser.id))}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="ghost" size="middle" style={{ margin: '0 10px', backgroundColor: '#FF4D4F', color: 'white' }}>Delete</Button>
                  </Popconfirm>
                  <Popconfirm
                    title={`Are you sure to ${superuser?.active ? 'deactivate' : 'activate'} this superuser?`}
                    onConfirm={() => setActiveHandler(superuser.id, !superuser.active)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      loading={isSetEnableSuperuserLoading}
                      type="primary"
                      size="middle"
                      style={{ margin: '0 10px', backgroundColor: '#52C41A', color: 'white' }}
                    >
                      {superuser.active ? 'Deactivate' : 'Activate'}
                    </Button>
                  </Popconfirm>
                </>
              )
            }
            <Button type="default" size="middle" style={{ margin: '0 10px' }} onClick={onClose}>Close</Button>
          </ButtonContainer>
        </>
      }
    </PageContainer>
  );
};

export default SuperuserProfileScreen;

const Profile = styled.div`
  width: 400px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ActiveData = styled.span<{ isActive: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? '#52C41A' : '#FF4D4F')}; 
`;

const ButtonContainer = styled.div`
  max-width: 400px;
  display: flex;
  justify-content: flex-end;
  margin: 10px auto;
`;

const Line = styled.div`
  display: flex;
`;

const Label = styled.div`
  width: 150px;
  font-weight: bold;
  line-height: 22px;
  border-bottom: 1px solid #F0F0F0;
  margin-right: 10px;
  padding: 10px 0 10px;
  text-align: right;
`;

const Info = styled.div`
  width: 240px;
  text-align: left;
  line-height: 22px;
  border-bottom: 1px solid black;
  padding: 10px 0 10px;
  border-bottom: 1px solid #F0F0F0;
  max-width: 240px;
  line-break: auto;
  word-break: break-word;
`;
