import { updateLicensesPage, updateLicensesItemsPerPage } from 'store/license/licenseSlice';
import { updateClientsPage, updateClientsItemsPerPage } from 'store/client/clientSlice';
import { updateSuperusersPage, updateSuperusersItemsPerPage } from 'store/superuser/superuserSlice';
import { updateProductsItemsPerPage, updateProductsPage } from 'store/product/productSlice';
import { updateAccountManagersPage, updateAccountManagersItemsPerPage } from 'store/account-manager/accountManagerSlice';
import { updateOrganizationsPage, updateOrganizationsItemsPerPage } from 'store/organization/organizationSlice';
import { AppThunk, AppDispatch } from 'store/store';
import {
  resetProductsFilters,
  resetOrganizationFilters,
  resetClientsFilters,
  resetLicenseFilters,
} from './filterSlice';
import ItemsPerPage from 'core/shared/enum/ItemsPerPage';

const resetFilters = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(resetProductsFilters());
    dispatch(resetOrganizationFilters());
    dispatch(resetClientsFilters());
    dispatch(resetLicenseFilters());
    dispatch(updateSuperusersPage(1));
    dispatch(updateProductsPage(1));
    dispatch(updateAccountManagersPage(1));
    dispatch(updateOrganizationsPage(1));
    dispatch(updateClientsPage(1));
    dispatch(updateLicensesPage(1));
    dispatch(updateSuperusersItemsPerPage(ItemsPerPage.TEN));
    dispatch(updateProductsItemsPerPage(ItemsPerPage.TEN));
    dispatch(updateAccountManagersItemsPerPage(ItemsPerPage.TEN));
    dispatch(updateOrganizationsItemsPerPage(ItemsPerPage.TEN));
    dispatch(updateClientsItemsPerPage(ItemsPerPage.TEN));
    dispatch(updateLicensesItemsPerPage(ItemsPerPage.TEN));
  } catch (err) {
    console.log(err);
  }
};

export { resetFilters };
