import { RootState } from 'store/store';

export const selectOrganizationsStatus = (state: RootState) => state.organization.organizationsList.organizationsStatus;
export const selectOrganizationsPage = (state: RootState) => state.organization.organizationsList.page;
export const selectOrganizationsTotalItems = (state: RootState) => state.organization.organizationsList.totalItems;
export const selectOrganizationsItemsPerPage = (state: RootState) => state.organization.organizationsList.itemsPerPage;
export const selectOrganizations = (state: RootState) => state.organization.organizationsList.organizations;

export const selectOrganizationsBaseStatus = (state: RootState) => state.organization.organizationsBaseList.organizationsBaseStatus;
export const selectOrganizationsBase = (state: RootState) => state.organization.organizationsBaseList.organizations;
export const selectOrganizationsBaseError = (state: RootState) => state.organization.organizationsBaseList.error;

export const selectOrganizationById = (state: RootState) => state.organization.organizationById.organization;
export const selectOrganizationStatus = (state: RootState) => state.organization.organizationById.getOrganizationByIdStatus;
export const selectOrganizationError = (state: RootState) => state.organization.organizationById.error;

export const selectSetEnableOrganizationStatus = (state: RootState) => state.organization.organizationActions.setEnableOrganizationStatus;
export const selectCreateOrganizationStatus = (state: RootState) => state.organization.organizationActions.createOrganizationStatus;
export const selectUpdateOrganizationStatus = (state: RootState) => state.organization.organizationActions.updateOrganizationStatus;
export const selectDeleteOrganizationStatus = (state: RootState) => state.organization.organizationActions.deleteOrganizationStatus;
export const selectOrganizationActionsError = (state: RootState) => state.organization.organizationActions.error;