import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { detectUser } from 'store/user/userThunk';
import AppConfigContext from 'components/context/AppConfigContext';
import appConfig from 'config/appConfig';
import App from './App';
import { store } from './store/store';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from 'components/ErrorBoundary';

store.dispatch(detectUser());

ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <AppConfigContext.Provider value={appConfig}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppConfigContext.Provider>
      </Provider>
    </ErrorBoundary>,
  document.getElementById('root')
);

serviceWorker.unregister();
