import { RootState } from 'store/store';

export const selectAccountManagersStatus = (state: RootState) => state.accountManager.accountManagersList.getAccountManagersStatus;
export const selectAccountManagers = (state: RootState) => state.accountManager.accountManagersList.accountManagers;
export const selectAccountManagersPage = (state: RootState) => state.accountManager.accountManagersList.page;
export const selectAccountManagersTotalItems = (state: RootState) => state.accountManager.accountManagersList.totalItems;
export const selectAccountManagersItemsPerPage = (state: RootState) => state.accountManager.accountManagersList.itemsPerPage;
export const selectAccountManagersError = (state: RootState) => state.accountManager.accountManagersList.error;

export const selectAccountManagersBaseStatus = (state: RootState) => state.accountManager.accountManagersBaseList.getAccountManagersBaseStatus;
export const selectAccountManagersBase = (state: RootState) => state.accountManager.accountManagersBaseList.accountManagers;
export const selectAccountManagersBaseError = (state: RootState) => state.accountManager.accountManagersBaseList.error;

export const selectGetAccountManagerByIdStatus = (state: RootState) => state.accountManager.accountManagerById.getAccountManagerByIdStatus;
export const selectAccountManagerById = (state: RootState) => state.accountManager.accountManagerById.accountManager;
export const selectGetAccountManagerByIdError = (state: RootState) => state.accountManager.accountManagerById.error;

export const selectClientData = (state: RootState) => state.accountManager.accountManagerActions.clientData;
export const selectisClientExistsStatus = (state: RootState) => state.accountManager.accountManagerActions.isClientExistsStatus;

export const selectCreateAccountManagerStatus = (state: RootState) => state.accountManager.accountManagerActions.createAccountManagerStatus;
export const selectUpdateAccountManagerStatus = (state: RootState) => state.accountManager.accountManagerActions.updateAccountManagerStatus;
export const selectSetEnableAccountManagerStatus = (state: RootState) => state.accountManager.accountManagerActions.setEnableAccountManagerStatus;
export const selectDeleteAccountManagerStatus = (state: RootState) => state.accountManager.accountManagerActions.deleteAccountManagerStatus;
export const selectAccountManagerActionsMessage = (state: RootState) => state.accountManager.accountManagerActions.message;
export const selectAccountManagerActionsError = (state: RootState) => state.accountManager.accountManagerActions.error;
