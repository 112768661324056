import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import styled from '@emotion/styled/macro';
import { useDispatch } from 'react-redux';
import { Button, Input, Form, notification, Select } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import routes from 'config/Route';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { resetGetOrganizationById, resetUpdateOrganizationStatus } from 'store/organization/organizationSlice';
import { getOrganizationById, updateOrganization } from 'store/organization/organizationThunk';
import { selectUpdateOrganizationStatus, selectOrganizationById, selectOrganizationActionsError } from 'store/organization/organizationSelector';
import { selectAccountManagersBase, selectAccountManagersBaseStatus } from 'store/account-manager/accountManagerSelector';
import { getAccountManagersBase } from 'store/account-manager/accountManagerThunk';
import { resetGetAccountManagersBase } from 'store/account-manager/accountManagerSlice';
import { OrganizationUpdateForm } from 'core/organization/Organization';
import { FormButtonLayout, FormErrorLabelLayout, FormLayout } from 'config/constants';
import PageContainer from 'components/layouts/PageContainer';

const OrganizationsUpdateScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const { id } = useParams<{ id: string }>();

  const organization = useSelector(selectOrganizationById)
  const accountManagersStatus = useSelector(selectAccountManagersBaseStatus);
  const accountManagers = useSelector(selectAccountManagersBase);
  const updateOrganizationStatus = useSelector(selectUpdateOrganizationStatus);
  const updateOrganizationError = useSelector(selectOrganizationActionsError);
  const isUpdateLoading = updateOrganizationStatus === RequestStatus.LOADING;
  const isUpdateSuccess = updateOrganizationStatus === RequestStatus.SUCCEESS;

  const onFinish = async (values: OrganizationUpdateForm) => {
    dispatch(updateOrganization(values, id))
  };

  useEffect(() => {
    dispatch(getOrganizationById(id));
    dispatch(getAccountManagersBase());
    return () => {
      dispatch(resetGetOrganizationById());
      dispatch(resetGetAccountManagersBase());
      dispatch(resetUpdateOrganizationStatus());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (isUpdateSuccess) {
      notification.success({
        key: 'organization',
        message: `Success`,
        description: 'Organization successfully updated',
        placement: 'bottomRight',
      });
      history.push(routes.organizations.path);
    }
  }, [history, isUpdateSuccess]);

  return (
    <PageContainer title="Organization Update">
      {
        organization && accountManagers && (
          <Container>
            <Form
              {...FormLayout}
              layout="horizontal"
              onFinish={onFinish}
              initialValues={{
                name: organization.name,
                managedBy: organization.managedBy.id,
              }}
            >
              <Form.Item rules={[{ required: true }]} name="name" label="Organization name: ">
                <Input placeholder="Please enter username" />
              </Form.Item>
              <Form.Item rules={[{ required: true }]} name="managedBy" label="Managed by">
                <Select loading={accountManagersStatus === RequestStatus.LOADING}>
                  {accountManagers.map(item => (
                    <Select.Option key={item.id} value={item.id} style={{ color: item.active ? 'inherit' : '#FF4D4F' }}>
                      {item.firstName}{' '}{item.lastName}{!item.active && ' [not active]'}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item {...FormErrorLabelLayout}>
                <ErrorLabel>{updateOrganizationError}</ErrorLabel>
              </Form.Item>
              <Form.Item {...FormButtonLayout}>
                <Button loading={isUpdateLoading} style={{ marginRight: '10px' }} htmlType="submit" type="primary">Update</Button>
                <Link to={routes.organizations.path}>
                  <Button type="default">Cancel</Button>
                </Link>
              </Form.Item>
            </Form>
          </Container>
        )
      }
    </PageContainer>
  );
};

export default OrganizationsUpdateScreen;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.div`
  color: #ff4d4f;
`;
