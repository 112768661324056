import { AppThunk, AppDispatch } from 'store/store';
import { 
  getSuperusersRequest,
  getSuperusersSucceess,
  getSuperusersFailure,
  getSuperuserByIdRequest,
  getSuperuserByIdSucceess,
  getSuperuserByIdFailure,
  createSuperuserRequest,
  createSuperuserSucceess,
  createSuperuserFailure,
  setEnableSuperuserRequest,
  setEnableSuperuserSucceess,
  setEnableSuperuserFailure,
  updateSuperuserRequest,
  updateSuperuserSucceess,
  updateSuperuserFailure,
  deleteSuperuserRequest,
  deleteSuperuserSucceess,
  deleteSuperuserFailure,
} from './superuserSlice';
import SuperuserService from 'services/api/SuperuserService';
import { SuperuserForm } from 'core/superuser/Superuser';

const getSuperusers = (page: number, itemsPerPage: number): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getSuperusersRequest());
    const response = await SuperuserService.getSuperusers(page, itemsPerPage);
    dispatch(getSuperusersSucceess({
      superusers: response.data.data,
      totalItems: response.data.totalItems
    }));
  } catch (err) {
    dispatch(getSuperusersFailure(err.toString()));
  }
};

const getSuperuserById = (id: string): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getSuperuserByIdRequest());
    const response = await SuperuserService.getSuperuserById(id);
    dispatch(getSuperuserByIdSucceess(response.data));
  } catch (err) {
    dispatch(getSuperuserByIdFailure(err.toString()));
  }
};

const updateSuperuser = (data: SuperuserForm, id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(updateSuperuserRequest());
    const response = await SuperuserService.updateSuperuser(data, id);
    dispatch(updateSuperuserSucceess({ message: response.data.message || '' }));
  } catch (err) {
    dispatch(updateSuperuserFailure(err.message));
  }
};

const createSuperuser = (data: SuperuserForm) => async (dispatch: AppDispatch) => {
  try {
    dispatch((createSuperuserRequest()));
    const response = await SuperuserService.createSuperuser(data);
    dispatch(createSuperuserSucceess({ message: response.data.message || '' }));
  } catch (err) {
    dispatch(createSuperuserFailure(err.message));
  }
};

const deleteSuperuser = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(deleteSuperuserRequest());
    await SuperuserService.deleteSuperuser(id);
    dispatch(deleteSuperuserSucceess());
  } catch (err) {
    dispatch(deleteSuperuserFailure(err.message));
  }
};

const setEnable = (id: string, isActive: boolean) => async (dispatch: AppDispatch) => {
  try {
    dispatch((setEnableSuperuserRequest()));
    await SuperuserService.setEnable(id, isActive);
    dispatch(setEnableSuperuserSucceess());
  } catch (err) {
    dispatch(setEnableSuperuserFailure());
  }
};

export { getSuperusers, getSuperuserById, createSuperuser, updateSuperuser, setEnable, deleteSuperuser };
