import { RootState } from 'store/store';

export const selectProductsStatus = (state: RootState) => state.product.productList.getProductsStatus;
export const selectProducts = (state: RootState) => state.product.productList.products;
export const selectProductsPage = (state: RootState) => state.product.productList.page;
export const selectProductsTotalItems = (state: RootState) => state.product.productList.totalItems;
export const selectProductsItemsPerPage = (state: RootState) => state.product.productList.itemsPerPage;
export const selectProductsError = (state: RootState) => state.product.productList.error;

export const selectGetProductsBaseStatus = (state: RootState) => state.product.productsBaseList.getProductsBaseStatus;
export const selectProductsBase = (state: RootState) => state.product.productsBaseList.products;
export const selectProductsBaseError = (state: RootState) => state.product.productsBaseList.error;

export const selectGetProductByIdStatus = (state: RootState) => state.product.productById.getProductByIdStatus;
export const selectProductById = (state: RootState) => state.product.productById.product;
export const selectGetProductByIdError = (state: RootState) => state.product.productById.error;

export const selectCreateProductStatus = (state: RootState): string => state.product.productActions.createProductStatus;
export const selectUpdateProductStatus = (state: RootState): string => state.product.productActions.updateProductStatus;
export const selectDeleteProductStatus = (state: RootState): string => state.product.productActions.deleteProductStatus;
export const selectSetEnableProductStatus = (state: RootState): string => state.product.productActions.setEnableProductStatus;
export const selectProductActionsError = (state: RootState): string => state.product.productActions.error;