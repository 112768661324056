import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { AccountManager } from 'core/account-manager/AccountManager';
import { Client } from 'core/client/Client';

import { AccountManagersState, AccountManagersBaseState, AccountManagerByIdState, AccountManagerActionsState } from './accountManagerTypes';
import { AccountManagerById } from 'core/account-manager/AccountManager';
import { AccountManagerBase } from 'core/account-manager/AccountManager';
import ItemsPerPage from 'core/shared/enum/ItemsPerPage';

const accountManagersInitialState: AccountManagersState = {
  getAccountManagersStatus: RequestStatus.IDLE,
  accountManagers: [],
  page: 1,
  totalItems: 0,
  itemsPerPage: ItemsPerPage.TEN,
  error: '',
};

export const accountManagersSlice = createSlice({
  name: 'accountManagersSlice',
  initialState: accountManagersInitialState,
  reducers: {
    getAccountManagersRequest: (state) => {
      state.getAccountManagersStatus = RequestStatus.LOADING;
    },
    getAccountManagersSucceess: (state, action: PayloadAction<{ accountManagers: AccountManager[], totalItems: number }>) => {
      const { payload } = action;
      state.getAccountManagersStatus = RequestStatus.SUCCEESS;
      state.accountManagers = payload.accountManagers;
      state.totalItems = payload.totalItems;
    },
    getAccountManagersFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.getAccountManagersStatus = RequestStatus.FAILURE;
    },
    updateAccountManagersPage: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.page = payload;
    },
    updateAccountManagersItemsPerPage: (state, action: PayloadAction<ItemsPerPage>) => {
      const { payload } = action;
      state.itemsPerPage = payload;
    },
    resetGetAccountManagers: (state) => {
      state.getAccountManagersStatus = RequestStatus.IDLE;
      state.accountManagers = [];
      state.error = '';
    },
  },
});

const accountManagersBaseInitialState: AccountManagersBaseState = {
  getAccountManagersBaseStatus: RequestStatus.IDLE,
  accountManagers: [],
  error: '',
};

export const accountManagersBaseSlice = createSlice({
  name: 'accountManagersBaseSlice',
  initialState: accountManagersBaseInitialState,
  reducers: {
    getAccountManagersBaseRequest: (state) => {
      state.getAccountManagersBaseStatus = RequestStatus.LOADING;
    },
    getAccountManagersBaseSucceess: (state, action: PayloadAction<{ accountManagers: AccountManagerBase[] }>) => {
      const { payload } = action;
      state.getAccountManagersBaseStatus = RequestStatus.SUCCEESS;
      state.accountManagers = payload.accountManagers;
    },
    getAccountManagersBaseFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.getAccountManagersBaseStatus = RequestStatus.FAILURE;
      state.error = payload;
    },
    resetGetAccountManagersBase: () => ({ ...accountManagersBaseInitialState }),
  },
});

const accountManagerByIdInitialState: AccountManagerByIdState = {
  getAccountManagerByIdStatus: RequestStatus.IDLE,
  accountManager: null,
  error: '',
};

export const accountManagerByIdSlice = createSlice({
  name: 'accountManagerByIdSlice',
  initialState: accountManagerByIdInitialState,
  reducers: {
    getAccountManagerByIdRequest: (state) => {
      state.getAccountManagerByIdStatus = RequestStatus.LOADING;
    },
    getAccountManagerByIdSucceess: (state, action: PayloadAction<AccountManagerById>) => {
      const { payload } = action;
      state.accountManager = payload;
      state.getAccountManagerByIdStatus = RequestStatus.SUCCEESS;
    },
    getAccountManagerByIdFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.getAccountManagerByIdStatus = RequestStatus.FAILURE;
    },
    resetGetAccountManagerById: () => ({ ...accountManagerByIdInitialState }),
  },
});

const accountManagerActionsInitialState: AccountManagerActionsState = {
  isClientExistsStatus: RequestStatus.IDLE,
  clientData: null,
  setEnableAccountManagerStatus: RequestStatus.IDLE,
  createAccountManagerStatus: RequestStatus.IDLE,
  updateAccountManagerStatus: RequestStatus.IDLE,
  deleteAccountManagerStatus: RequestStatus.IDLE,
  message: '',
  error: '',
};

export const accountManagerActionsSlice = createSlice({
  name: 'accountManagerActionsSlice',
  initialState: accountManagerActionsInitialState,
  reducers: {
    setEnableAccountManagerRequest: (state) => {
      state.setEnableAccountManagerStatus = RequestStatus.LOADING;
    },
    setEnableAccountManagerSucceess: (state) => {
      state.setEnableAccountManagerStatus = RequestStatus.SUCCEESS;
    },
    setEnableAccountManagerFailure: (state) => {
      state.setEnableAccountManagerStatus = RequestStatus.FAILURE;
    },
    resetSetEnableAccountManagerStatus: (state) => {
      state.setEnableAccountManagerStatus = RequestStatus.IDLE;
    },
    createAccountManagerRequest: (state) => {
      state.createAccountManagerStatus = RequestStatus.LOADING;
      state.error = '';
      state.message = '';
    },
    createAccountManagerSucceess: (state, action: PayloadAction<{ message: string}>) => {
      state.createAccountManagerStatus = RequestStatus.SUCCEESS;
      state.message = action.payload.message;
    },
    createAccountManagerFailure: (state, action: PayloadAction<string>) => {
      state.createAccountManagerStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetCreateAccountManagerStatus: (state) => {
      state.createAccountManagerStatus = RequestStatus.IDLE;
      state.error = '';
      state.message = '';
    },
    isClientExistsRequest: (state) => {
      state.isClientExistsStatus = RequestStatus.LOADING;
    },
    isClientExistsSucceess: (state, action: PayloadAction<Client>) => {
      state.isClientExistsStatus = RequestStatus.SUCCEESS;
      state.clientData = action.payload;
    },
    isClientExistsFailure: (state, action: PayloadAction<string>) => {
      state.isClientExistsStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetIsClientExists: (state) => {
      state.isClientExistsStatus = RequestStatus.IDLE;
      state.clientData = null;
      state.error = '';
    },
    updateAccountManagerRequest: (state) => {
      state.updateAccountManagerStatus = RequestStatus.LOADING;
      state.error = '';
      state.message = '';
    },
    updateAccountManagerSucceess: (state, action: PayloadAction<{ message: string}>) => {
      state.updateAccountManagerStatus = RequestStatus.SUCCEESS;
      state.message = action.payload.message;
    },
    updateAccountManagerFailure: (state, action: PayloadAction<string>) => {
      state.updateAccountManagerStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetUpdateAccountManagerStatus: (state) => {
      state.updateAccountManagerStatus = RequestStatus.IDLE;
      state.error = '';
      state.message = '';
    },
    deleteAccountManagerRequest: (state) => {
      state.deleteAccountManagerStatus = RequestStatus.LOADING;
    },
    deleteAccountManagerSucceess: (state) => {
      state.deleteAccountManagerStatus = RequestStatus.SUCCEESS;
    },
    deleteAccountManagerFailure: (state, action: PayloadAction<string>) => {
      state.deleteAccountManagerStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetDeleteAccountManagerStatus: (state) => {
      state.deleteAccountManagerStatus = RequestStatus.IDLE;
      state.error = '';
    },
  },
});

export const {
  getAccountManagersRequest,
  getAccountManagersSucceess,
  getAccountManagersFailure,
  updateAccountManagersPage,
  updateAccountManagersItemsPerPage,
  resetGetAccountManagers,
} = accountManagersSlice.actions;

export const {
  getAccountManagersBaseRequest,
  getAccountManagersBaseSucceess,
  getAccountManagersBaseFailure,
  resetGetAccountManagersBase,
} = accountManagersBaseSlice.actions;

export const {
  getAccountManagerByIdRequest,
  getAccountManagerByIdSucceess,
  getAccountManagerByIdFailure,
  resetGetAccountManagerById,
} = accountManagerByIdSlice.actions;

export const {
  setEnableAccountManagerRequest,
  setEnableAccountManagerSucceess,
  setEnableAccountManagerFailure,
  resetSetEnableAccountManagerStatus,
  createAccountManagerRequest,
  createAccountManagerSucceess,
  createAccountManagerFailure,
  isClientExistsRequest,
  isClientExistsSucceess,
  isClientExistsFailure,
  resetIsClientExists,
  resetCreateAccountManagerStatus,
  updateAccountManagerRequest,
  updateAccountManagerSucceess,
  updateAccountManagerFailure,
  resetUpdateAccountManagerStatus,
  deleteAccountManagerRequest,
  deleteAccountManagerSucceess,
  deleteAccountManagerFailure,
  resetDeleteAccountManagerStatus,
} = accountManagerActionsSlice.actions;

export default combineReducers({
  accountManagersList: accountManagersSlice.reducer,
  accountManagersBaseList: accountManagersBaseSlice.reducer,
  accountManagerById: accountManagerByIdSlice.reducer,
  accountManagerActions: accountManagerActionsSlice.reducer
});
