import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Form, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import routes from 'config/Route';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { selectAccount, selectAccountActionsMessage, selectUserData } from 'store/user/userSelector';
import { selectUpdateAccountDataStatus, selectAccountActionsError } from 'store/user/userSelector';
import { updateAccountData, getAccount } from 'store/user/userThunk';
import { resetGetAccount, resetUpdateAccountData } from 'store/user/userSlice';
import UserRole from 'core/shared/enum/UserRole';
import { UpdateAccountDataForm } from 'core/user/Account';
import { FormButtonLayout, FormErrorLabelLayout, FormLayout } from 'config/constants';
import PageContainer from 'components/layouts/PageContainer';

const UpdateAccountPasswordScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userData = useSelector(selectUserData);
  const account = useSelector(selectAccount);
  const accountActionsMessage = useSelector(selectAccountActionsMessage);
  const accountActionsError = useSelector(selectAccountActionsError);
  const updateAccountDataStatus = useSelector(selectUpdateAccountDataStatus);
  const isUpdateLoading = updateAccountDataStatus === RequestStatus.LOADING;
  const isUpdateSuccess = updateAccountDataStatus === RequestStatus.SUCCEESS;

  const onFinish = async (values: UpdateAccountDataForm) => {
    dispatch(updateAccountData(values))
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      notification.success({
        key: 'accountData',
        message: `Success`,
        description: 'Account data successfully updated',
        placement: 'bottomRight',
      });
      if (accountActionsMessage) {
        notification.warning({
          key: 'accountDataEmail',
          message: `Email notification was failed`,
          description: accountActionsMessage,
          placement: 'bottomRight',
        });
      }
      history.push(routes.account.path);
    }
  }, [history, isUpdateSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  
  useEffect(() => {
    dispatch(getAccount());
    return () => {
      dispatch(resetUpdateAccountData());
      dispatch(resetGetAccount());
    }
  }, [dispatch]);

  return (
    <PageContainer title="Account data update">
      {
        account && (
          <Container>
            <Form
              {...FormLayout}
              layout="horizontal"
              onFinish={onFinish}
              initialValues={account}
            >
              {
                userData.userRole === UserRole.ROLE_ACCOUNT_MANAGER ? (
                  <>
                    <Form.Item rules={[{ required: false }]} name="contactAddress" label="Contact address (optional)">
                      <Input placeholder="Please enter your contact address" />
                    </Form.Item>
                    <Form.Item rules={[{ required: false }]} name="phoneNumber" label="Phone number (optional)">
                      <Input placeholder="Please enter your phone number" />
                    </Form.Item>
                  </>
                ) : (
                    <>
                      <Form.Item rules={[{ required: true }]} name="username" label="Username">
                        <Input placeholder="Please enter username" />
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]} name="email" label="Email">
                        <Input type="email" placeholder="Please enter email" />
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]} name="firstName" label="First name">
                        <Input placeholder="Please enter first name" />
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]} name="lastName" label="Last name">
                        <Input placeholder="Please enter last name" />
                      </Form.Item>
                      <Form.Item rules={[{ required: false }]} name="contactAddress" label="Contact address (optional)">
                        <Input placeholder="Please enter your contact address" />
                      </Form.Item>
                      <Form.Item rules={[{ required: false }]} name="phoneNumber" label="Phone number (optional)">
                        <Input placeholder="Please enter your phone number" />
                      </Form.Item>
                    </>
                  )
              }
              <Form.Item {...FormErrorLabelLayout} style={{ marginBottom: '0' }}>
                <ErrorLabel>{accountActionsError}</ErrorLabel>
              </Form.Item>
              <Form.Item {...FormButtonLayout}>
                <Button loading={isUpdateLoading} style={{ marginRight: '10px' }} htmlType="submit" type="primary">Update account data</Button>
                <Button type="default" onClick={() => history.push("/account")}>Cancel</Button>
              </Form.Item>
            </Form>
          </Container>
        )}
    </PageContainer>
  );
};

export default UpdateAccountPasswordScreen;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.div`
  color: #ff4d4f;
`;
