import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { PageHeader, Layout, Table, Button, Space, notification, Radio, Form, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import { store } from 'store/store';
import { Product } from 'core/product/Product';
import ProductStatus from 'core/product/enum/ProductStatus';
import RequestStatus from 'core/shared/enum/RequestStatus';
import PRODUCT_TYPE_ENUM_LABEL from 'core/product/ProductTypeEnumLabel';
import UserRole from 'core/shared/enum/UserRole';
import { selectUserData } from 'store/user/userSelector';
import {
  selectDeleteProductStatus,
  selectProducts,
  selectProductsPage,
  selectProductsStatus,
  selectSetEnableProductStatus,
  selectProductsTotalItems,
  selectProductsItemsPerPage
} from 'store/product/productSelector';
import { deleteProduct, getProducts, setEnableProduct } from 'store/product/productThunk';
import { useAppConfig } from 'components/context/AppConfigContext';
import { selectProductStatusPF } from 'store/filter/filterSelector';
import { setProductStatusPF } from 'store/filter/filterSlice';
import {
  updateProductsPage,
  resetDeleteProductStatus,
  resetGetProducts,
  resetSetEnableProductStatus,
  updateProductsItemsPerPage
} from 'store/product/productSlice';

const { Content } = Layout;

const getColumns = (role: OrNull<UserRole>): ColumnsType<Product> => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, record) => (
        <Link to={`products/${record.id}`}>{record.name}</Link>
      ),
      fixed: 'left',
    },
    ...(role === UserRole.ROLE_SUPER_USER ? [
    {
      title: 'Technical Code',
      dataIndex: 'technicalCode',
      key: 'technicalCode',
    }] : []),
    {
      title: 'Base',
      dataIndex: 'base',
      render: (text, record) => (
        <span>{record.base ? 'Yes' : 'No'}</span>
      ),
    },
    {
      title: 'Licenses count',
      dataIndex: 'licenses',
      key: 'licenses',
    },
    {
      title: 'Status',
      key: 'active',
      render: (text, record) => (
        <>
          <Space size="middle" style={{ wordBreak: "normal"}}>
            <ActiveData isActive={record.active}>{record.active ? 'Active' : 'Not active'}</ActiveData>
          </Space>
        </>
      ),
    },
    {
      title: 'Related exclusively to products',
      dataIndex: 'productsRelatedTo',
      width: "250px",
      render: (text, record) => (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {record.productsRelatedTo.map(item => item.name).join(', ') || '-'}
        </div>
      ),
    },
    {
      title: 'Usage Type',
      dataIndex: 'usageType',
      render: (text, record) => (
        <span>{PRODUCT_TYPE_ENUM_LABEL[record.usageType]}</span>
      ),
    },
    ...(role === UserRole.ROLE_SUPER_USER ? [
    {
      title: 'Actions',
      key: 'action',
      render: (text: string, record: Product) => (
        <>
        {
          role === UserRole.ROLE_SUPER_USER && (
          <Space size="middle" style={{ wordBreak: "normal"}}>
            <Popconfirm
              title={`Are you sure to ${record?.active ? 'deactivate' : 'activate'} this product?`}
              onConfirm={() => store.dispatch(setEnableProduct(record.id, !record.active))}
              okText="Yes"
              cancelText="No"
            >
              <ActionButton>{record.active ? 'Deactivate' : 'Activate'}</ActionButton>
            </Popconfirm>
                <Space size="middle">
                  <Link to={`products/${record.id}/update`}>Update</Link>
                  {
                    !record.licenses && (
                      <Popconfirm
                        title={`Are you sure to delete this product?`}
                        onConfirm={() => store.dispatch(deleteProduct(record.id))}
                        okText="Yes"
                        cancelText="No"
                      >
                        <ActionButton>Delete</ActionButton>
                      </Popconfirm>
                    )
                  }
                </Space>
          </Space>
          )
        }
        </>
      ),
    },
    ] : []),
  ];
}

const ProductsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { routes } = useAppConfig();

  const status = useSelector(selectProductStatusPF);

  const userData = useSelector(selectUserData);
  const productsStatus = useSelector(selectProductsStatus);
  const products = useSelector(selectProducts);
  const productsPage = useSelector(selectProductsPage);
  const productsTotalItems = useSelector(selectProductsTotalItems);
  const productsItemsPerPage = useSelector(selectProductsItemsPerPage);

  const deleteProductStatus = useSelector(selectDeleteProductStatus);
  const isDeleteSuccess = deleteProductStatus === RequestStatus.SUCCEESS;
  const setEnableProductStatus = useSelector(selectSetEnableProductStatus);
  const isSetEnableSuccess = setEnableProductStatus === RequestStatus.SUCCEESS;

  useEffect(() => {
    if (isDeleteSuccess) {
      notification.success({
        key: 'product',
        message: `Success`,
        description: 'Product successfully deleted',
        placement: 'bottomRight',
      });
      dispatch(getProducts(status, productsPage, productsItemsPerPage));
      dispatch(resetDeleteProductStatus());
    }
     // eslint-disable-next-line
  }, [dispatch, isDeleteSuccess]);

  useEffect(() => {
    if (isSetEnableSuccess) {
      notification.success({
        key: 'product',
        message: `Success`,
        description: 'Product status successfully updated',
        placement: 'bottomRight',
      });
      dispatch(getProducts(status, productsPage, productsItemsPerPage));
      dispatch(resetSetEnableProductStatus());
    }
     // eslint-disable-next-line
  }, [dispatch, isSetEnableSuccess]);

  useEffect(() => {
    dispatch(getProducts(status, productsPage, productsItemsPerPage));
  }, [dispatch, status, productsPage, productsItemsPerPage]);

  useEffect(() => {
    return () => {
      dispatch(resetGetProducts());
    }
  }, [dispatch]);

  const onPageChange = (page: number) => dispatch(updateProductsPage(page));

  const productsColumns: ColumnsType<Product> = getColumns(userData.userRole);

  return (
    <>
      <PageHeader
        title="Products"
        ghost={false}
      >
        
      </PageHeader>
      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          backgroundColor: '#fff',
        }}
      >
        <Form layout="inline">
          <Form.Item label="Status " style={{ marginBottom: '0' }}>
            <Radio.Group value={status} onChange={(data) => {dispatch(updateProductsPage(1)); dispatch(setProductStatusPF(data.target.value))}}>
              <Radio.Button value={ProductStatus.ACTIVE}>{ProductStatus.ACTIVE}</Radio.Button>
              <Radio.Button value={ProductStatus.ALL}>{ProductStatus.ALL}</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Content>
      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          minHeight: 280,
          backgroundColor: '#fff',
        }}
      >
        <Table
          scroll={{ x: "max-content" }}
          title={() => (<TitleContainer>
            <h2>Products</h2>
            {userData.userRole === UserRole.ROLE_SUPER_USER &&
            <Link to={routes.productCreate.path}>
              <Button
                type="primary"
                size="large"
              >
                <PlusOutlined />
                Add new
              </Button>
            </Link>}
          </TitleContainer>)}
          loading={productsStatus === RequestStatus.LOADING}
          rowKey={record => record.id}
          pagination={{
            defaultPageSize: productsItemsPerPage,
            current: productsPage,
            total: productsTotalItems,
            onChange: onPageChange,
            showSizeChanger: true,
            onShowSizeChange: (current, size) => {dispatch(updateProductsPage(1)); dispatch(updateProductsItemsPerPage(size))},
            showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`
          }}
          columns={productsColumns}
          dataSource={products}
        />
      </Content>
    </>
  );
};

export default ProductsScreen;

const ActiveData = styled.span<{ isActive: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? '#52C41A' : '#FF4D4F')}; 
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActionButton = styled.span`
  color: #1890ff;
  cursor: pointer;
  &:hover {
    color: #40a9ff;
  }
`;
