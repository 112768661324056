import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Form, notification } from 'antd';
import { useHistory, useLocation, Link } from 'react-router-dom';
import routes from 'config/Route';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { selectResetPasswordStatus, selectValidateTokenStatus } from 'store/user/userSelector';
import { resetPassword, validateToken } from 'store/user/userThunk';
import { resetRequestResetPassword } from 'store/user/userSlice';
import BaseLayout from 'components/layouts/BaseLayout';

const ResetPassword: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const token = new URLSearchParams(search).get('token') || '';
  const validateTokenStatus = useSelector(selectValidateTokenStatus);
  const resetPasswordStatus = useSelector(selectResetPasswordStatus);
  const isValidateTokenStatusFaiure = validateTokenStatus === RequestStatus.FAILURE;
  const isResetPasswordStatusLoading = resetPasswordStatus === RequestStatus.LOADING;
  const isResetPasswordStatusSuccess = resetPasswordStatus === RequestStatus.SUCCEESS;

  const onFinish = async (values: { password: string }) => {
    dispatch(resetPassword(values.password, token));
  };

  useEffect(() => {
    if (isValidateTokenStatusFaiure) {
      history.push(routes.signin.path);
    }
  }, [history, validateTokenStatus]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (isResetPasswordStatusSuccess) {
      notification.success({
        message: `Success`,
        description: 'Password updated successfully',
        placement: 'bottomRight',
      });
      history.push(routes.signin.path);
    }
  }, [history, isResetPasswordStatusSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  
  useEffect(() => {
    dispatch(validateToken(token));
    return () => {
      dispatch(resetRequestResetPassword());
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseLayout>
      <Container>
        <Container style={{ marginBottom: "30px"}}>
          <Header1>SamanTree Medical Admin Panel</Header1>
          <Header2>License Management System</Header2>
        </Container>
        <Header3 style={{ marginBottom: "15px" }}>Enter and confirm new password</Header3>
        <Form onFinish={onFinish}>
          <Form.Item
            name="password"
            style={{ marginBottom: "15px" }}
            rules={[
              { required: true },
              {
                // eslint-disable-next-line
                pattern: new RegExp("^[A-Za-z0-9\"\'!#$%&()*+,-./:;<=>?@^_`{|}~]*$"),
                message: "Password should contain only latin letters, numbers and special characters!"
              },
              {
                pattern: new RegExp("(?=.*[A-Z])"),
                message: "At least one uppercase letter required!"
              },
              {
                pattern: new RegExp("(?=.*[a-z])"),
                message: "At least one lowercase letter required!"
              },
              {
                // eslint-disable-next-line
                pattern: new RegExp("(?=.*[\"\'!#$%&()*+,-./:;<=>?@^_`{|}~])"),
                message: "At least one special character required!"
              },
              {
                pattern: new RegExp(".*[0-9].*"),
                message: "At least one digit required!"
              },
              {
                min: 8,
                message: "At least 8 symbols required!"
              },
            ]}
          >
            <Password
              placeholder="Enter new password"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            style={{ marginBottom: "15px" }}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Password
              placeholder="Confirm new password"
              size="large"
            />
          </Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={isResetPasswordStatusLoading}
              block
            >
              Update password
            </Button>
        </Form>
        <Link style={{ margin: "10px 10px", padding: "5px" }} to={routes.signin.path}>
          Back to login
        </Link>
      </Container>
    </BaseLayout>
  );
};

export default ResetPassword;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
`;

const Header1 = styled.h1`
  font-size: 38px;
  margin-bottom: 12px;
  font-weight: 750;
  line-height: 1;
  color: #000000CC;
  text-align: center;
`;

const Header2 = styled.h2`
  letter-spacing: 0.005em;
  color: #00000073;
`;

const Header3 = styled.h3`
  letter-spacing: 0.005em;
  color: #00000073;
`;

const Password = styled(Input.Password)`
  font-size: 16px;
  line-height: 24px;
`;