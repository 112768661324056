import { RootState } from 'store/store';

export const selectSuperusersStatus = (state: RootState) => state.superuser.superuserList.getSuperusersStatus;
export const selectSuperusers = (state: RootState) => state.superuser.superuserList.superusers;
export const selectSuperusersPage = (state: RootState) => state.superuser.superuserList.page;
export const selectSuperusersTotalItems = (state: RootState) => state.superuser.superuserList.totalItems;
export const selectSuperusersItemsPerPage = (state: RootState) => state.superuser.superuserList.itemsPerPage;
export const selectSuperusersError = (state: RootState) => state.superuser.superuserList.error;

export const selectGetSuperuserByIdStatus = (state: RootState) => state.superuser.superuserById.getSuperuserByIdStatus;
export const selectSuperuserById = (state: RootState) => state.superuser.superuserById.superuser;
export const selectGetSuperuserByIdError = (state: RootState) => state.superuser.superuserById.error;

export const selectCreateSuperuserStatus = (state: RootState): string => state.superuser.superuserActions.createSuperuserStatus;
export const selectUpdateSuperuserStatus = (state: RootState): string => state.superuser.superuserActions.updateSuperuserStatus;
export const selectSetEnableSuperuserStatus = (state: RootState): string => state.superuser.superuserActions.setEnableSuperuserStatus;
export const selectDeleteSuperuserStatus = (state: RootState): string => state.superuser.superuserActions.deleteSuperuserStatus;
export const selectSuperuserActionsMessage = (state: RootState): string => state.superuser.superuserActions.message;
export const selectSuperuserActionsError = (state: RootState): string => state.superuser.superuserActions.error;