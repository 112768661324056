const routes = {
  index: {
    path: '/',
    exact: true,
  },
  signin: {
    path: '/signin',
    exact: true,
  },
  requestRecoverPassword: {
    path: '/recover-password',
    exact: true,
  },
  recoverPassword: {
    path: '/password-reset',
    exact: true,
  },
  superusers: {
    path: '/superusers',
    exact: true,
  },
  superusersCreate: {
    path: '/superusers/create',
    exact: true,
  },
  superusersProfile: {
    path: '/superusers/:id',
    exact: false,
  },
  superusersUpdate: {
    path: '/superusers/:id/update',
    exact: true,
  },
  accountManagers: {
    path: '/account-managers',
    exact: true,
  },
  accountManagersCreate: {
    path: '/account-managers/create',
    exact: true,
  },
  accountManagersProfile: {
    path: '/account-managers/:id',
    exact: false,
  },
  accountManagersUpdate: {
    path: '/account-managers/:id/update',
    exact: true,
  },
  products: {
    path: '/products',
    exact: true,
  },
  productCreate: {
    path: '/products/create',
    exact: true,
  },
  productProfile: {
    path: '/products/:id',
    exact: false,
  },
  productUpdate: {
    path: '/products/:id/update',
    exact: true,
  },
  organizations: {
    path: '/organizations',
    exact: true,
  },
  organizationsProfile: {
    path: '/organizations/:id',
    exact: false,
  },
  organizationsUpdate: {
    path: '/organizations/:id/update',
    exact: true,
  },
  organizationsCreate: {
    path: '/organizations/create',
    exact: true,
  },
  clients: {
    path: '/clients',
    exact: true,
  },
  clientsCreate: {
    path: '/clients/create',
    exact: true,
  },
  clientsProfile: {
    path: '/clients/:id',
    exact: false,
  },
  clientsUpdate: {
    path: '/clients/:id/update',
    exact: true,
  },
  licenses: {
    path: '/licenses',
    exact: true,
  },
  licenseCreate: {
    path: '/licenses/create',
    exact: true,
  },
  licenseProfile: {
    path: '/licenses/:id',
    exact: false,
  },
  licensesUpdate: {
    path: '/licenses/:id/update',
    exact: true,
  },
  account: {
    path: '/account',
    exact: true,
  },
  accountPasswordUpdate: {
    path: '/account/password-update',
    exact: true,
  },
  accountProfileUpdate: {
    path: '/account/profile-update',
    exact: true,
  },
  instructionsForUse: {
    path: '/ifu',
    exact: true,
  },
};

export default routes;
