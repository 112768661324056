import { useCallback, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, notification, Popconfirm, Tooltip } from 'antd';
import { Link, useHistory, useParams } from "react-router-dom";
import { Typography } from 'antd';

import { selectClientById, selectDeleteClientStatus, selectSetEnableClientStatus } from 'store/client/clientSelector';
import RequestStatus from 'core/shared/enum/RequestStatus';
import { deleteClient, getClientById, setEnableClient } from 'store/client/clientThunk';
import { resetDeleteClientStatus, resetGetClientById, resetSetEnableClientStatus } from 'store/client/clientSlice';
import { selectUserData } from 'store/user/userSelector';
import UserRole from 'core/shared/enum/UserRole';
import PageContainer from 'components/layouts/PageContainer';

const { Title } = Typography;

const ClientProfileScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams<{ id: string }>()

  const client = useSelector(selectClientById)
  const setEnableClientStatus = useSelector(selectSetEnableClientStatus);
  const deleteClientStatus = useSelector(selectDeleteClientStatus);
  const userData = useSelector(selectUserData);

  const isSetEnableClientLoading = setEnableClientStatus === RequestStatus.LOADING;
  const isSetEnableClientSuccess = setEnableClientStatus === RequestStatus.SUCCEESS;
  const isDeleteClientSuccess = deleteClientStatus === RequestStatus.SUCCEESS;

  const hasDoubleRole = client && client.roles.length > 1 && client.active

  const setActiveHandler = async (id: string, isActive: boolean) => {
    dispatch(setEnableClient(id, isActive))
  };

  useEffect(() => {
    if (isDeleteClientSuccess) {
      notification.success({
        key: 'client',
        message: `Success`,
        description: 'Client successfully deleted',
        placement: 'bottomRight',
      });
      dispatch(resetDeleteClientStatus());
      history.goBack();
    }
  }, [dispatch, history, isDeleteClientSuccess]);

  useEffect(() => {
    if (isSetEnableClientSuccess) {
      notification.success({
        message: `Success`,
        description: hasDoubleRole ? 'Client successfully deleted' : 'Client status changed successfully.',
        placement: 'bottomRight',
      });
      dispatch(resetSetEnableClientStatus());
      if (hasDoubleRole) {
        history.goBack();
      } else {
        dispatch(getClientById(id));
      }
    }
  }, [dispatch, isSetEnableClientSuccess, hasDoubleRole, id, history]);

  useEffect(() => {
    dispatch(getClientById(id));
    return () => {
      dispatch(resetGetClientById());
    }
  }, [dispatch, id]);

  const statusText = hasDoubleRole ? 
  `The client proposed for deactivation also has an account manager role. Deactivation of this client will remove the client role from the associated e-mail account. Are you sure that you want to remove the role?` : 
  `Are you sure to ${client?.active ? 'deactivate' : 'activate'} this client?`;

  const statusOkText = hasDoubleRole ? 'Remove' : 'Yes';

  const statusCancelText = hasDoubleRole ? 'Cancel' : 'No';

  const onClose = useCallback(() => {
    if (history.length < 3) {
      history.push("/clients");
    } else {
      history.goBack();
    }
  }, [history]);

  return (
    <PageContainer title="Client Profile">
      { client &&
        <>
          <Profile>
            <Title level={3}>Client <b>{client.email}</b></Title>
            <Line>
              <Label>Email (username):</Label>
              <Info>{client.email || '<No info>'}</Info>
            </Line>
            <Line>
              <Label>First name:</Label>
              <Info>{client.firstName || '<No info>'}</Info>
            </Line>
            <Line>
              <Label>Last name:</Label>
              <Info>{client.lastName || '<No info>'}</Info>
            </Line>
            <Line>
              <Label>Status:</Label>
              <Info><ActiveData isActive={!!client.active}>{!!client.active ? 'Active' : 'Not active'}</ActiveData></Info>
            </Line>
            <Line>
              <Label>Organization:</Label>
              <Info>
                <Link to={`/organizations/${client.organization.id}`}>{client.organization.name}</Link>
              </Info>
            </Line>
            <Line>
              <Label>Contact Address:</Label>
              <Info>{client.contactAddress || 'No info'}</Info>
            </Line>
            <Line>
              <Label>Phone Number:</Label>
              <Info>{client.phoneNumber || 'No info'}</Info>
            </Line>
          </Profile>
          <ButtonContainer>
            {userData.userRole === UserRole.ROLE_ACCOUNT_MANAGER && (
              <>
                <Link style={{ margin: '0 10px' }} to={`${client.id}/update`}>
                  <Button type="primary" size="middle">Update</Button>
                </Link>

                <Popconfirm
                  title={`Are you sure to delete this client?`}
                  onConfirm={() => dispatch(deleteClient(client.id))}
                  okText="Yes"
                  cancelText="No"
                  disabled={!!client.active}
                >
                  <Tooltip
                    title={!!client.active ? "A Client profile can be deleted if it was previously deactivated." : ""}
                    trigger="hover"
                    color="white"
                    overlayInnerStyle={{ color: "#000000d9", padding: "12px 16px", textAlign: "justify" }}
                  >
                    <Button
                      type="primary"
                      size="middle"
                      style={{ margin: '0 10px' }}
                      danger
                      disabled={!!client.active}
                    >
                      Delete
                      </Button>
                  </Tooltip>
                </Popconfirm>
                <Popconfirm
                  title={<div style={{ width: '300px' }}>{statusText}</div>}
                  onConfirm={() => setActiveHandler(client.id, !client.active)}
                  okText={statusOkText}
                  cancelText={statusCancelText}
                >
                  <Button loading={isSetEnableClientLoading} type="primary" size="middle" style={{ margin: '0 10px', backgroundColor: '#52C41A', color: 'white' }}>
                    {client.active ? 'Deactivate' : 'Activate'}
                  </Button>
                </Popconfirm>
              </>
            )}
            <Button type="default" size="middle" style={{ margin: '0 10px' }} onClick={onClose}>Close</Button>
          </ButtonContainer>
        </>
      }
    </PageContainer >
  );
};

export default ClientProfileScreen;

const Profile = styled.div`
  width: 400px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ActiveData = styled.span<{ isActive: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? '#52C41A' : '#FF4D4F')}; 
`;

const ButtonContainer = styled.div`
  max-width: 400px;
  display: flex;
  justify-content: flex-end;
  margin: 10px auto;
`;

const Line = styled.div`
  display: flex;
`;

const Label = styled.div`
  width: 150px;
  font-weight: bold;
  line-height: 22px;
  border-bottom: 1px solid #F0F0F0;
  margin-right: 10px;
  padding: 10px 0 10px;
  text-align: right;
`;

const Info = styled.div`
  width: 240px;
  text-align: left;
  line-height: 22px;
  border-bottom: 1px solid black;
  padding: 10px 0 10px;
  border-bottom: 1px solid #F0F0F0;
  max-width: 240px;
  line-break: auto;
  word-break: break-word;
`;
