import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Form, notification } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import routes from 'config/Route';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { getAccountManagerById, updateAccountManager } from 'store/account-manager/accountManagerThunk';
import { resetGetAccountManagerById, resetUpdateAccountManagerStatus } from 'store/account-manager/accountManagerSlice';
import { selectAccountManagerActionsError, selectAccountManagerActionsMessage, selectAccountManagerById, selectUpdateAccountManagerStatus } from 'store/account-manager/accountManagerSelector';
import { AccountManagerForm } from 'core/account-manager/AccountManager';
import { FormButtonLayout, FormErrorLabelLayout, FormLayout } from 'config/constants';
import PageContainer from 'components/layouts/PageContainer';

const AccountManagerUpdateScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const accountManager = useSelector(selectAccountManagerById);
  const accountManagerActionsMessage = useSelector(selectAccountManagerActionsMessage);
  const accountManagerUpdateError = useSelector(selectAccountManagerActionsError);
  const accountManagerUpdateStatus = useSelector(selectUpdateAccountManagerStatus);
  const isUpdateLoading = accountManagerUpdateStatus === RequestStatus.LOADING;
  const isUpdateSuccess = accountManagerUpdateStatus === RequestStatus.SUCCEESS;

  const onFinish = async (values: AccountManagerForm) => {
    dispatch(updateAccountManager(values, id))
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      notification.success({
        key: 'accountManager',
        message: `Success`,
        description: 'Account Manager successfully updated',
        placement: 'bottomRight',
      });
      if (accountManagerActionsMessage) {
        notification.warning({
          key: 'accountWarning',
          message: `Email notification was failed`,
          description: accountManagerActionsMessage,
          placement: 'bottomRight',
        });
      }
      history.push(routes.accountManagers.path);
    }
  }, [history, isUpdateSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  
  useEffect(() => {
    dispatch(getAccountManagerById(id));
    return () => {
      dispatch(resetUpdateAccountManagerStatus());
      dispatch(resetGetAccountManagerById());
    }
  }, [dispatch, id]);

  return (
    <PageContainer title="Account Manager Update">
      {
        accountManager && (
          <Container>
            <Form
              initialValues={accountManager}
              {...FormLayout}
              layout="horizontal"
              onFinish={onFinish}
            >
              <Form.Item rules={[{ required: true }]} name="email" label="Email (username)">
                <Input type="email" placeholder="Please enter email (username)" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    // eslint-disable-next-line
                    pattern: new RegExp("^[A-Za-z0-9\"\'!#$%&()*+,-./:;<=>?@^_`{|}~]*$"),
                    message: "Password should contain only latin letters, numbers and special characters!"
                  },
                  {
                    pattern: new RegExp("(?=.*[A-Z])"),
                    message: "At least one uppercase letter required!"
                  },
                  {
                    pattern: new RegExp("(?=.*[a-z])"),
                    message: "At least one lowercase letter required!"
                  },
                  {
                    // eslint-disable-next-line
                    pattern: new RegExp("(?=.*[\"\'!#$%&()*+,-./:;<=>?@^_`{|}~])"),
                    message: "At least one special character required!"
                  },
                  {
                    pattern: new RegExp(".*[0-9].*"),
                    message: "At least one digit required!"
                  },
                  {
                    min: 8,
                    message: "At least 8 symbols required!"
                  },
                ]}
                name="password"
                label="Password"
              >
                <Input.Password
                  autoComplete="new-password"
                  placeholder="Please enter password"
                  visibilityToggle={true}
                />
              </Form.Item>
              <Form.Item rules={[{ required: true }]} name="firstName" label="First name">
                <Input placeholder="Please enter first name" />
              </Form.Item>
              <Form.Item rules={[{ required: true }]} name="lastName" label="Last name">
                <Input placeholder="Please enter last name" />
              </Form.Item>
              <Form.Item rules={[{ required: false }]} name="contactAddress" label="Contact address (optional)">
                <Input placeholder="Please enter your contact address" />
              </Form.Item>
              <Form.Item rules={[{ required: false }]} name="phoneNumber" label="Phone number (optional)">
                <Input placeholder="Please enter your phone number" />
              </Form.Item>
              <Form.Item {...FormErrorLabelLayout}>
                <ErrorLabel>{accountManagerUpdateError}</ErrorLabel>
              </Form.Item>
              <Form.Item {...FormButtonLayout}>
                <Button loading={isUpdateLoading} style={{ marginRight: '10px' }} htmlType="submit" type="primary">Update</Button>
                <Button type="default" onClick={() => history.goBack()}>Cancel</Button>
              </Form.Item>
            </Form>
          </Container>
        )
      }
    </PageContainer>
  );
};

export default AccountManagerUpdateScreen;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.div`
  color: #ff4d4f;
`;