import axios, { AxiosInstance, AxiosResponse } from 'axios';
import AppConfig from 'config/appConfig';
import { notification } from 'antd';

import { store } from 'store/store';
import { signOutLocal } from 'store/user/userThunk';

const http: AxiosInstance = axios.create({
  baseURL: AppConfig.baseApiUrl,
});

http.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('lms_token') || "";

    config.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...(token && { 'Authorization': `Bearer ${token}` }),
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

http.interceptors.response.use((response: AxiosResponse) => {
  return response;
}, error => {
  if (!error.response) {
    notification.error({
      key: 'networkError',
      message: `Error`,
      description: 'Network Error',
      placement: 'bottomRight',
    });
    throw new Error('Network Error');
  }
  if (error.response && error.response.status === 404) {
    notification.error({
      key: '404',
      message: `Error`,
      description: error.response.data.error,
      placement: 'bottomRight',
    });
    window.location.href = "/404";
  }
  if (error.response && error.response.status === 401) {
    notification.error({
      key: 'unauthorized',
      message: `Error`,
      description: error.response.data.error,
      placement: 'bottomRight',
    });
    store.dispatch(signOutLocal());
    throw new Error('Unauthorized');
  }
  if (error.response && error.response.status >= 500) {
    notification.error({
      key: 'internalError',
      message: `Error`,
      description: 'Internal error',
      placement: 'bottomRight',
    });
    throw new Error('Internal Error');
  }
  if (error.response) {
    notification.error({
      key: 'unhandledError',
      message: `Error`,
      description: error.response.data.error,
      placement: 'bottomRight',
    });
    throw new Error(error.response.data.error);
  }
});

export default http;
