import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Form, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import routes from 'config/Route';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { resetCreateSuperuserStatus } from 'store/superuser/superuserSlice';
import { createSuperuser } from 'store/superuser/superuserThunk';
import { selectCreateSuperuserStatus, selectSuperuserActionsError, selectSuperuserActionsMessage } from 'store/superuser/superuserSelector';
import { SuperuserForm } from 'core/superuser/Superuser';
import { FormButtonLayout, FormErrorLabelLayout, FormLayout } from 'config/constants';
import PageContainer from 'components/layouts/PageContainer';

const SuperuserCreateScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const superuserCreateError = useSelector(selectSuperuserActionsError);
  const superuserActionsMessage = useSelector(selectSuperuserActionsMessage);
  const superuserCreateStatus = useSelector(selectCreateSuperuserStatus);
  const isCreateLoading = superuserCreateStatus === RequestStatus.LOADING;
  const isCreateSuccess = superuserCreateStatus === RequestStatus.SUCCEESS;

  const onFinish = async (values: SuperuserForm) => {
    dispatch(createSuperuser(values))
  };

  useEffect(() => {
    return () => {
      dispatch(resetCreateSuperuserStatus())
    };
  }, [dispatch]);

  useEffect(() => {
    if (isCreateSuccess) {
      notification.success({
        key: 'superuser',
        message: `Success`,
        description: 'Superuser successfully created',
        placement: 'bottomRight',
      });
      if (superuserActionsMessage) {
        notification.warning({
          key: 'superuserWarning',
          message: `Email notification was failed`,
          description: superuserActionsMessage,
          placement: 'bottomRight',
        });
      }
      history.push(routes.superusers.path);
    }
  }, [history, isCreateSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageContainer title="Superuser Creation">
      <Container>
        <Form
          {...FormLayout}
          layout="horizontal"
          onFinish={onFinish}
        >
          <Form.Item rules={[{ required: true }]} name="username" label="Username">
            <Input placeholder="Please enter username" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true },
              {
                // eslint-disable-next-line
                pattern: new RegExp("^[A-Za-z0-9\"\'!#$%&()*+,-./:;<=>?@^_`{|}~]*$"),
                message: "Password should contain only latin letters, numbers and special characters!"
              },
              {
                pattern: new RegExp("(?=.*[A-Z])"),
                message: "At least one uppercase letter required!"
              },
              {
                pattern: new RegExp("(?=.*[a-z])"),
                message: "At least one lowercase letter required!"
              },
              {
                // eslint-disable-next-line
                pattern: new RegExp("(?=.*[\"\'!#$%&()*+,-./:;<=>?@^_`{|}~])"),
                message: "At least one special character required!"
              },
              {
                pattern: new RegExp(".*[0-9].*"),
                message: "At least one digit required!"
              },
              {
                min: 8,
                message: "At least 8 symbols required!"
              },
            ]}
            name="password"
            label="Password"
          >
            <Input.Password
              autoComplete="new-password"
              placeholder="Please enter password"
              visibilityToggle={true}
            />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="email" label="Email">
            <Input type="email" placeholder="Please enter email" />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="firstName" label="First name">
            <Input placeholder="Please enter first name" />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="lastName" label="Last name">
            <Input placeholder="Please enter last name" />
          </Form.Item>
          <Form.Item rules={[{ required: false }]} name="contactAddress" label="Contact address (optional)">
            <Input placeholder="Please enter your contact address" />
          </Form.Item>
          <Form.Item rules={[{ required: false }]} name="phoneNumber" label="Phone number (optional)">
            <Input placeholder="Please enter your phone number" />
          </Form.Item>
          <Form.Item {...FormErrorLabelLayout}>
            <ErrorLabel>{superuserCreateError}</ErrorLabel>
          </Form.Item>
          <Form.Item {...FormButtonLayout}>
            <Button loading={isCreateLoading} style={{ marginRight: '10px' }} htmlType="submit" type="primary">Create</Button>
            <Link to={routes.superusers.path}>
              <Button type="default">Cancel</Button>
            </Link>
          </Form.Item>
        </Form>
      </Container>
    </PageContainer>
  );
};

export default SuperuserCreateScreen;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.div`
  color: #ff4d4f;
`;