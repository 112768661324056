import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { PageHeader, Layout, Table, Space, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import { AccountManager } from 'core/account-manager/AccountManager';
import RequestStatus from 'core/shared/enum/RequestStatus';
import UserRole from 'core/shared/enum/UserRole';
import { getAccountManagers } from 'store/account-manager/accountManagerThunk';
import { selectUserData } from 'store/user/userSelector';
import { selectAccountManagers, selectAccountManagersItemsPerPage, selectAccountManagersPage, selectAccountManagersStatus, selectAccountManagersTotalItems } from 'store/account-manager/accountManagerSelector';
import { useAppConfig } from 'components/context/AppConfigContext';
import { resetGetAccountManagers, updateAccountManagersItemsPerPage, updateAccountManagersPage } from 'store/account-manager/accountManagerSlice';

const { Content } = Layout;

const getColumns = (role: OrNull<UserRole>): ColumnsType<AccountManager> => {
  return [
    {
      title: 'Email (username)',
      dataIndex: 'username',
      render: (text, record) => (
        <Link to={`account-managers/${record.id}`}>{record.email}</Link>
      ),
      fixed: 'left',
    },
    {
      title: 'Name',
      render: (text, record) => (
        <span>{record.firstName} {record.lastName}</span>
      ),
    },
    {
      title: 'Managed organizations',
      dataIndex: 'organization',
      render: (text, record) => (
        <span>{record.organizationsManagedBy.map(item => item.name).join(', ') || '-'}</span>
      ),
    },
    {
      title: 'Status',
      key: 'active',
      render: (text, record) => (
        <>
          <Space size="middle" style={{ wordBreak: "normal"}}>
            <ActiveData isActive={record.active}>{record.active ? 'Active' : 'Not active'}</ActiveData>
          </Space>
        </>
      ),
    },
    ...(role === UserRole.ROLE_SUPER_USER ? [
      {
        title: 'Action',
        key: 'action',
        render: (text: string, record: AccountManager) => (
          <>
            <Space size="middle" style={{ wordBreak: "normal"}}>
              {role === UserRole.ROLE_SUPER_USER && (   
                <Link to={`account-managers/${record.id}/update`}>Update</Link>
              )}
            </Space>
          </>
        ),
      },
    ] : []),
  ];
}

const AccountManagersScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { routes } = useAppConfig();

  const userData = useSelector(selectUserData);
  const accountManagersStatus = useSelector(selectAccountManagersStatus);
  const accountManagers = useSelector(selectAccountManagers);
  const accountManagersPage = useSelector(selectAccountManagersPage);
  const accountManagersTotalItems = useSelector(selectAccountManagersTotalItems);
  const accountManagersItemsPerPage = useSelector(selectAccountManagersItemsPerPage);

  const accountManagersColumns = getColumns(userData.userRole);

  useEffect(() => {
    dispatch(getAccountManagers(accountManagersPage, accountManagersItemsPerPage));
  }, [dispatch, accountManagersPage, accountManagersItemsPerPage]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAccountManagers());
    }
  }, [dispatch]);

  const onPageChange = (page: number) => dispatch(updateAccountManagersPage(page));

  return (
    <>
      <PageHeader
        title="Account Managers"
        ghost={false}
      >
        
      </PageHeader>
      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          minHeight: 280,
          backgroundColor: '#fff',
        }}
      >
        <Table
          scroll={{ x: "max-content" }}
          title={() => (<TitleContainer>
            <h2>Account Managers</h2>
            {userData.userRole === UserRole.ROLE_SUPER_USER &&
              <Link to={routes.accountManagersCreate.path}>
                <Button
                  type="primary"
                  size="large"
                >
                  <PlusOutlined />
                  Add new
                </Button>
              </Link>
            }
          </TitleContainer>)}
          loading={accountManagersStatus === RequestStatus.LOADING}
          rowKey={record => record.id}
          pagination={{
            defaultPageSize: accountManagersItemsPerPage,
            current: accountManagersPage,
            total: accountManagersTotalItems,
            onChange: onPageChange,
            showSizeChanger: true,
            onShowSizeChange: (current, size) => {dispatch(updateAccountManagersPage(1)); dispatch(updateAccountManagersItemsPerPage(size))},
            showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`
          }}
          columns={accountManagersColumns}
          dataSource={accountManagers}
        />
      </Content>
    </>
  );
};

export default AccountManagersScreen;

const ActiveData = styled.span<{ isActive: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? '#52C41A' : '#FF4D4F')}; 
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

