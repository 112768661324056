import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import styled from '@emotion/styled/macro';
import { useDispatch } from 'react-redux';
import { Button, Input, Form, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import routes from 'config/Route';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { resetCreateOrganizationStatus } from 'store/organization/organizationSlice';
import { createOrganization } from 'store/organization/organizationThunk';
import { selectCreateOrganizationStatus, selectOrganizationActionsError } from 'store/organization/organizationSelector';
import { OrganizationCreateForm } from 'core/organization/Organization';
import { FormButtonLayout, FormErrorLabelLayout, FormLayout } from 'config/constants';
import PageContainer from 'components/layouts/PageContainer';

const OrganizationsCreateScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const createOrganizationStatus = useSelector(selectCreateOrganizationStatus);
  const createOrganizationError = useSelector(selectOrganizationActionsError);
  const isCreateLoading = createOrganizationStatus === RequestStatus.LOADING;
  const isCreateSuccess = createOrganizationStatus === RequestStatus.SUCCEESS;

  const onFinish = async (values: OrganizationCreateForm) => {
    dispatch(createOrganization(values))
  };

  useEffect(() => {
    return () => {
      dispatch(resetCreateOrganizationStatus())
    };
  }, [dispatch]);

  useEffect(() => {
    if (isCreateSuccess) {
      notification.success({
        key: 'organization',
        message: `Success`,
        description: 'Organization successfully created',
        placement: 'bottomRight',
      });
      history.push(routes.organizations.path);
    }
  }, [history, isCreateSuccess]);

  return (
    <PageContainer title="Organization Creation">
      <Container>
        <Form
          {...FormLayout}
          layout="horizontal"
          onFinish={onFinish}
        >
          <Form.Item rules={[{ required: true }]} name="name" label="Organization name: ">
            <Input placeholder="Please enter the name of organization" />
          </Form.Item>
          <Form.Item {...FormErrorLabelLayout}>
            <ErrorLabel>{createOrganizationError}</ErrorLabel>
          </Form.Item>
          <Form.Item {...FormButtonLayout}>
            <Button loading={isCreateLoading} style={{ marginRight: '10px' }} htmlType="submit" type="primary">Create</Button>
            <Link to={routes.organizations.path}>
              <Button type="default">Cancel</Button>
            </Link>
          </Form.Item>
        </Form>
      </Container>
    </PageContainer>
  );
};

export default OrganizationsCreateScreen;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.div`
  color: #ff4d4f;
`;
