import ApiMethods from 'core/shared/enum/ApiMethod';
import { GetOrganizationsResponse } from 'core/organization/OrganizationResponse';
import { GetOrganizationByIdResponse } from 'core/organization/OrganizationResponse';
import ActivityStatus from 'core/shared/enum/ActivityStatus';

import http from './request';
import { Organization, OrganizationBase, OrganizationUpdateForm } from 'core/organization/Organization';
import { OrganizationCreateForm } from 'core/organization/Organization';

const getOrganizations = (accountManager: string, status: ActivityStatus, page: number, itemsPerPage: number): Promise<GetOrganizationsResponse> => {
  const offset = itemsPerPage * (page - 1);

  const accountManagerId = (accountManager === 'ALL' || accountManager === 'MY') ? '' : `&accountManagerId=${accountManager}`;
  const url = `/organizations?my=${accountManager === 'MY'}&active=${status === ActivityStatus.ACTIVE}${accountManagerId}`;

  return http.request({
    url: `${url}&max=${itemsPerPage}&offset=${offset}`,
    method: ApiMethods.GET,
  });
};

const getOrganizationsBase = (): Promise<{ data: OrganizationBase[]}> => {
  return http.request({
    url: "/organizations/basedetails",
    method: ApiMethods.GET,
  });
};

const getOrganizationById = (id: string): Promise<GetOrganizationByIdResponse> => {
  return http.request({
    url: `/organizations/${id}`,
    method: ApiMethods.GET,
  });
};

const createOrganization = (data: OrganizationCreateForm & { managedBy: string }): Promise<{data : Organization }> => {
  return http.request({
    url: '/organizations',
    method: ApiMethods.POST,
    data,
  });
};

const updateOrganization = (data: OrganizationUpdateForm, id: string): Promise<{data : Organization }> => {
  return http.request({
    url: `/organizations/${id}`,
    method: ApiMethods.PUT,
    data,
  });
};

const setEnableOrganization = (id: string, isActive: boolean): Promise<{data: Organization }> => {
  return http.request({
    url: `/organizations/${id}/enable?enabled=${isActive}`,
    method: ApiMethods.POST,
  });
};

const deleteOrganization = (id: string): Promise<{}> => {
  return http.request({
    url: `/organizations/${id}`,
    method: ApiMethods.DELETE,
  });
};

const OrganizationService = {
  getOrganizations,
  getOrganizationsBase,
  createOrganization,
  updateOrganization,
  getOrganizationById,
  setEnableOrganization,
  deleteOrganization,
};

export default OrganizationService;
