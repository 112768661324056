import { RootState } from 'store/store';

export const selectProductStatusPF = (state: RootState) => state.filter.productsFilter.productStatus;

export const selectOrganizationStatusOF = (state: RootState) => state.filter.organizationsFilter.organizationStatus;
export const selectAccountManagerOF = (state: RootState) => state.filter.organizationsFilter.accountManager;

export const selectClientStatusCF = (state: RootState) => state.filter.clientsFilter.clientStatus;
export const selectAccountManagerCF = (state: RootState) => state.filter.clientsFilter.accountManager;
export const selectOrganizationOF = (state: RootState) => state.filter.clientsFilter.organization;

export const selectLicenseStatusLF = (state: RootState) => state.filter.licensesFilter.licenseStatus;
export const selectAccountManagerLF = (state: RootState) => state.filter.licensesFilter.accountManager;
export const selectOrganizationLF = (state: RootState) => state.filter.licensesFilter.organization;
export const selectProductLF = (state: RootState) => state.filter.licensesFilter.product;