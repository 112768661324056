import { UpdateAccountPasswordForm, UpdateAccountDataForm } from 'core/user/Account';
import UserRole from 'core/shared/enum/UserRole';
import { AppThunk, AppDispatch } from 'store/store';

import UserService from 'services/api/UserService';
import {
  updateUserData,
  signInRequest,
  signInSucceess,
  signInFailure,
  signOutLocalAction,
  signOutRequest,
  signOutSucceess,
  signOutFailure,
  signOutReset,
  getAccountRequest,
  getAccountSucceess,
  getAccountFailure,
  updateAccountPassswordRequest,
  updateAccountPassswordSucceess,
  updateAccountPassswordFailure,
  updateAccountDataRequest,
  updateAccountDataSucceess,
  updateAccountDataFailure,
  getAppDataRequest,
  getAppDataSucceess,
  getAppDataFailure,
  requestResetPasswordRequest,
  requestResetPasswordSucceess,
  requestResetPasswordFailure,
  validateTokenRequest,
  validateTokenSucceess,
  validateTokenFailure,
  resetPasswordRequest,
  resetPasswordSucceess,
  resetPasswordFailure,
} from './userSlice';
import { SignInThunkParams } from './userTypes';

const detectUser = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    const authTocken = localStorage.getItem('lms_token');

    const username = localStorage.getItem('lms_username');
    const userId = localStorage.getItem('lms_user_id');
    const userRole = localStorage.getItem('lms_user_role') as UserRole;

    if (authTocken) {
      dispatch(updateUserData({
        username: username || '',
        userId: userId || '',
        userRole,
        isAuth: true
      }));
    }
  } catch (err) {
    dispatch(signInFailure(err.toString()));
  }
};

const signIn = (data: SignInThunkParams): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(signInRequest());
    const { data: { username, token, id, roles } } = await UserService.signIn(data);

    const role = roles.filter(role => role !== UserRole.ROLE_CLIENT)[0];

    localStorage.setItem('lms_username', username);
    localStorage.setItem('lms_token', token);
    localStorage.setItem('lms_user_id', id);
    localStorage.setItem('lms_user_role', role);

    dispatch(signInSucceess({ username, userId: id, userRole: role }));
  } catch (err) {
    dispatch(signInFailure(err.response?.data.message || err.toString()));
  }
};

const getAccount = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getAccountRequest());
    const userRole = localStorage.getItem('lms_user_role') as UserRole;
    const response = await UserService.getAccountData(userRole);
    dispatch(getAccountSucceess(response.data));
  } catch (err) {
    dispatch(getAccountFailure(err.toString()));
  }
};

const getAppData = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getAppDataRequest());
    const response = await UserService.getAppData();
    dispatch(getAppDataSucceess(response.data));
  } catch (err) {
    dispatch(getAppDataFailure(err.toString()));
  }
};

const updateAccountPassword = (data: UpdateAccountPasswordForm): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(updateAccountPassswordRequest());
    const userRole = localStorage.getItem('lms_user_role') as UserRole;
    const response = await UserService.updateAccount(userRole, data);
    const token = response.data.data?.token;
    localStorage.setItem('lms_token', token || '');

    dispatch(updateAccountPassswordSucceess({ message: response.data.message || '' }));
  } catch (err) {
    dispatch(updateAccountPassswordFailure(err.toString()));
  }
};

const updateAccountData = (data: UpdateAccountDataForm): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(updateAccountDataRequest());
    const userRole = localStorage.getItem('lms_user_role') as UserRole;
    const response = await UserService.updateAccount(userRole, data);
    const token = response.data.data?.token;
    if (token) {
      localStorage.setItem('lms_token', token || '');
    }
    dispatch(updateAccountDataSucceess({ message: response.data.message || '' }));
  } catch (err) {
    dispatch(updateAccountDataFailure(err.toString()));
  }
};

const signOut = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(signOutRequest());
    await UserService.signOut();
    dispatch(signOutSucceess());
    localStorage.removeItem('lms_username');
    localStorage.removeItem('lms_token');
    localStorage.removeItem('lms_user_role');
    localStorage.removeItem('lms_user_id');
    dispatch(signOutReset());
  } catch (err) {
    localStorage.removeItem('lms_username');
    localStorage.removeItem('lms_token');
    localStorage.removeItem('lms_user_role');
    localStorage.removeItem('lms_user_id');
    dispatch(signOutFailure(err.toString()));
  }
};

const signOutLocal = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(signOutLocalAction());
  localStorage.removeItem('lms_username');
  localStorage.removeItem('lms_token');
  localStorage.removeItem('lms_user_role');
  localStorage.removeItem('lms_user_id');
};

const requestResetPassword = (email: string): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(requestResetPasswordRequest());
    await UserService.requestResetPassword(email);
    dispatch(requestResetPasswordSucceess());
  } catch (err) {
    dispatch(requestResetPasswordFailure(err.toString()));
  }
};

const validateToken = (token: string): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(validateTokenRequest());
    await UserService.validateResetPasswordToken(token);
    dispatch(validateTokenSucceess());
  } catch (err) {
    dispatch(validateTokenFailure(err.toString()));
  }
};

const resetPassword = (password: string, token: string): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(resetPasswordRequest());
    console.log(password);
    await UserService.resetPassword(password, token);
    dispatch(resetPasswordSucceess());
  } catch (err) {
    dispatch(resetPasswordFailure(err.toString()));
  }
};

export {
  detectUser,
  signIn,
  signOut,
  getAccount,
  updateAccountPassword,
  updateAccountData,
  signOutLocal,
  getAppData,
  requestResetPassword,
  validateToken,
  resetPassword,
};
