import { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Form, notification, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import routes from 'config/Route';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { LicenseForm } from 'core/license/License';
import { ProductBase } from 'core/product/Product';
import { resetCreateLicenseStatus } from 'store/license/licenseSlice';
import { resetGetOrganizationsBase } from 'store/organization/organizationSlice';
import { createLicense } from 'store/license/licenseThunk';
import { getOrganizationsBase } from 'store/organization/organizationThunk';
import { selectCreateLicenseStatus, selectLicenseActionsMessage, selectLicenseActionsError } from 'store/license/licenseSelector';
import { selectOrganizationsBase, selectOrganizationsBaseStatus } from 'store/organization/organizationSelector';
import { getProductsBase } from 'store/product/productThunk';
import { selectGetProductsBaseStatus, selectProductsBase } from 'store/product/productSelector';
import { resetGetProductsBase } from 'store/product/productSlice';
import ActivityStatus from 'core/shared/enum/ActivityStatus';
import { FormButtonLayout, FormErrorLabelLayout, FormLayout } from 'config/constants';
import PageContainer from 'components/layouts/PageContainer';
import { renderConfig, renderDynamicConfig } from 'helpers/licenseFormHelpers';

const LicenseCreateScreen: React.FC = () => {
  const [ form ] = Form.useForm<LicenseForm>();
  const [filteredProducts, setFilteredProducts] = useState<ProductBase[]>([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const organizationsStatus = useSelector(selectOrganizationsBaseStatus);
  const organizations = useSelector(selectOrganizationsBase);

  const productsStatus = useSelector(selectGetProductsBaseStatus);
  const products = useSelector(selectProductsBase);

  const licenseActionsMessage = useSelector(selectLicenseActionsMessage);
  const licenseCreateError = useSelector(selectLicenseActionsError);
  const licenseCreateStatus = useSelector(selectCreateLicenseStatus);
  const isLoading = licenseCreateStatus === RequestStatus.LOADING;
  const isSuccess = licenseCreateStatus === RequestStatus.SUCCEESS;

  const onFinish = async (values: LicenseForm) => {
    dispatch(createLicense(values));
  };

  useEffect(() => {
    dispatch(getOrganizationsBase());
    dispatch(getProductsBase(ActivityStatus.ACTIVE));
    return () => {
      dispatch(resetCreateLicenseStatus());
      dispatch(resetGetOrganizationsBase())
      dispatch(resetGetProductsBase());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        key: 'license',
        message: `Success`,
        description: 'License successfully created',
        placement: 'bottomRight',
      });
      if (licenseActionsMessage) {
        notification.warning({
          key: 'licenseEmail',
          message: `Email notification was failed`,
          description: licenseActionsMessage,
          placement: 'bottomRight',
        });
      }
      history.push(routes.licenses.path);
    }
  }, [history, isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  /* get valid configuration base on form configuration */
  const getValidConfiguration = (formConfiguration: ProductBase[]) => {
    let nonRelatedToProducts = formConfiguration.filter(item => !item.productsRelatedTo || !item.productsRelatedTo.length);
    let productsWithRelated = formConfiguration.filter(item => item.productsRelatedTo && !!item.productsRelatedTo.length);
    let validConfig = [
      ...nonRelatedToProducts,
    ];

    let isProductsAdded = true;

    while (isProductsAdded) {
      const validConfigIds = validConfig.map(item => item.id);
      let newValidProducts: ProductBase[] = [];

      /*
        checking is products with productsRelatedTo field can be added to configuration or not
        if products from productsRelatedTo fields meet in validConfig, so this product is valid
      */
      productsWithRelated.forEach(product => {
        const productsRelatedTo = product.productsRelatedTo.map(item => item.id);
        const commonProducts = productsRelatedTo.filter(x => validConfigIds.indexOf(x) !== -1);
        if (commonProducts.length) {
          newValidProducts.push(product);
        }
      })
      /* 
        if some products became valid, so we can add them to validConfig
        remove from productsWithRelated array and make one more loop with checking 
      */
      if (newValidProducts.length) {
        validConfig = [
          ...validConfig,
          ...newValidProducts
        ];
        productsWithRelated = productsWithRelated.filter(product => !newValidProducts.includes(product))
        isProductsAdded = true;
      } else {
        isProductsAdded = false;
      }
    }

    return validConfig;
  }

  const filterProducts = useCallback((products: ProductBase[]) => {
    const formValues = form.getFieldsValue();
    const currentConfigurationProductsIds = [
      formValues.baseProductId || "",
      ...formValues.configurations?.map(item => item?.productId || "") || [],
    ].filter(item => !!item);

    /* map configuration product Ids to ProductBase type */
    const currentConfigurationProductsValues = currentConfigurationProductsIds
      .reduce<ProductBase[]>(
        (acc, productId) => {
          const elem = products.find(product => product.id === productId);
          if (elem) acc.push(elem);
          return acc;
        },
        []
      );

    /* get valid configuration based on previous configuration */
    const validConfigurationProductsValues = getValidConfiguration(currentConfigurationProductsValues);
    const validConfigurationProductsIds = validConfigurationProductsValues.map(item => item.id);

    const newFilteredProducts = products.filter(item => !item.base).filter(item => {
      /* products without productsRelatedTo field can be selected */
      if (!item.productsRelatedTo.length) return true;
      const productRelatedTo = item.productsRelatedTo.map(item => item.id);
      /*
        check is any of products from productRelatedTo field match with current products configuration
        if yes, then product can be selected 
      */
      const commonProducts = productRelatedTo.filter(x => validConfigurationProductsIds.indexOf(x) !== -1);
      return commonProducts.length > 0;
    });

    setFilteredProducts(newFilteredProducts);
    return newFilteredProducts;
  }, [form]);

  useEffect(() => {
    if (products) {
      filterProducts(products);
    }
  }, [products, filterProducts]);


  return (
    <PageContainer title="License Creation">
      <Container>
        <Form
          {...FormLayout}
          form={form}
          layout="horizontal"
          onFinish={onFinish}
          name="license_form"
          onValuesChange={(value, allValues) => {
            if (value.baseProductId || value.configurations) {
              /* filtering all the products on onChange */
              const productsAfterFormChange = filterProducts(products);
              let updatedConfiguration = [ ...(allValues.configurations || []) ];
              /* checking is form configuration products valid after refiltering all products on onChange */
              allValues?.configurations?.forEach((configItem, index) => {
                if (!configItem || !configItem?.productId) return;
                /* checking is configuration product exists in filteredProducts after onChange */
                const isProductFound = productsAfterFormChange.find(el => el.id === configItem.productId);
                if (!isProductFound) {
                  updatedConfiguration[index] = {
                    productId: "",
                    count: undefined,
                    days: undefined,
                    permanent: false,
                  };
                }
              })
              form.setFieldsValue({ configurations: updatedConfiguration });
            }
          }}
        >
          <Form.Item
            rules={[{ required: true, max: 50 }]}
            name="name"
            label="Name"
            tooltip="Name field will be visible to client."
          >
            <Input placeholder="Please enter name" />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="description" label="Description">
            <Input placeholder="Please enter description" />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="organizationId" label="Organization">
            <Select loading={organizationsStatus === RequestStatus.LOADING}>
              {organizations.map(item => (
                <Select.Option key={item.id} value={item.id} style={{ color: item.active ? 'inherit' : '#FF4D4F' }}>
                  {item.name}{!item.active && ' [not active]'}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="baseProductId" label="Base product">
            <Select loading={productsStatus === RequestStatus.LOADING}>
              {products.filter(item => item.base).map(item => (
                <Select.Option key={item.id} value={item.id} style={{ color: item.active ? 'inherit' : '#FF4D4F' }}>
                  {item.name}{!item.active && ' [not active]'}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* if base product or base permanent has changed, then we should update data */}
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => prevValues.baseProductId !== curValues.baseProductId || prevValues.basePermanent !== curValues.basePermanent}
          >
            {
              () => renderConfig(products.find((elem: ProductBase) => elem.id === form.getFieldValue('baseProductId'))?.usageType, form)
            }
          </Form.Item>
          <Form.List name="configurations">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div style={{ border: '1px dashed grey', padding: '5px 0', marginBottom: '10px' }} key={key}>
                    <Form.Item
                      {...restField}
                      name={[name, 'productId']}
                      fieldKey={[key, 'productId']}
                      rules={[
                        { required: true, message: 'Missing product' },
                      ]}
                      label="Product"
                    >
                      <Select
                        loading={productsStatus === RequestStatus.LOADING}
                      >
                        {
                          /* Filtering products, to remove already selected items */
                          filteredProducts.filter(item => {
                            const allConfigs = [...form.getFieldsValue().configurations];

                            const canBeSelected = allConfigs.reduce((acc, configItem, index) => {
                              if (configItem && index !== name && configItem.productId === item.id) {
                                acc = false;
                              }
                              return acc;
                            }, true);
                            return canBeSelected;
                          }).map(item => (
                            <Select.Option key={item.id} value={item.id} style={{ color: item.active ? 'inherit' : '#FF4D4F' }}>
                              {item.name}{!item.active && ' [not active]'}
                            </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {/*  if product or permanent has changed, then we should update data */}
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) => prevValues.configurations[name]?.productId !== curValues.configurations[name]?.productId || prevValues.configurations[name]?.permanent !== curValues.configurations[name]?.permanent}
                    >
                      {
                        () => renderDynamicConfig(products.find(elem => elem.id === form.getFieldsValue().configurations[name]?.productId)?.usageType, restField, name, key, form)
                      }
                    </Form.Item>
                    <Form.Item {...FormButtonLayout} style={{ marginBottom: '5px' }}>
                      <Button type="default" onClick={() => remove(name)} style={{ width: '50%' }} block icon={<MinusCircleOutlined />}>
                        Remove Product
                        </Button>
                    </Form.Item>
                  </div>
                ))}
                {/* Button for adding extra producs; disabled, if additional configurations more than products */}
                <Form.Item {...FormButtonLayout}
                  shouldUpdate={(prevValues, curValues) => prevValues.configurations.length !== curValues.configurations.length}
                >
                  <Button disabled={filteredProducts.filter(item => !item.base).length <= form.getFieldsValue().configurations?.length} type="dashed" onClick={() => add()} style={{ width: '60%', marginTop: '10px' }} block icon={<PlusOutlined />}>
                    Add Product
                    </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item {...FormErrorLabelLayout}>
            <ErrorLabel>{licenseCreateError}</ErrorLabel>
          </Form.Item>
          <Form.Item {...FormButtonLayout}>
            <Button loading={isLoading} style={{ marginRight: '10px' }} htmlType="submit" type="primary">Create</Button>
            <Link to={routes.licenses.path}>
              <Button type="default">Cancel</Button>
            </Link>
          </Form.Item>
        </Form>
      </Container>
    </PageContainer>
  );
};

export default LicenseCreateScreen;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.div`
  color: #ff4d4f;
`;
