import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Form, notification, Select } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import routes from 'config/Route';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { resetCreateClientStatus, resetIsAccountManagerExists } from 'store/client/clientSlice';
import { resetGetOrganizationsBase } from 'store/organization/organizationSlice';
import { createClient, checkIsAccountManagerExist } from 'store/client/clientThunk';
import { getOrganizationsBase } from 'store/organization/organizationThunk';
import { selectCreateClientStatus, selectClientActionsError, selectAccountManagerData, selectClientActionsMessage } from 'store/client/clientSelector';
import { selectOrganizationsBase, selectOrganizationsBaseStatus } from 'store/organization/organizationSelector';
import { ClientForm } from 'core/client/Client';
import { FormButtonLayout, FormErrorLabelLayout } from 'config/constants';
import PageContainer from 'components/layouts/PageContainer';

const ClientCreateScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [ form ] = Form.useForm();

  const organizationsStatus = useSelector(selectOrganizationsBaseStatus);
  const organizations = useSelector(selectOrganizationsBase);
  const accountManagerData = useSelector(selectAccountManagerData);

  const clientCreateError = useSelector(selectClientActionsError);
  const clientActionsMessage = useSelector(selectClientActionsMessage);
  const clientCreateStatus = useSelector(selectCreateClientStatus);
  const isCreateLoading = clientCreateStatus === RequestStatus.LOADING;
  const isCreateSuccess = clientCreateStatus === RequestStatus.SUCCEESS;

  const formItemLayout = {
    labelCol: {
      md: {
        span: 8,
      },
      sm: {
        span: 7,
      }
    },
    wrapperCol: {
      md: {
        span: 14,
      },
      sm: {
        span: 10,
      },
      xs: {
        span: 1,
      }
    },
  }

  const onFinish = async (values: ClientForm) => {
    dispatch(createClient(values))
  };

  useEffect(() => {
    dispatch(getOrganizationsBase());
    return () => {
      dispatch(resetCreateClientStatus());
      dispatch(resetGetOrganizationsBase());
      dispatch(resetIsAccountManagerExists());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isCreateSuccess) {
      notification.success({
        key: 'client',
        message: `Success`,
        description: 'Client successfully created',
        placement: 'bottomRight',
      });
      if (clientActionsMessage) {
        notification.warning({
          key: 'clientEmail',
          message: `Email notification was failed`,
          description: clientActionsMessage,
          placement: 'bottomRight',
        });
      }
      history.push(routes.clients.path);
    }
  }, [history, isCreateSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accountManagerData) {
      form.setFieldsValue({
        password: '!Placeholder1',
        firstName: accountManagerData.firstName,
        lastName: accountManagerData.lastName,
        contactAddress: accountManagerData.contactAddress,
        phoneNumber: accountManagerData.phoneNumber,
      });
      notification.success({
        key: 'client',
        message: `User already exists.`,
        description: 'This user already exists with the Account Manager\'s role. Do you want to add the User role? If yes, please fill Client title and Organization fields.',
        placement: 'bottomRight',
      });
    } else {
      form.setFieldsValue({
        firstName: '',
        password: '',
        lastName: '',
        contactAddress:  '',
        phoneNumber: '',
      })
    }
  }, [form, accountManagerData]);
  
  return (
    <PageContainer title="Client Creation">
      <Container>
        <Form
          {...formItemLayout}
          layout="horizontal"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            rules={[{ required: true }]}
            name="email"
            label="Email (username)"
          >
            <Input
              type="email"
              onBlur={(e) => {
                dispatch(checkIsAccountManagerExist(e.target.value))
              }}
              onChange={(e) => {
                if (accountManagerData) {
                  dispatch(resetIsAccountManagerExists())
                }
              }}
              placeholder="Please enter email (username)"
            />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true },
              {
                // eslint-disable-next-line
                pattern: new RegExp("^[A-Za-z0-9\"\'!#$%&()*+,-./:;<=>?@^_`{|}~]*$"),
                message: "Password should contain only latin letters, numbers and special characters!"
              },
              {
                pattern: new RegExp("(?=.*[A-Z])"),
                message: "At least one uppercase letter required!"
              },
              {
                pattern: new RegExp("(?=.*[a-z])"),
                message: "At least one lowercase letter required!"
              },
              {
                // eslint-disable-next-line
                pattern: new RegExp("(?=.*[\"\'!#$%&()*+,-./:;<=>?@^_`{|}~])"),
                message: "At least one special character required!"
              },
              {
                pattern: new RegExp(".*[0-9].*"),
                message: "At least one digit required!"
              },
              {
                min: 8,
                message: "At least 8 symbols required!"
              },
            ]}
            name="password"
            label="Password"
          >
            <Input.Password
              autoComplete="new-password"
              placeholder="Please enter password"
              visibilityToggle={true}
              disabled={!!accountManagerData}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            name="title"
            label="Client title"
            tooltip="Example: Mr., Ms., Dr., Prof."
          >
            <Input placeholder="Please enter client title" />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="firstName" label="First name">
            <Input disabled={!!accountManagerData} placeholder="Please enter first name" />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="lastName" label="Last name">
            <Input disabled={!!accountManagerData} placeholder="Please enter last name" />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="organizationId" label="Organization">
            <Select loading={organizationsStatus === RequestStatus.LOADING}>
              {organizations.map(item => (
                <Select.Option key={item.id} value={item.id} style={{ color: item.active ? 'inherit' : '#FF4D4F' }}>
                  {item.name}{!item.active && ' [not active]'}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item rules={[{ required: false }]} name="contactAddress" label="Contact address (optional)">
            <Input disabled={!!accountManagerData} placeholder="Please enter your contact address" />
          </Form.Item>
          <Form.Item rules={[{ required: false }]} name="phoneNumber" label="Phone number (optional)">
            <Input disabled={!!accountManagerData} placeholder="Please enter your phone number" />
          </Form.Item>
          <Form.Item {...FormErrorLabelLayout}>
            <InfoLabel>{accountManagerData && "This user already exists with the Account Manager's role. Do you want to add the User role? If yes, please fill Client title and Organization fields."}</InfoLabel>
            <ErrorLabel>{clientCreateError}</ErrorLabel>
          </Form.Item>
          <Form.Item {...FormButtonLayout}>
            <Button loading={isCreateLoading} style={{ marginRight: '10px' }} htmlType="submit" type="primary">Create</Button>
            <Link to={routes.clients.path}>
              <Button type="default">Cancel</Button>
            </Link>
          </Form.Item>
        </Form>
      </Container>
    </PageContainer>
  );
};

export default ClientCreateScreen;

const Container = styled.div`
  maxWidth: 600px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.div`
  color: #ff4d4f;
`;

const InfoLabel = styled.div`
  color: #44a115;
`;
