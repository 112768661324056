import { createSlice, PayloadAction, combineReducers } from '@reduxjs/toolkit';

import { Product } from 'core/product/Product';
import { ProductBase } from 'core/product/Product';
import RequestStatus from 'core/shared/enum/RequestStatus';
import ItemsPerPage from 'core/shared/enum/ItemsPerPage';

import {
  ProductsState,
  ProductByIdState,
  ProductActionsState,
  ProductsBaseState,
} from './productTypes';

const productsInitialState: ProductsState = {
  getProductsStatus: RequestStatus.IDLE,
  products: [],
  page: 1,
  totalItems: 0,
  itemsPerPage: ItemsPerPage.TEN,
  error: '',
};

export const productsSlice = createSlice({
  name: 'productsSlice',
  initialState: productsInitialState,
  reducers: {
    getProductsRequest: (state) => {
      state.getProductsStatus = RequestStatus.LOADING;
    },
    getProductsSucceess: (state, action: PayloadAction<{ products: Product[], totalItems: number }>) => {
      const { payload } = action;
      state.getProductsStatus = RequestStatus.SUCCEESS;
      state.products = payload.products;
      state.totalItems = payload.totalItems;
    },
    getProductsFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.getProductsStatus = RequestStatus.FAILURE;
    },
    updateProductsPage: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.page = payload;
    },
    updateProductsItemsPerPage: (state, action: PayloadAction<ItemsPerPage>) => {
      const { payload } = action;
      state.itemsPerPage = payload;
    },
    resetGetProducts: (state) => {
      state.getProductsStatus = RequestStatus.IDLE;
      state.products = [];
      state.error = '';
    },
  },
});

const productsBaseInitialState: ProductsBaseState = {
  getProductsBaseStatus: RequestStatus.IDLE,
  products: [],
  error: '',
};

export const productsBaseSlice = createSlice({
  name: 'productsBaseSlice',
  initialState: productsBaseInitialState,
  reducers: {
    getProductsBaseRequest: (state) => {
      state.getProductsBaseStatus = RequestStatus.LOADING;
    },
    getProductsBaseSucceess: (state, action: PayloadAction<{ products: ProductBase[] }>) => {
      const { payload } = action;
      state.getProductsBaseStatus = RequestStatus.SUCCEESS;
      state.products = payload.products;
    },
    getProductsBaseFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.getProductsBaseStatus = RequestStatus.FAILURE;
      state.error = payload;
    },
    resetGetProductsBase: () => ({ ...productsBaseInitialState }),
  },
});

const productByIdInitialState: ProductByIdState = {
  getProductByIdStatus: RequestStatus.IDLE,
  product: null,
  error: '',
};

export const productByIdSlice = createSlice({
  name: 'productByIdSlice',
  initialState: productByIdInitialState,
  reducers: {
    getProductByIdRequest: (state) => {
      state.getProductByIdStatus = RequestStatus.LOADING;
    },
    getProductByIdSucceess: (state, action: PayloadAction<Product>) => {
      const { payload } = action;
      state.product = payload;
      state.getProductByIdStatus = RequestStatus.SUCCEESS;
    },
    getProductByIdFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.getProductByIdStatus = RequestStatus.FAILURE;
    },
    resetGetProductById: () => ({ ...productByIdInitialState }),
  },
});

const productActionsInitialState: ProductActionsState = {
  setEnableProductStatus: RequestStatus.IDLE,
  createProductStatus: RequestStatus.IDLE,
  updateProductStatus: RequestStatus.IDLE,
  deleteProductStatus: RequestStatus.IDLE,
  error: '',
};

export const productActionsSlice = createSlice({
  name: 'productActionsSlice',
  initialState: productActionsInitialState,
  reducers: {
    setEnableProductRequest: (state) => {
      state.setEnableProductStatus = RequestStatus.LOADING;
    },
    setEnableProductSucceess: (state) => {
      state.setEnableProductStatus = RequestStatus.SUCCEESS;
    },
    setEnableProductFailure: (state) => {
      state.setEnableProductStatus = RequestStatus.FAILURE;
    },
    resetSetEnableProductStatus: (state) => {
      state.setEnableProductStatus = RequestStatus.IDLE;
    },
    createProductRequest: (state) => {
      state.createProductStatus = RequestStatus.LOADING;
    },
    createProductSucceess: (state) => {
      state.createProductStatus = RequestStatus.SUCCEESS;
    },
    createProductFailure: (state, action: PayloadAction<string>) => {
      state.createProductStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetCreateProductStatus: (state) => {
      state.createProductStatus = RequestStatus.IDLE;
      state.error = '';
    },
    updateProductRequest: (state) => {
      state.updateProductStatus = RequestStatus.LOADING;
    },
    updateProductSucceess: (state) => {
      state.updateProductStatus = RequestStatus.SUCCEESS;
    },
    updateProductFailure: (state, action: PayloadAction<string>) => {
      state.updateProductStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetUpdateProductStatus: (state) => {
      state.updateProductStatus = RequestStatus.IDLE;
      state.error = '';
    },
    deleteProductRequest: (state) => {
      state.deleteProductStatus = RequestStatus.LOADING;
    },
    deleteProductSucceess: (state) => {
      state.deleteProductStatus = RequestStatus.SUCCEESS;
    },
    deleteProductFailure: (state, action: PayloadAction<string>) => {
      state.deleteProductStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetDeleteProductStatus: (state) => {
      state.deleteProductStatus = RequestStatus.IDLE;
      state.error = '';
    },
  },
});

export const {
  getProductsRequest,
  getProductsSucceess,
  getProductsFailure,
  updateProductsPage,
  updateProductsItemsPerPage,
  resetGetProducts,
} = productsSlice.actions;

  export const {
  getProductsBaseRequest,
  getProductsBaseSucceess,
  getProductsBaseFailure,
  resetGetProductsBase,
  } = productsBaseSlice.actions;

export const {
  getProductByIdRequest,
  getProductByIdSucceess,
  getProductByIdFailure,
  resetGetProductById,
} = productByIdSlice.actions;

export const {
  setEnableProductRequest,
  setEnableProductSucceess,
  setEnableProductFailure,
  resetSetEnableProductStatus,
  createProductRequest,
  createProductSucceess,
  createProductFailure,
  resetCreateProductStatus,
  updateProductRequest,
  updateProductSucceess,
  updateProductFailure,
  resetUpdateProductStatus,
  deleteProductRequest,
  deleteProductSucceess,
  deleteProductFailure,
  resetDeleteProductStatus,
} = productActionsSlice.actions;

export default combineReducers({
  productList: productsSlice.reducer,
  productsBaseList: productsBaseSlice.reducer,
  productById: productByIdSlice.reducer,
  productActions: productActionsSlice.reducer,
});
