import { Global, css } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';

const GlobalStyles: React.FC= () => {
  return (
    <Global
      styles={css`
      ${emotionNormalize}
      * {
        box-sizing: border-box;
        line-height: 1.2;
      }

      html,
      body,
      #root {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
      }

      body {
        font-size: 14px;
      }

      a, button {
        cursor: pointer;
      }

      a {
        text-decoration: none;
      }

      ul, p, h1, h2, h3, h4, h5, h6  {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }

      .ant-table-tbody > tr > td {
        line-break: auto;
        word-break: break-word;
      }
      .ant-table-fixed {
        table-layout: fixed;
      }

      .ant-pagination-item a {
        display: inline-block;
      }

      .ant-pagination-item-link {
        display: inline-block;
      }

      .ant-pagination-item-link .ant-pagination-item-container  {
        left: -3px;
      }
      `}
    />
  );
};

export { GlobalStyles };
