import { AppThunk, AppDispatch } from 'store/store';
import { 
  getClientsRequest,
  getClientsSucceess,
  getClientsFailure,
  getClientByIdRequest,
  getClientByIdSucceess,
  getClientByIdFailure,
  setEnableClientRequest,
  setEnableClientSucceess,
  setEnableClientFailure,
  createClientRequest,
  createClientSucceess,
  createClientFailure,
  isAccountManagerExistsRequest,
  isAccountManagerExistsSucceess,
  isAccountManagerExistsFailure,
  updateClientRequest,
  updateClientSucceess,
  updateClientFailure,
  deleteClientRequest,
  deleteClientSucceess,
  deleteClientFailure,
} from './clientSlice';
import ClientService from 'services/api/ClientService';
import ActivityStatus from 'core/shared/enum/ActivityStatus';
import AccountManagerService from 'services/api/AccountManagerService';
import { ClientForm } from 'core/client/Client';

const getClients = (accountManager: string, status: ActivityStatus, organizationId: string, page: number, itemsPerPage: number): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getClientsRequest());
    const response = await ClientService.getClients(accountManager, status, organizationId, page, itemsPerPage);
    dispatch(getClientsSucceess({
      clients: response.data.data,
      totalItems: response.data.totalItems
    }));
  } catch (err) {
    dispatch(getClientsFailure(err.toString()));
  }
};

const getClientById = (id: string): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getClientByIdRequest());
    const response = await ClientService.getClientById(id);
    dispatch(getClientByIdSucceess(response.data));
  } catch (err) {
    dispatch(getClientByIdFailure(err.toString()));
  }
};

const createClient = (data: ClientForm) => async (dispatch: AppDispatch) => {
  try {
    dispatch((createClientRequest()));
    const response = await ClientService.createClient(data);
    dispatch(createClientSucceess({ message: response.data.message || '' }));
  } catch (err) {
    dispatch(createClientFailure(err.message));
  }
};

const updateClient = (data: ClientForm, id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch((updateClientRequest()));
    const response = await ClientService.updateClient(data, id);
    dispatch(updateClientSucceess({ message: response.data.message || '' }));
  } catch (err) {
    dispatch(updateClientFailure(err.message));
  }
};

const setEnableClient = (id: string, isActive: boolean) => async (dispatch: AppDispatch) => {
  try {
    dispatch((setEnableClientRequest()));
    await ClientService.setEnableClient(id, isActive);
    dispatch(setEnableClientSucceess());
  } catch (err) {
    dispatch(setEnableClientFailure());
  }
};

const deleteClient = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(deleteClientRequest());
    await ClientService.deleteClient(id);
    dispatch(deleteClientSucceess());
  } catch (err) {
    dispatch(deleteClientFailure(err.message));
  }
};

const checkIsAccountManagerExist = (email: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isAccountManagerExistsRequest());
    const response = await AccountManagerService.getAccountManagerByEmail(email);
    dispatch(isAccountManagerExistsSucceess(response.data.data[0] || null));
  } catch (err) {
    dispatch(isAccountManagerExistsFailure(err.message));
  }
};

export {
  getClients,
  getClientById,
  setEnableClient,
  createClient,
  updateClient,
  deleteClient,
  checkIsAccountManagerExist,
};
