import {Component, CSSProperties, ErrorInfo} from "react";
import UserService from "services/api/UserService";

const stylesRoot: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
};

const stylesInner: CSSProperties = {
    maxWidth: '600px',
    borderRadius: '10px',
    background: '#eaeaea',
    padding: '2em',
    fontSize: '1.2em',
};

export default class ErrorBoundary extends Component<{}, { hasError: boolean }> {
    state = {
        hasError: false,
    }

    static getDerivedStateFromError = (error: Error) => {
        return { hasError: true };
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        UserService.logError(`${error.name} ${error.message}`, error.stack || "");
        this.setState({hasError: true});
    }

    render() {
        const {hasError} = this.state;
        const {children} = this.props;
        if (hasError) {
            return (
                <div style={{ ...stylesRoot }}>
                    <div style={{ ...stylesInner }}>
                        <div>
                            Ooops, failed to load page. We are looking into this.
                        </div>
                        <br/>
                        <div>
                            Please refresh the page or <a href='/'>click here</a> to continue.
                        </div>
                    </div>
                </div>
            );
        }

        return children;
    }
}
