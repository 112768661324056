import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { PageHeader, Layout, Table, Space, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import { Superuser } from 'core/superuser/Superuser';
import RequestStatus from 'core/shared/enum/RequestStatus';
import { getSuperusers } from 'store/superuser/superuserThunk';
import { selectSuperusers, selectSuperusersItemsPerPage, selectSuperusersPage, selectSuperusersStatus, selectSuperusersTotalItems } from 'store/superuser/superuserSelector';
import { useAppConfig } from 'components/context/AppConfigContext';
import { resetGetSuperusers, updateSuperusersItemsPerPage, updateSuperusersPage } from 'store/superuser/superuserSlice';

const { Content } = Layout;

const columns: ColumnsType<Superuser> = [
  {
    title: 'Username',
    dataIndex: 'username',
    render: (text, record) => (
      <Link to={`superusers/${record.id}`}>{record.username}</Link>
    ),
    fixed: 'left',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'Status',
    key: 'active',
    render: (text, record) => (
      <>
        <Space size="middle" style={{ wordBreak: "normal"}}>
          <ActiveData isActive={record.active}>{record.active ? 'Active' : 'Not active'}</ActiveData>
        </Space>
      </>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    width: '100px',
    render: (text, record) => (
      <>
        <Space size="middle" style={{ wordBreak: "normal"}}>
          <Link to={`superusers/${record.id}/update`}>Update</Link>
        </Space>
      </>
    ),
  },
];

const SuperusersScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { routes } = useAppConfig();

  const superusersStatus = useSelector(selectSuperusersStatus);
  const superusers = useSelector(selectSuperusers);
  const superusersPage = useSelector(selectSuperusersPage);
  const superusersTotalItems = useSelector(selectSuperusersTotalItems);
  const superusersItemsPerPage = useSelector(selectSuperusersItemsPerPage);

  useEffect(() => {
    dispatch(getSuperusers(superusersPage, superusersItemsPerPage));
  }, [dispatch, superusersPage, superusersItemsPerPage]);
  
  useEffect(() => {
    return () => {
      dispatch(resetGetSuperusers());
    }
  }, [dispatch]);

  const onPageChange = (page: number) => dispatch(updateSuperusersPage(page));

  return (
    <>
      <PageHeader
        title="Superusers"
        ghost={false}
      >
      </PageHeader>
      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          minHeight: 280,
          backgroundColor: '#fff',
        }}
      >
        <Table
          scroll={{ x: "max-content" }}
          title={() => (<TitleContainer>
            <h2>Superusers</h2>
            <Link to={routes.superusersCreate.path}>
              <Button
                type="primary"
                size="large"
              >
                <PlusOutlined />
                Add new
              </Button>
            </Link>
          </TitleContainer>)}
          loading={superusersStatus === RequestStatus.LOADING}
          rowKey={record => record.id}
          pagination={{
            defaultPageSize: superusersItemsPerPage,
            current: superusersPage,
            total: superusersTotalItems,
            onChange: onPageChange,
            showSizeChanger: true,
            onShowSizeChange: (current, size) => {dispatch(updateSuperusersPage(1)); dispatch(updateSuperusersItemsPerPage(size))},
            showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`
          }}
          columns={columns}
          dataSource={superusers}
        />
      </Content>
    </>
  );
};

export default SuperusersScreen;

const ActiveData = styled.span<{ isActive: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? '#52C41A' : '#FF4D4F')}; 
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
