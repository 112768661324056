import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Superuser } from 'core/superuser/Superuser';
import RequestStatus from 'core/shared/enum/RequestStatus';

import { SuperusersState, SuperuserByIdState, SuperuserActionsState } from './superuserTypes';
import ItemsPerPage from 'core/shared/enum/ItemsPerPage';

const superusersInitialState: SuperusersState = {
  getSuperusersStatus: RequestStatus.IDLE,
  superusers: [],
  page: 1,
  totalItems: 0,
  itemsPerPage: ItemsPerPage.TEN,
  error: '',
};

export const superusersSlice = createSlice({
  name: 'superusersSlice',
  initialState: superusersInitialState,
  reducers: {
    getSuperusersRequest: (state) => {
      state.getSuperusersStatus = RequestStatus.LOADING;
    },
    getSuperusersSucceess: (state, action: PayloadAction<{ superusers: Superuser[], totalItems: number }>) => {
      const { payload } = action;
      state.getSuperusersStatus = RequestStatus.SUCCEESS;
      state.superusers = payload.superusers;
      state.totalItems = payload.totalItems;
    },
    getSuperusersFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.getSuperusersStatus = RequestStatus.FAILURE;
    },
    updateSuperusersPage: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.page = payload;
    },
    updateSuperusersItemsPerPage: (state, action: PayloadAction<ItemsPerPage>) => {
      const { payload } = action;
      state.itemsPerPage = payload;
    },
    resetGetSuperusers: (state) => {
      state.getSuperusersStatus = RequestStatus.IDLE;
      state.superusers = [];
      state.error = '';
    },
  },
});

const superuserByIdInitialState: SuperuserByIdState = {
  getSuperuserByIdStatus: RequestStatus.IDLE,
  superuser: null,
  error: '',
};

export const superuserByIdSlice = createSlice({
  name: 'superuserByIdSlice',
  initialState: superuserByIdInitialState,
  reducers: {
    getSuperuserByIdRequest: (state) => {
      state.getSuperuserByIdStatus = RequestStatus.LOADING;
    },
    getSuperuserByIdSucceess: (state, action: PayloadAction<Superuser>) => {
      const { payload } = action;
      state.superuser = payload;
      state.getSuperuserByIdStatus = RequestStatus.SUCCEESS;
    },
    getSuperuserByIdFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.getSuperuserByIdStatus = RequestStatus.FAILURE;
    },
    resetGetSuperuserById: () => ({ ...superuserByIdInitialState }),
  },
});

const superuserActionsInitialState: SuperuserActionsState = {
  setEnableSuperuserStatus: RequestStatus.IDLE,
  createSuperuserStatus: RequestStatus.IDLE,
  updateSuperuserStatus: RequestStatus.IDLE,
  deleteSuperuserStatus: RequestStatus.IDLE,
  message: '',
  error: '',
};

export const superuserActionsSlice = createSlice({
  name: 'superuserActionsSlice',
  initialState: superuserActionsInitialState,
  reducers: {
    setEnableSuperuserRequest: (state) => {
      state.setEnableSuperuserStatus = RequestStatus.LOADING;
    },
    setEnableSuperuserSucceess: (state) => {
      state.setEnableSuperuserStatus = RequestStatus.SUCCEESS;
    },
    setEnableSuperuserFailure: (state) => {
      state.setEnableSuperuserStatus = RequestStatus.FAILURE;
    },
    resetSetEnableSuperuserStatus: (state) => {
      state.setEnableSuperuserStatus = RequestStatus.IDLE;
    },
    createSuperuserRequest: (state) => {
      state.createSuperuserStatus = RequestStatus.LOADING;
      state.error = '';
      state.message = '';
    },
    createSuperuserSucceess: (state, action: PayloadAction<{ message: string}>) => {
      state.createSuperuserStatus = RequestStatus.SUCCEESS;
      state.message = action.payload.message;
    },
    createSuperuserFailure: (state, action: PayloadAction<string>) => {
      state.createSuperuserStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetCreateSuperuserStatus: (state) => {
      state.createSuperuserStatus = RequestStatus.IDLE;
      state.error = '';
      state.message = '';
    },
    updateSuperuserRequest: (state) => {
      state.updateSuperuserStatus = RequestStatus.LOADING;
      state.error = '';
      state.message = '';
    },
    updateSuperuserSucceess: (state, action: PayloadAction<{ message: string}>) => {
      state.updateSuperuserStatus = RequestStatus.SUCCEESS;
      state.message = action.payload.message;
    },
    updateSuperuserFailure: (state, action: PayloadAction<string>) => {
      state.updateSuperuserStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetUpdateSuperuserStatus: (state) => {
      state.updateSuperuserStatus = RequestStatus.IDLE;
      state.error = '';
      state.message = '';
    },
    deleteSuperuserRequest: (state) => {
      state.deleteSuperuserStatus = RequestStatus.LOADING;
    },
    deleteSuperuserSucceess: (state) => {
      state.deleteSuperuserStatus = RequestStatus.SUCCEESS;
    },
    deleteSuperuserFailure: (state, action: PayloadAction<string>) => {
      state.deleteSuperuserStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetDeleteSuperuserStatus: (state) => {
      state.deleteSuperuserStatus = RequestStatus.IDLE;
      state.error = '';
    },
  },
});

export const {
  getSuperusersRequest,
  getSuperusersSucceess,
  getSuperusersFailure,
  updateSuperusersPage,
  updateSuperusersItemsPerPage,
  resetGetSuperusers,
} = superusersSlice.actions;

export const {
  getSuperuserByIdRequest,
  getSuperuserByIdSucceess,
  getSuperuserByIdFailure,
  resetGetSuperuserById,
} = superuserByIdSlice.actions;

export const {
  setEnableSuperuserRequest,
  setEnableSuperuserSucceess,
  setEnableSuperuserFailure,
  resetSetEnableSuperuserStatus,
  createSuperuserRequest,
  createSuperuserSucceess,
  createSuperuserFailure,
  resetCreateSuperuserStatus,
  updateSuperuserRequest,
  updateSuperuserSucceess,
  updateSuperuserFailure,
  resetUpdateSuperuserStatus,
  deleteSuperuserRequest,
  deleteSuperuserSucceess,
  deleteSuperuserFailure,
  resetDeleteSuperuserStatus,
} = superuserActionsSlice.actions;

export default combineReducers({
  superuserList: superusersSlice.reducer,
  superuserById: superuserByIdSlice.reducer,
  superuserActions: superuserActionsSlice.reducer
});
