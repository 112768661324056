const FormLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 14,
  },
  labelWrap: true,
}
const FormButtonLayout = {
  wrapperCol: {
    span: 14,
    offset: 8,
  },
}

const FormErrorLabelLayout = {
  wrapperCol: {
    span: 14,
    offset: 8,
  }
}

export {
  FormLayout,
  FormButtonLayout,
  FormErrorLabelLayout
}