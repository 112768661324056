import { Superuser, SuperuserForm } from 'core/superuser/Superuser';
import ApiMethods from 'core/shared/enum/ApiMethod';
import { GetSuperusersResponse } from 'core/superuser/SuperuserResponse';

import http from './request';

const getSuperusers = (page: number, itemsPerPage: number): Promise<GetSuperusersResponse> => {
  const offset = itemsPerPage * (page - 1);
  return http.request({
    url: `/users/super-users?max=${itemsPerPage}&offset=${offset}`,
    method: ApiMethods.GET,
  });
};

const getSuperuserById = (id: string): Promise<{ data: Superuser }> => {
  return http.request({
    url: `/users/super-users/${id}`,
    method: ApiMethods.GET,
  });
};

const createSuperuser = (data: SuperuserForm): Promise<{ data: { data: Superuser, message: string | null }}> => {
  return http.request({
    url: '/users/super-users',
    method: ApiMethods.POST,
    data,
  });
};

const updateSuperuser = (data: SuperuserForm, id: string): Promise<{ data: { data: Superuser, message: string | null }}> => {
  return http.request({
    url: `/users/super-users/${id}`,
    method: ApiMethods.PUT,
    data,
  });
};

const setEnable = (id: string, isActive: boolean): Promise<{data : Superuser}> => {
  return http.request({
    url: `/users/super-users/${id}/enable?enabled=${isActive}`,
    method: ApiMethods.POST,
  });
};

const deleteSuperuser = (id: string): Promise<{}> => {
  return http.request({
    url: `/users/super-users/${id}`,
    method: ApiMethods.DELETE,
  });
};

const SuperuserService = {
  getSuperusers,
  getSuperuserById,
  createSuperuser,
  updateSuperuser,
  deleteSuperuser,
  setEnable,
};

export default SuperuserService;
