import { FC, PropsWithChildren } from "react";
import { PageHeader, Layout } from "antd";

const { Content } = Layout;

type FCC<P={}> = FC<PropsWithChildren<P>>

const PageContainer: FCC<{ title: string, contentMaxWidth?: number | string }> = ({ children, title, contentMaxWidth }) => (
  <>
    <PageHeader
      title={title}
      ghost={false}
    />
    <div style={{ margin: "0 16px"}}>
      <Content
        style={{
          margin: '24px auto',
          padding: 24,
          minHeight: 280,
          maxWidth: contentMaxWidth ? contentMaxWidth : 650,
          backgroundColor: '#fff',
        }}
      >
        {children}
      </Content>
    </div>
  </>
);

export default PageContainer;
