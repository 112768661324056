import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { Typography } from 'antd';

import { getAccount, getAppData } from 'store/user/userThunk';
import { selectAccount, selectAppData, selectUserData } from 'store/user/userSelector';
import { resetGetAccount, resetGetAppData } from 'store/user/userSlice';
import UserRole from 'core/shared/enum/UserRole';
import { useAppConfig } from 'components/context/AppConfigContext';

import generatedGitInfo from 'generatedGitInfo.json';
import PageContainer from 'components/layouts/PageContainer';

const { Title } = Typography;

const AccountProfileScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { reactAppVersion } = useAppConfig();

  const account = useSelector(selectAccount);
  const userData = useSelector(selectUserData);
  const appData = useSelector(selectAppData);

  useEffect(() => {
    dispatch(getAccount());
    dispatch(getAppData());
    return () => {
      dispatch(resetGetAccount());
      dispatch(resetGetAppData());
    }
  }, [dispatch]);

  return (
    <PageContainer title="Account Profile">
      {account &&
        <>
          <Profile>
            <Profile>
              <Title level={3}><b>{userData.userRole === UserRole.ROLE_SUPER_USER ? account.username : account.email}</b></Title>
              {
                userData.userRole === UserRole.ROLE_SUPER_USER && (
                  <Line>
                    <Label>Username:</Label>
                    <Info>{account.username}</Info>
                  </Line>
                )
              }
              <Line>
                <Label>Name:</Label>
                <Info>{account.firstName} {account.lastName}</Info>
              </Line>
              <Line>
                <Label>{userData.userRole === UserRole.ROLE_SUPER_USER ? 'Email:' : 'Email (username):'}</Label>
                <Info>{account.email}</Info>
              </Line>
              {
                account.organization && (
                  <Line>
                    <Label>Organization:</Label>
                    <Info>{account.organization.name}</Info>
                  </Line>
                )
              }
              {
                userData.userRole === UserRole.ROLE_ACCOUNT_MANAGER && (
                  <Line>
                    <Label>Managed organizations:</Label>
                    <Info>{account.organizationsManagedBy.map(item => item.name).join(', ') || '-'}</Info>
                  </Line>
                )
              }
              <Line>
                <Label>Status:</Label>
                <Info><ActiveData isActive={!!account.active}>{!!account.active ? 'Active' : 'Not active'}</ActiveData></Info>
              </Line>
              <Line>
                <Label>Contact Address:</Label>
                <Info>{account.contactAddress || '<No info>'}</Info>
              </Line>
              <Line>
                <Label>Phone Number:</Label>
                <Info>{account.phoneNumber || '<No info>'}</Info>
              </Line>
            </Profile>
            <ButtonContainer>
              <Link style={{ margin: '10px 10px' }} to="/account/password-update">
                <Button type="primary" size="middle">Update password</Button>
              </Link>
              <Link style={{ margin: '10px 10px' }} to="/account/profile-update">
                <Button type="primary" size="middle">Update account data</Button>
              </Link>
            </ButtonContainer>
          </Profile>
          <Profile>
            <Title level={5}><b>Application info:</b></Title>
            <Line>
              <Label>Client Version:</Label>
              <Info>{(generatedGitInfo && `${reactAppVersion || '<No info>'}-${generatedGitInfo.gitCommitHash}`) || '<No info>'}</Info>
            </Line>
            <Line>
              <Label>Backend Version:</Label>
              <Info>{(appData && `${appData.appVersion}`) || '<No info>'}</Info>
            </Line>
          </Profile>
        </>
      }
    </PageContainer>
  );
};

export default AccountProfileScreen;

const Profile = styled.div`
  width: 400px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ActiveData = styled.span<{ isActive: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? '#52C41A' : '#FF4D4F')}; 
`;

const Line = styled.div`
  display: flex;
`;

const Label = styled.div`
  width: 150px;
  font-weight: bold;
  line-height: 22px;
  border-bottom: 1px solid #F0F0F0;
  margin-right: 10px;
  padding: 10px 0 10px;
  text-align: right;
`;

const Info = styled.div`
  width: 240px;
  text-align: left;
  line-height: 22px;
  border-bottom: 1px solid black;
  padding: 10px 0 10px;
  border-bottom: 1px solid #F0F0F0;
`;

const ButtonContainer = styled.div`
  max-width: 400px;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
`;
