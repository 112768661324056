import ApiMethods from 'core/shared/enum/ApiMethod';
import { GetAccountManagersResponse } from 'core/account-manager/AccountManagerResponse';

import http from './request';
import { AccountManagerById } from 'core/account-manager/AccountManager';
import { AccountManagerBase } from 'core/account-manager/AccountManager';
import { AccountManager } from 'core/account-manager/AccountManager';
import { AccountManagerForm } from 'core/account-manager/AccountManager';

const getAccountManagers = (page: number, itemsPerPage: number): Promise<GetAccountManagersResponse> => {
  const offset = itemsPerPage * (page - 1);
  return http.request({
    url: `/users/account-managers?max=${itemsPerPage}&offset=${offset}`,
    method: ApiMethods.GET,
  });
};

const getAccountManagersBase = (): Promise<{ data: AccountManagerBase[] }> => {
  return http.request({
    url: "/users/account-managers/basedetails",
    method: ApiMethods.GET,
  });
};

const getAccountManagerById = (id: string): Promise<{data: AccountManagerById}> => {
  return http.request({
    url: `/users/account-managers/${id}`,
    method: ApiMethods.GET,
  });
};

const createAccountManager = (data: AccountManagerForm): Promise<{data : {data: AccountManager, message: string | null}}> => {
  return http.request({
    url: '/users/account-managers',
    method: ApiMethods.POST,
    data: {
      ...data,
      username: data.email,
    },
  });
};

const updateAccountManager = (data: AccountManagerForm, id: string): Promise<{data : {data: AccountManager, message: string | null}}> => {
  return http.request({
    url: `/users/account-managers/${id}`,
    method: ApiMethods.PUT,
    data,
  });
};

const setEnable = (id: string, isActive: boolean): Promise<{data : AccountManager}> => {
  return http.request({
    url: `/users/account-managers/${id}/enable?enabled=${isActive}`,
    method: ApiMethods.POST,
  });
};

const deleteAccountManager = (id: string): Promise<{}> => {
  return http.request({
    url: `/users/account-managers/${id}`,
    method: ApiMethods.DELETE,
  });
};

const getAccountManagerByEmail = (email: string): Promise<GetAccountManagersResponse> => {
  let url = `/users/account-managers?email=${email}`;

  return http.request({
    url: url.replace('+', '%2B'),
    method: ApiMethods.GET,
  });
};

const AccountManagerService = {
  getAccountManagers,
  getAccountManagersBase,
  getAccountManagerById,
  createAccountManager,
  updateAccountManager,
  setEnable,
  deleteAccountManager,
  getAccountManagerByEmail,
};

export default AccountManagerService;
