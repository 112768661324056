import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Organization } from 'core/organization/Organization';
import { OrganizationBase } from 'core/organization/Organization';
import ItemsPerPage from 'core/shared/enum/ItemsPerPage';
import RequestStatus from 'core/shared/enum/RequestStatus';

import { OrganizationsState, OrganizationsBaseState, OrganizationByIdState, OrganizationActionsState } from './organizationTypes';

const organizationsInitialState: OrganizationsState = {
  organizations: [],
  organizationsStatus: RequestStatus.IDLE,
  page: 1,
  totalItems: 0,
  itemsPerPage: ItemsPerPage.TEN,
  error: '',
};

export const organizationsSlice = createSlice({
  name: 'organizationsSlice',
  initialState: organizationsInitialState,
  reducers: {
    getOrganizationsRequest: (state) => {
      state.organizationsStatus = RequestStatus.LOADING;
    },
    getOrganizationsSucceess: (state, action: PayloadAction<{ organizations: Organization[], totalItems: number }>) => {
      const { payload } = action;
      state.organizationsStatus = RequestStatus.SUCCEESS;
      state.organizations = payload.organizations;
      state.totalItems = payload.totalItems;
    },
    getOrganizationsFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.organizationsStatus = RequestStatus.FAILURE;
    },
    updateOrganizationsPage: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.page = payload;
    },
    updateOrganizationsItemsPerPage: (state, action: PayloadAction<ItemsPerPage>) => {
      const { payload } = action;
      state.itemsPerPage = payload;
    },
    resetGetOrganizations: (state) => {
      state.organizationsStatus = RequestStatus.IDLE;
      state.organizations = [];
      state.error = '';
    },
  },
});

const organizationsBaseInitialState: OrganizationsBaseState = {
  organizationsBaseStatus: RequestStatus.IDLE,
  organizations: [],
  error: '',
};

export const organizationsBaseSlice = createSlice({
  name: 'organizationsBaseSlice',
  initialState: organizationsBaseInitialState,
  reducers: {
    getOrganizationsBaseRequest: (state) => {
      state.organizationsBaseStatus = RequestStatus.LOADING;
    },
    getOrganizationsBaseSucceess: (state, action: PayloadAction<{ organizations: OrganizationBase[] }>) => {
      const { payload } = action;
      state.organizationsBaseStatus = RequestStatus.SUCCEESS;
      state.organizations = payload.organizations;
    },
    getOrganizationsBaseFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.organizationsBaseStatus = RequestStatus.FAILURE;
      state.error = payload;
    },
    resetGetOrganizationsBase: () => ({ ...organizationsBaseInitialState }),
  },
});

const organizationByIdInitialState: OrganizationByIdState = {
  getOrganizationByIdStatus: RequestStatus.IDLE,
  organization: null,
  error: '',
};

export const organizationByIdSlice = createSlice({
  name: 'organizationByIdSlice',
  initialState: organizationByIdInitialState,
  reducers: {
    getOrganizationByIdRequest: (state) => {
      state.getOrganizationByIdStatus = RequestStatus.LOADING;
    },
    getOrganizationByIdSucceess: (state, action: PayloadAction<Organization>) => {
      const { payload } = action;
      state.organization = payload;
      state.getOrganizationByIdStatus = RequestStatus.SUCCEESS;
    },
    getOrganizationByIdFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.getOrganizationByIdStatus = RequestStatus.FAILURE;
    },
    resetGetOrganizationById: () => ({ ...organizationByIdInitialState }),
  },
});


const organizationActionsInitialState: OrganizationActionsState = {
  setEnableOrganizationStatus: RequestStatus.IDLE,
  createOrganizationStatus: RequestStatus.IDLE,
  updateOrganizationStatus: RequestStatus.IDLE,
  deleteOrganizationStatus: RequestStatus.IDLE,
  error: '',
};

export const organizationActionsSlice = createSlice({
  name: 'organizationActionsSlice',
  initialState: organizationActionsInitialState,
  reducers: {
    setEnableOrganizationRequest: (state) => {
      state.setEnableOrganizationStatus = RequestStatus.LOADING;
    },
    setEnableOrganizationSucceess: (state) => {
      state.setEnableOrganizationStatus = RequestStatus.SUCCEESS;
    },
    setEnableOrganizationFailure: (state) => {
      state.setEnableOrganizationStatus = RequestStatus.FAILURE;
    },
    resetSetEnableOrganizationStatus: (state) => {
      state.setEnableOrganizationStatus = RequestStatus.IDLE;
    },
    createOrganizationRequest: (state) => {
      state.createOrganizationStatus = RequestStatus.LOADING;
    },
    createOrganizationSucceess: (state) => {
      state.createOrganizationStatus = RequestStatus.SUCCEESS;
    },
    createOrganizationFailure: (state, action: PayloadAction<string>) => {
      state.createOrganizationStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetCreateOrganizationStatus: (state) => {
      state.createOrganizationStatus = RequestStatus.IDLE;
      state.error = '';
    },
    updateOrganizationRequest: (state) => {
      state.updateOrganizationStatus = RequestStatus.LOADING;
    },
    updateOrganizationSucceess: (state) => {
      state.updateOrganizationStatus = RequestStatus.SUCCEESS;
    },
    updateOrganizationFailure: (state, action: PayloadAction<string>) => {
      state.updateOrganizationStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetUpdateOrganizationStatus: (state) => {
      state.updateOrganizationStatus = RequestStatus.IDLE;
      state.error = '';
    },
    deleteOrganizationRequest: (state) => {
      state.updateOrganizationStatus = RequestStatus.LOADING;
    },
    deleteOrganizationSucceess: (state) => {
      state.deleteOrganizationStatus = RequestStatus.SUCCEESS;
    },
    deleteOrganizationFailure: (state, action: PayloadAction<string>) => {
      state.deleteOrganizationStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetDeleteOrganizationStatus: (state) => {
      state.deleteOrganizationStatus = RequestStatus.IDLE;
      state.error = '';
    },
  },
});

export const {
  getOrganizationsRequest,
  getOrganizationsSucceess,
  getOrganizationsFailure,
  updateOrganizationsPage,
  updateOrganizationsItemsPerPage,
  resetGetOrganizations,
} = organizationsSlice.actions;

export const {
  getOrganizationsBaseRequest,
  getOrganizationsBaseSucceess,
  getOrganizationsBaseFailure,
  resetGetOrganizationsBase,
} = organizationsBaseSlice.actions;

export const {
  getOrganizationByIdRequest,
  getOrganizationByIdSucceess,
  getOrganizationByIdFailure,
  resetGetOrganizationById,
} = organizationByIdSlice.actions;

export const {
  setEnableOrganizationRequest,
  setEnableOrganizationSucceess,
  setEnableOrganizationFailure,
  resetSetEnableOrganizationStatus,
  createOrganizationRequest,
  createOrganizationSucceess,
  createOrganizationFailure,
  resetCreateOrganizationStatus,
  updateOrganizationRequest,
  updateOrganizationSucceess,
  updateOrganizationFailure,
  resetUpdateOrganizationStatus,
  deleteOrganizationRequest,
  deleteOrganizationSucceess,
  deleteOrganizationFailure,
  resetDeleteOrganizationStatus,
} = organizationActionsSlice.actions;

export default combineReducers({
  organizationsList: organizationsSlice.reducer,
  organizationsBaseList: organizationsBaseSlice.reducer,
  organizationById: organizationByIdSlice.reducer,
  organizationActions: organizationActionsSlice.reducer,
});
