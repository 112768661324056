import { Product, ProductForm } from 'core/product/Product';
import ApiMethods from 'core/shared/enum/ApiMethod';
import ActivityStatus from 'core/shared/enum/ActivityStatus';
import { GetProductsResponse } from 'core/product/ProductResponse';

import http from './request';
import { ProductBase } from 'core/product/Product';

const getProducts = (status: ActivityStatus, page: number, itemsPerPage: number): Promise<GetProductsResponse> => {
  const offset = itemsPerPage * (page - 1);
  return http.request({
    url: `/products?all=${status === ActivityStatus.ALL}&max=${itemsPerPage}&offset=${offset}`,
    method: ApiMethods.GET,
  });
};

const getProductsBase = (status: ActivityStatus): Promise<{data: ProductBase[]}> => {
  return http.request({
    url: `/products/basedetails?all=${status === ActivityStatus.ALL}`,
    method: ApiMethods.GET,
  });
};

const getProductById = (id: string): Promise<{data: Product}> => {
  return http.request({
    url: `/products/${id}`,
    method: ApiMethods.GET,
  });
};

const setEnableProduct = (id: string, isActive: boolean): Promise<{data : Product}> => {
  return http.request({
    url: `/products/${id}/enable?enabled=${isActive}`,
    method: ApiMethods.POST,
  });
};

const createProduct = (data: ProductForm): Promise<{data : Product}> => {
  return http.request({
    url: '/products',
    method: ApiMethods.POST,
    data,
  });
};

const updateProduct = (data: ProductForm, id: string): Promise<{data : Product}> => {
  return http.request({
    url: `/products/${id}`,
    method: ApiMethods.PUT,
    data,
  });
};

const deleteProduct = (id: string): Promise<{}> => {
  return http.request({
    url: `/products/${id}`,
    method: ApiMethods.DELETE,
  });
};

const SuperuserService = {
  getProducts,
  getProductsBase,
  getProductById,
  setEnableProduct,
  createProduct,
  updateProduct,
  deleteProduct,
};

export default SuperuserService;
