import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Form, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import routes from 'config/Route';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { checkIsClientExist, createAccountManager } from 'store/account-manager/accountManagerThunk';
import { resetCreateAccountManagerStatus, resetIsClientExists } from 'store/account-manager/accountManagerSlice';
import { selectCreateAccountManagerStatus, selectAccountManagerActionsError, selectClientData, selectAccountManagerActionsMessage } from 'store/account-manager/accountManagerSelector';
import { AccountManagerForm } from 'core/account-manager/AccountManager';
import { FormButtonLayout, FormLayout } from 'config/constants';
import PageContainer from 'components/layouts/PageContainer';

const AccountManagerCreateScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [ form ] = Form.useForm();
  
  const createAccountManagerError = useSelector(selectAccountManagerActionsError);
  const accountManagerActionsMessage = useSelector(selectAccountManagerActionsMessage);
  const createAccountManagerStatus = useSelector(selectCreateAccountManagerStatus);
  const clientData = useSelector(selectClientData);

  const isLoading = createAccountManagerStatus === RequestStatus.LOADING;
  const isSuccess = createAccountManagerStatus === RequestStatus.SUCCEESS;

  const onFinish = async (values: AccountManagerForm) => {
    dispatch(createAccountManager(values))
  };

  useEffect(() => {
    return () => {
      dispatch(resetCreateAccountManagerStatus())
      dispatch(resetIsClientExists())
    };
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        key: `account`,
        message: `Success`,
        description: 'Account manager successfully created',
        placement: 'bottomRight',
      });
      if (accountManagerActionsMessage) {
        notification.warning({
          key: 'accountWarning',
          message: `Email notification was failed`,
          description: accountManagerActionsMessage,
          placement: 'bottomRight',
        });
      }
      history.push(routes.accountManagers.path);
    }
  }, [history, isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clientData) {
      form.setFieldsValue({
        firstName: clientData.firstName,
        password: '!Placeholder1',
        lastName: clientData.lastName,
        contactAddress: clientData.contactAddress,
        phoneNumber: clientData.phoneNumber,
      })
      notification.success({
        key: 'accountManager',
        message: `User already exists.`,
        description: 'This user already exists with the Client\'s role. Do you want to add the Account Manager role?',
        placement: 'bottomRight',
      });
    } else {
      form.setFieldsValue({
        firstName: '',
        password: '',
        lastName: '',
        contactAddress:  '',
        phoneNumber: '',
      })
    }
  }, [form, clientData]);

  return (
    <PageContainer title="Account Manager Creation">
      <Container>
        <Form
          {...FormLayout}
          layout="horizontal"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item rules={[{ required: true }]} name="email" label="Email (username)">
            <Input
              type="email"
              onBlur={(e) => {
                dispatch(checkIsClientExist(e.target.value))
              }}
              onChange={(e) => {
                if (clientData) {
                  dispatch(resetIsClientExists())
                }
              }}
              placeholder="Please enter email (username)"
            />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true },
              {
                // eslint-disable-next-line
                pattern: new RegExp("^[A-Za-z0-9\"\'!#$%&()*+,-./:;<=>?@^_`{|}~]*$"),
                message: "Password should contain only latin letters, numbers and special characters!"
              },
              {
                pattern: new RegExp("(?=.*[A-Z])"),
                message: "At least one uppercase letter required!"
              },
              {
                pattern: new RegExp("(?=.*[a-z])"),
                message: "At least one lowercase letter required!"
              },
              {
                // eslint-disable-next-line
                pattern: new RegExp("(?=.*[\"\'!#$%&()*+,-./:;<=>?@^_`{|}~])"),
                message: "At least one special character required!"
              },
              {
                pattern: new RegExp(".*[0-9].*"),
                message: "At least one digit required!"
              },
              {
                min: 8,
                message: "At least 8 symbols required!"
              },
            ]}
            name="password"
            label="Password"
          >
            <Input.Password
              autoComplete="new-password"
              placeholder="Please enter password"
              visibilityToggle={true}
              disabled={!!clientData}
            />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="firstName" label="First name">
            <Input disabled={!!clientData} placeholder="Please enter first name" />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="lastName" label="Last name">
            <Input disabled={!!clientData} placeholder="Please enter last name" />
          </Form.Item>
          <Form.Item rules={[{ required: false }]} name="contactAddress" label="Contact address (optional)">
            <Input disabled={!!clientData} placeholder="Please enter your contact address" />
          </Form.Item>
          <Form.Item rules={[{ required: false }]} name="phoneNumber" label="Phone number (optional)">
            <Input disabled={!!clientData} placeholder="Please enter your phone number" />
          </Form.Item>
          <Form.Item {...FormButtonLayout}>
            <InfoLabel>{clientData && "This user already exists with the Client's role. Do you want to add the Account Manager role?"}</InfoLabel>
            <ErrorLabel>{createAccountManagerError}</ErrorLabel>
          </Form.Item>
          <Form.Item {...FormButtonLayout}>
            <Button loading={isLoading} style={{ marginRight: '10px' }} htmlType="submit" type="primary">Create</Button>
            <Link to={routes.accountManagers.path}>
              <Button type="default">Cancel</Button>
            </Link>
          </Form.Item>
        </Form>
      </Container>
    </PageContainer>
  );
};

export default AccountManagerCreateScreen;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.div`
  color: #ff4d4f;
`;

const InfoLabel = styled.div`
  color: #44a115;
`;