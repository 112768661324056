import { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Form, Checkbox, Select, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import routes from 'config/Route';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { createProduct, getProductsBase } from 'store/product/productThunk';
import { resetCreateProductStatus, resetGetProductsBase } from 'store/product/productSlice';
import { selectCreateProductStatus, selectProductActionsError, selectGetProductsBaseStatus, selectProductsBase } from 'store/product/productSelector';
import { ProductForm } from 'core/product/Product';
import { FormButtonLayout, FormErrorLabelLayout, FormLayout } from 'config/constants';
import PageContainer from 'components/layouts/PageContainer';
import ActivityStatus from 'core/shared/enum/ActivityStatus';

const ProductCreateScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [ form ] = Form.useForm();
  const [productsRelatedToDisabled, setProductsRelatedToDisabled] = useState(false);

  const productCreateError = useSelector(selectProductActionsError);
  const productCreateStatus = useSelector(selectCreateProductStatus);
  const getAllProductsStatus = useSelector(selectGetProductsBaseStatus);
  const allProducts = useSelector(selectProductsBase);
  const isAllProductsLoading = getAllProductsStatus === RequestStatus.LOADING;
  const isCreateLoading = productCreateStatus === RequestStatus.LOADING;
  const isCreateSuccess = productCreateStatus === RequestStatus.SUCCEESS;

  const onFinish = async (values: ProductForm) => {
    console.log(values);
    dispatch(createProduct(values));
  };

  useEffect(() => {
    dispatch(getProductsBase(ActivityStatus.ACTIVE));
    return () => {
      dispatch(resetCreateProductStatus())
      dispatch(resetGetProductsBase());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isCreateSuccess) {
      notification.success({
        key: 'product',
        message: `Success`,
        description: 'Product successfully created',
        placement: 'bottomRight',
      });
      history.push(routes.products.path);
    }
  }, [history, isCreateSuccess]);

  return (
    <PageContainer
      title="Product Creation"
      contentMaxWidth="800px"
    >
      <Container>
        <Form
          {...FormLayout}
          labelCol={{ span: 9 }}
          layout="horizontal"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item rules={[{ required: true }]} name="name" label="Name">
            <Input placeholder="Please enter name of product" />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="technicalCode" label="Technical Code">
            <Input placeholder="Please enter technical code" />
          </Form.Item>
          <Form.Item name="base" label="Base" valuePropName="checked">
            <Checkbox
              onChange={(event) => {
                if (event.target.checked) {
                  form.setFieldsValue({
                    productsRelatedTo: [],
                  });
                  setProductsRelatedToDisabled(true);
                } else {
                  setProductsRelatedToDisabled(false);
                }
              }}
            />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="usageType" label="Usage type">
            <Select>
              <Select.Option value="TIME">Time</Select.Option>
              <Select.Option value="TIME_AND_NUMBER">Time and number</Select.Option>
              <Select.Option value="NUMBER_LIMIT">Number</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item  name="productsRelatedTo" label="Related exclusively to products">
            <Select
              disabled={productsRelatedToDisabled}
              loading={isAllProductsLoading}
              mode="multiple"
              placeholder="Please select products related to"
              allowClear
              options={allProducts.map(item => ({
                value: item.id,
                label: item.name,
              }))}
              filterOption={(input, option) =>
                (option?.label?.toString().toLowerCase().indexOf(input.toLowerCase()) || 0) >= 0
              }
            />
          </Form.Item>
          <Form.Item {...FormErrorLabelLayout}>
            <ErrorLabel>{productCreateError}</ErrorLabel>
          </Form.Item>
          <Form.Item {...FormButtonLayout}>
            <Button loading={isCreateLoading} style={{ marginRight: '10px' }} htmlType="submit" type="primary">Create</Button>
            <Button type="default" onClick={() => history.goBack()}>Cancel</Button>
          </Form.Item>
        </Form>
      </Container>
    </PageContainer>
  );
};

export default ProductCreateScreen;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.div`
  color: #ff4d4f;
`;
