import { AppThunk, AppDispatch } from 'store/store';
import { 
  getLicensesRequest,
  getLicensesSucceess,
  getLicensesFailure,
  getLicenseByIdRequest,
  getLicenseByIdSucceess,
  getLicenseByIdFailure,
  getLicenseStatisticRequest,
  getLicenseStatisticSucceess,
  getLicenseStatisticFailure,
  createLicenseRequest,
  createLicenseSucceess,
  createLicenseFailure,
  setEnableLicenseRequest,
  setEnableLicenseSucceess,
  setEnableLicenseFailure,
  deleteLicenseRequest,
  deleteLicenseSucceess,
  deleteLicenseFailure,
} from './licenseSlice';
import LicenseService from 'services/api/LicenseService';
import ActivityStatus from 'core/shared/enum/ActivityStatus';
import { LicenseForm } from 'core/license/License';

const getLicensesByOrganization = (organizationId: string, page: number, itemsPerPage: number): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getLicensesRequest());
    const response = await LicenseService.getLicensesFiltered('ALL', ActivityStatus.ALL, organizationId, "ALL", page, itemsPerPage);
    dispatch(getLicensesSucceess({
      licenses: response.data.data,
      totalItems: response.data.totalItems
    }));
  } catch (err) {
    dispatch(getLicensesFailure(err.toString()));
  }
};

const getLicenses = (
  accountManagerId: string,
  status: ActivityStatus,
  organizationId: string,
  productId: string,
  page: number,
  itemsPerPage: number
  ): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getLicensesRequest());
    const response = await LicenseService.getLicensesFiltered(accountManagerId, status, organizationId, productId, page, itemsPerPage);
    dispatch(getLicensesSucceess({
      licenses: response.data.data,
      totalItems: response.data.totalItems
    }));
  } catch (err) {
    dispatch(getLicensesFailure(err.toString()));
  }
};

const getLicenseById = (id: string): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getLicenseByIdRequest());
    const response = await LicenseService.getLicenseById(id);
    dispatch(getLicenseByIdSucceess(response.data));
  } catch (err) {
    dispatch(getLicenseByIdFailure(err.toString()));
  }
};

const getLicenseStatistic = (id: string, page: number, itemsPerPage: number): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getLicenseStatisticRequest());
    const response = await LicenseService.getLicenseStatistic(id, page, itemsPerPage);
    dispatch(getLicenseStatisticSucceess({
      statistic: response.data.data,
      totalItems: response.data.totalItems
    }));
  } catch (err) {
    dispatch(getLicenseStatisticFailure(err.toString()));
  }
};

const setEnableLicense = (id: string, isActive: boolean) => async (dispatch: AppDispatch) => {
  try {
    dispatch((setEnableLicenseRequest()));
    await LicenseService.setEnableLicense(id, isActive);
    dispatch(setEnableLicenseSucceess());
  } catch (err) {
    dispatch(setEnableLicenseFailure());
  }
};

const createLicense = (data: LicenseForm) => async (dispatch: AppDispatch) => {
  try {
    dispatch((createLicenseRequest()));
    const response = await LicenseService.createLicense(data);
    dispatch(createLicenseSucceess({ message: response.data.message || '' }));
  } catch (err) {
    dispatch(createLicenseFailure(err.message));
  }
};

const deleteLicense = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(deleteLicenseRequest());
    await LicenseService.deleteLicense(id);
    dispatch(deleteLicenseSucceess());
  } catch (err) {
    dispatch(deleteLicenseFailure(err.message));
  }
};

export {
  getLicensesByOrganization,
  getLicenses,
  getLicenseById,
  getLicenseStatistic,
  setEnableLicense,
  createLicense,
  deleteLicense,
};
