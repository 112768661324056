import { useEffect, useMemo } from 'react';
import { Layout, Menu } from 'antd';
import {
  ToolOutlined,
  PicLeftOutlined,
  BlockOutlined,
  ApartmentOutlined,
  TeamOutlined,
  FileAddOutlined,
  UserOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import styled from '@emotion/styled/macro';
import { Switch, NavLink, useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import RouteType from 'core/shared/enum/RouteType';
import UserRole from 'core/shared/enum/UserRole';
import USER_ROLE_ENUM from 'core/shared/label/UserRoleLabel';

import { selectIsAuth, selectUserData } from 'store/user/userSelector';
import { signOut } from 'store/user/userThunk';

import SuperusersScreen from 'pages/superusers/SuperusersScreen';
import SuperuserProfileScreen from 'pages/superusers/superuser-profile/SuperuserProfileScreen';
import SuperuserCreateScreen from 'pages/superusers/superuser-create/SuperuserCreateScreen';
import SuperuserUpdateScreen from 'pages/superusers/superuser-update/SuperuserUpdateScreen';

import ProductsScreen from 'pages/products/ProductsScreen';
import ProductProfileScreen from 'pages/products/product-profile/ProductProfileScreen';
import ProductCreateScreen from 'pages/products/product-create/ProductCreateScreen';
import ProductUpdateScreen from 'pages/products/product-update/ProductUpdateScreen';

import AccountManagersScreen from 'pages/account-managers/AccountManagersScreen';
import AccountManagerProfileScreen from 'pages/account-managers/account-manager-profile/AccountManagerProfileScreen';
import AccountManagerCreateScreen from 'pages/account-managers/account-manager-create/AccountManagerCreateScreen';
import AccountManagerUpdateScreen from 'pages/account-managers/account-manager-update/AccountManagerUpdateScreen';

import OrganizationsScreen from 'pages/organizations/OrganizationsScreen';
import OrganizationProfileScreen from 'pages/organizations/organization-profile/OrganizationProfileScreen';
import OrganizationsCreateScreen from 'pages/organizations/organizations-create/OrganizationsCreateScreen';
import OrganizationsUpdateScreen from 'pages/organizations/organizations-update/OrganizationsUpdateScreen';

import ClientsScreen from 'pages/clients/ClientsScreen';
import ClientProfileScreen from 'pages/clients/client-profile/ClientProfileScreen';
import ClientCreateScreen from 'pages/clients/client-create/ClientCreateScreen';
import ClientUpdateScreen from 'pages/clients/client-update/ClientUpdateScreen';

import LicensesScreen from 'pages/licenses/LicensesScreen';
import LicenseProfileScreen from 'pages/licenses/license-profile/LicenseProfileScreen';
import LicenseCreateScreen from 'pages/licenses/license-create/LicenseCreateScreen';

import AccountProfileScreen from 'pages/account-profile/AccountProfileScreen';
import UpdateAccountPasswordScreen from 'pages/account-profile/UpdateAccountPasswordScreen';
import UpdateAccountDataScreen from 'pages/account-profile/UpdateAccountDataScreen';

import AuthRoute from 'components/routing/AuthRoute';
import { useAppConfig } from 'components/context/AppConfigContext';
import { resetFilters } from 'store/filter/filterThunk';
import InstructionsForUse from 'pages/instructions-for-use/InstructionsForUse';

import { ReactComponent as IfuLogo } from 'assets/ifu.svg';

const { Header, Sider, Content } = Layout;

const Dashboard: React.FC = () => {
  const { routes } = useAppConfig();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const isAuth = useSelector(selectIsAuth);
  const userData = useSelector(selectUserData);
  const onLogout = () => dispatch(signOut());

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    }
  }, [dispatch]);

  const menuItems = useMemo(() => ([
    ...(userData.userRole === UserRole.ROLE_SUPER_USER ? [{
      key: routes.superusers.path,
      label: (
        <NavLink to={routes.superusers.path}>
          <LinkSpan>Superusers</LinkSpan>
        </NavLink>
      ),
      icon: (<ToolOutlined />),
    }] : []),
    {
      key: routes.products.path,
      label: (
        <NavLink to={routes.products.path}>
          <LinkSpan>Products</LinkSpan>
        </NavLink>
      ),
      icon: (<PicLeftOutlined />),
    },
    {
      key: routes.accountManagers.path,
      label: (
        <NavLink to={routes.accountManagers.path}>
          <LinkSpan>Account Managers</LinkSpan>
        </NavLink>
      ),
      icon: (<BlockOutlined />),
    },
    {
      key: routes.organizations.path,
      label: (
        <NavLink to={routes.organizations.path}>
          <LinkSpan>Organizations</LinkSpan>
        </NavLink>
      ),
      icon: (<ApartmentOutlined />),
    },
    {
      key: routes.clients.path,
      label: (
        <NavLink to={routes.clients.path}>
          <LinkSpan>Clients</LinkSpan>
        </NavLink>
      ),
      icon: (<TeamOutlined />),
    },
    ...(userData.userRole === UserRole.ROLE_ACCOUNT_MANAGER ? [{
      key: routes.licenses.path,
      label: (
        <NavLink to={routes.licenses.path}>
          <LinkSpan>Licenses</LinkSpan>
        </NavLink>
      ),
      icon: (<FileAddOutlined />),
    }] : []),
    {
      key: routes.account.path,
      label: (
        <NavLink to={routes.account.path}>
          <LinkSpan>Account Profile</LinkSpan>
        </NavLink>
      ),
      icon: (<UserOutlined />),
    },
    {
      key: routes.instructionsForUse.path,
      label: (
        <NavLink to={routes.instructionsForUse.path}>
          <LinkSpan>IFU</LinkSpan>
        </NavLink>
      ),
      icon: (<IfuLogo />),
    },
  ]), [userData, routes]);

  return (
    <Layout style={{ minHeight: '100%', height: 'fit-content' }}>
      <StyledHeader>
        <Link to={routes.index.path}>
          <Logo>SamanTree Medical Admin &#8203;- License Management System</Logo>
        </Link>
        <SideElement>
          <span>Username: {userData.username} (role: {userData.userRole && USER_ROLE_ENUM[userData.userRole]})</span>
          <Logout style={{ padding: "10px 10px 10px 10px" }} onClick={onLogout}>
              <LogoutOutlined />
          </Logout>
        </SideElement>
      </StyledHeader>
      <Layout>
        <Sider
          theme="light"
          width="210px"
          collapsible
          breakpoint="lg"
        >
          <Menu
            theme="light"
            mode="inline"
            onClick={() => dispatch(resetFilters())}
            defaultSelectedKeys={[routes.superusers.path]}
            selectedKeys={[pathname]}
            items={menuItems}
          />
        </Sider>
        <Content>
          <Switch>
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.superusers.path}
              exact={routes.superusers.exact}
              userRole={userData.userRole as UserRole}
              requiredRoles={[ UserRole.ROLE_SUPER_USER ]}
              component={SuperusersScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.superusersCreate.path}
              exact={routes.superusersCreate.exact}
              userRole={userData.userRole as UserRole}
              requiredRoles={[ UserRole.ROLE_SUPER_USER ]}
              component={SuperuserCreateScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.superusersUpdate.path}
              exact={routes.superusersUpdate.exact}
              userRole={userData.userRole as UserRole}
              requiredRoles={[ UserRole.ROLE_SUPER_USER ]}
              component={SuperuserUpdateScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.superusersProfile.path}
              exact={routes.superusersProfile.exact}
              userRole={userData.userRole as UserRole}
              requiredRoles={[ UserRole.ROLE_SUPER_USER ]}
              component={SuperuserProfileScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.products.path}
              exact={routes.products.exact}
              component={ProductsScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.productCreate.path}
              exact={routes.productCreate.exact}
              userRole={userData.userRole as UserRole}
              requiredRoles={[ UserRole.ROLE_SUPER_USER ]}
              component={ProductCreateScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.productUpdate.path}
              exact={routes.productUpdate.exact}
              userRole={userData.userRole as UserRole}
              requiredRoles={[ UserRole.ROLE_SUPER_USER ]}
              component={ProductUpdateScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.productProfile.path}
              exact={routes.productProfile.exact}
              userRole={userData.userRole as UserRole}
              component={ProductProfileScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.accountManagers.path}
              exact={routes.accountManagers.exact}
              component={AccountManagersScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.accountManagersCreate.path}
              exact={routes.accountManagersCreate.exact}
              userRole={userData.userRole as UserRole}
              requiredRoles={[ UserRole.ROLE_SUPER_USER ]}
              component={AccountManagerCreateScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.accountManagersUpdate.path}
              exact={routes.accountManagersUpdate.exact}
              userRole={userData.userRole as UserRole}
              requiredRoles={[ UserRole.ROLE_SUPER_USER ]}
              component={AccountManagerUpdateScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.accountManagersProfile.path}
              exact={routes.accountManagersProfile.exact}
              component={AccountManagerProfileScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.organizations.path}
              exact={routes.organizations.exact}
              component={OrganizationsScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.organizationsCreate.path}
              exact={routes.organizationsCreate.exact}
              userRole={userData.userRole as UserRole}
              requiredRoles={[ UserRole.ROLE_ACCOUNT_MANAGER ]}
              component={OrganizationsCreateScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.organizationsUpdate.path}
              exact={routes.organizationsUpdate.exact}
              userRole={userData.userRole as UserRole}
              requiredRoles={[ UserRole.ROLE_ACCOUNT_MANAGER ]}
              component={OrganizationsUpdateScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.organizationsProfile.path}
              exact={routes.organizationsProfile.exact}
              component={OrganizationProfileScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.clients.path}
              exact={routes.clients.exact}
              component={ClientsScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.clientsCreate.path}
              exact={routes.clientsCreate.exact}
              userRole={userData.userRole as UserRole}
              requiredRoles={[ UserRole.ROLE_ACCOUNT_MANAGER ]}
              component={ClientCreateScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.clientsUpdate.path}
              exact={routes.clientsUpdate.exact}
              userRole={userData.userRole as UserRole}
              requiredRoles={[ UserRole.ROLE_ACCOUNT_MANAGER ]}
              component={ClientUpdateScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.clientsProfile.path}
              exact={routes.clientsProfile.exact}
              component={ClientProfileScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.licenses.path}
              exact={routes.licenses.exact}
              userRole={userData.userRole as UserRole}
              requiredRoles={[ UserRole.ROLE_ACCOUNT_MANAGER ]}
              component={LicensesScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.licenseCreate.path}
              exact={routes.licenseCreate.exact}
              userRole={userData.userRole as UserRole}
              requiredRoles={[ UserRole.ROLE_ACCOUNT_MANAGER ]}
              component={LicenseCreateScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.licenseProfile.path}
              exact={routes.licenseProfile.exact}
              userRole={userData.userRole as UserRole}
              requiredRoles={[ UserRole.ROLE_ACCOUNT_MANAGER ]}
              component={LicenseProfileScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.account.path}
              exact={routes.account.exact}
              component={AccountProfileScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.accountPasswordUpdate.path}
              exact={routes.accountPasswordUpdate.exact}
              component={UpdateAccountPasswordScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.accountProfileUpdate.path}
              exact={routes.accountProfileUpdate.exact}
              component={UpdateAccountDataScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.instructionsForUse.path}
              exact={routes.instructionsForUse.exact}
              component={InstructionsForUse}
            />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;

const StyledHeader = styled(Header)`
  font-weight: bold;
  font-size: 18px;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1100px) {
    font-size: 16px;
  }
  
  @media screen and (max-width: 900px) {
    font-size: 14px;
  }
`;

const Logo = styled.div`
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
  padding: 15px 0;
  margin-right: 20px;
`;

const Logout = styled.div`
  margin-left: 20px;
  font-weight: bold;
  font-size: 18px;
  color: #FFFFFF;
  cursor: pointer;
`;

const LinkSpan = styled.span` 
  font-size: 14px;
  line-height: 22px;
`;

const SideElement = styled.div`
  display: flex;
  align-items: center;
`;