import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Form, notification, Select } from 'antd';
import { Link, useHistory, useParams } from "react-router-dom";
import routes from 'config/Route';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { resetGetClientById, resetUpdateClientStatus } from 'store/client/clientSlice';
import { resetGetOrganizationsBase } from 'store/organization/organizationSlice';
import { getClientById, updateClient } from 'store/client/clientThunk';
import { getOrganizationsBase } from 'store/organization/organizationThunk';
import { selectUpdateClientStatus, selectClientActionsError, selectClientById, selectClientActionsMessage } from 'store/client/clientSelector';
import { selectOrganizationsBase, selectOrganizationsBaseStatus } from 'store/organization/organizationSelector';
import { ClientForm } from 'core/client/Client';
import { FormButtonLayout, FormErrorLabelLayout, FormLayout } from 'config/constants';
import PageContainer from 'components/layouts/PageContainer';

const ClientUpdateScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const client = useSelector(selectClientById);
  const organizationsStatus = useSelector(selectOrganizationsBaseStatus);
  const organizations = useSelector(selectOrganizationsBase);

  const clientUpdateError = useSelector(selectClientActionsError);
  const clientActionsMessage = useSelector(selectClientActionsMessage);
  const clientUpdateStatus = useSelector(selectUpdateClientStatus);
  const isUpdateLoading = clientUpdateStatus === RequestStatus.LOADING;
  const isUpdateSuccess = clientUpdateStatus === RequestStatus.SUCCEESS;

  const onFinish = async (values: ClientForm) => {
    dispatch(updateClient(values, id))
  };

  useEffect(() => {
    dispatch(getClientById(id));
    dispatch(getOrganizationsBase());
    return () => {
      dispatch(resetGetOrganizationsBase());
      dispatch(resetGetClientById());
      dispatch(resetUpdateClientStatus());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (isUpdateSuccess) {
      notification.success({
        key: 'client',
        message: `Success`,
        description: 'Client successfully updated',
        placement: 'bottomRight',
      });
      if (clientActionsMessage) {
        notification.warning({
          key: 'clientEmail',
          message: `Email notification was failed`,
          description: clientActionsMessage,
          placement: 'bottomRight',
        });
      }
      history.push(routes.clients.path);
    }
  }, [history, isUpdateSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageContainer title="Client Update">
      {
        client && organizations && (
          <Container>
            <Form
              {...FormLayout}
              layout="horizontal"
              onFinish={onFinish}
              initialValues={{
                ...client,
                organizationId: client.organization.id,
              }}
            >
              <Form.Item
                rules={[{ required: true }]}
                name="email"
                label="Email (username)"
              >
                <Input type="email" placeholder="Please enter email (username)" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    // eslint-disable-next-line
                    pattern: new RegExp("^[A-Za-z0-9\"\'!#$%&()*+,-./:;<=>?@^_`{|}~]*$"),
                    message: "Password should contain only latin letters, numbers and special characters!"
                  },
                  {
                    pattern: new RegExp("(?=.*[A-Z])"),
                    message: "At least one uppercase letter required!"
                  },
                  {
                    pattern: new RegExp("(?=.*[a-z])"),
                    message: "At least one lowercase letter required!"
                  },
                  {
                    // eslint-disable-next-line
                    pattern: new RegExp("(?=.*[\"\'!#$%&()*+,-./:;<=>?@^_`{|}~])"),
                    message: "At least one special character required!"
                  },
                  {
                    pattern: new RegExp(".*[0-9].*"),
                    message: "At least one digit required!"
                  },
                  {
                    min: 8,
                    message: "At least 8 symbols required!"
                  },
                ]}
                name="password"
                label="Password"
              >
                <Input.Password
                  autoComplete="new-password"
                  placeholder="Please enter password"
                  visibilityToggle={true}
                />
              </Form.Item>
              <Form.Item
                rules={[{ required: true }]}
                name="title"
                label="Client title"
                tooltip="Example: Mr., Ms., Dr., Prof."
              >
                <Input placeholder="Please enter client title" />
              </Form.Item>
              <Form.Item rules={[{ required: true }]} name="firstName" label="First name">
                <Input placeholder="Please enter first name" />
              </Form.Item>
              <Form.Item rules={[{ required: true }]} name="lastName" label="Last name">
                <Input placeholder="Please enter last name" />
              </Form.Item>
              <Form.Item rules={[{ required: true }]} name="organizationId" label="Organization">
                <Select loading={organizationsStatus === RequestStatus.LOADING}>
                  {organizations.map(item => (
                    <Select.Option key={item.id} value={item.id} style={{ color: item.active ? 'inherit' : '#FF4D4F' }}>
                      {item.name}{!item.active && ' [not active]'}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item rules={[{ required: false }]} name="contactAddress" label="Contact address (optional)">
                <Input placeholder="Please enter your contact address" />
              </Form.Item>
              <Form.Item rules={[{ required: false }]} name="phoneNumber" label="Phone number (optional)">
                <Input placeholder="Please enter your phone number" />
              </Form.Item>
              <Form.Item {...FormErrorLabelLayout}>
                <ErrorLabel>{clientUpdateError}</ErrorLabel>
              </Form.Item>
              <Form.Item {...FormButtonLayout}>
                <Button loading={isUpdateLoading} style={{ marginRight: '10px' }} htmlType="submit" type="primary">Update</Button>
                <Link to={routes.clients.path}>
                  <Button type="default">Cancel</Button>
                </Link>
              </Form.Item>
            </Form>
          </Container>
        )
      }
    </PageContainer>
  );
};

export default ClientUpdateScreen;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.div`
  color: #ff4d4f;
`;
