import { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Form, Checkbox, Select, notification } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import routes from 'config/Route';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { getProductById, getProductsBase, updateProduct } from 'store/product/productThunk';
import { resetGetProductById, resetGetProductsBase, resetUpdateProductStatus } from 'store/product/productSlice';
import {  selectGetProductsBaseStatus, selectProductActionsError, selectProductById, selectProductsBase, selectUpdateProductStatus } from 'store/product/productSelector';
import { ProductForm } from 'core/product/Product';
import { FormButtonLayout, FormErrorLabelLayout, FormLayout } from 'config/constants';
import PageContainer from 'components/layouts/PageContainer';
import ActivityStatus from 'core/shared/enum/ActivityStatus';

const ProductUpdateScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [ form ] = Form.useForm();
  const [productsRelatedToDisabled, setProductsRelatedToDisabled] = useState(false);

  const { id } = useParams<{ id: string }>();

  const product = useSelector(selectProductById);
  const productUpdateError = useSelector(selectProductActionsError);
  const productUpdateStatus = useSelector(selectUpdateProductStatus);
  const getAllProductsStatus = useSelector(selectGetProductsBaseStatus);
  const allProducts = useSelector(selectProductsBase);
  const isAllProductsLoading = getAllProductsStatus === RequestStatus.LOADING;
  const isUpdateLoading = productUpdateStatus === RequestStatus.LOADING;
  const isUpdateSuccess = productUpdateStatus === RequestStatus.SUCCEESS;

  const onFinish = async (values: ProductForm) => {
    dispatch(updateProduct(values, id))
  };

  useEffect(() => {
    dispatch(getProductById(id));
    dispatch(getProductsBase(ActivityStatus.ACTIVE));
    return () => {
      dispatch(resetGetProductById())
      dispatch(resetUpdateProductStatus())
      dispatch(resetGetProductsBase());
    };
  }, [dispatch, id]);

  useEffect(() => {
      setProductsRelatedToDisabled(!!product?.base)
  }, [product]);

  useEffect(() => {
    if (isUpdateSuccess) {
      notification.success({
        key: 'product',
        message: `Success`,
        description: 'Product successfully updated',
        placement: 'bottomRight',
      });
      history.push(routes.products.path);
    }
  }, [history, isUpdateSuccess]);

  return (
    <PageContainer
      title="Product update"
      contentMaxWidth="800px"
    >
      {
        product && (
          <Container>
            <Form
              {...FormLayout}
              layout="horizontal"
              onFinish={onFinish}
              initialValues={{
                ...product,
                productsRelatedTo: product.productsRelatedTo.map(item => item.id),
              }}
              form={form}
            >
              <Form.Item rules={[{ required: true }]} name="name" label="Name">
                <Input placeholder="Please enter name of product" />
              </Form.Item>
              <Form.Item rules={[{ required: true }]} name="technicalCode" label="Technical Code">
                <Input disabled={!!product.licenses} placeholder="Please enter technical code" />
              </Form.Item>
              <Form.Item name="base" label="Base" valuePropName="checked">
                <Checkbox
                  disabled={!!product.licenses}
                  onChange={(event) => {
                    if (event.target.checked) {
                      form.setFieldsValue({
                        productsRelatedTo: [],
                      });
                      setProductsRelatedToDisabled(true);
                    } else {
                      setProductsRelatedToDisabled(false);
                    }
                  }}
                />
              </Form.Item>
              <Form.Item rules={[{ required: true }]} name="usageType" label="Usage type">
                <Select>
                  <Select.Option value="TIME">Time</Select.Option>
                  <Select.Option value="TIME_AND_NUMBER">Time and number</Select.Option>
                  <Select.Option value="NUMBER_LIMIT">Number</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="productsRelatedTo" label="Related exclusively to products">
                <Select
                  disabled={productsRelatedToDisabled}
                  loading={isAllProductsLoading}
                  mode="multiple"
                  placeholder="Please select products related to"
                  allowClear
                  showSearch={false}
                  options={allProducts.map(item => ({
                    value: item.id,
                    label: item.name,
                  }))}
                />
              </Form.Item>
              <Form.Item {...FormErrorLabelLayout}>
                <ErrorLabel>{productUpdateError}</ErrorLabel>
              </Form.Item>
              <Form.Item {...FormButtonLayout}>
                <Button loading={isUpdateLoading} style={{ marginRight: '10px' }} htmlType="submit" type="primary">Update</Button>
                <Button type="default" onClick={() => history.goBack()}>Cancel</Button>
              </Form.Item>
            </Form>
          </Container>
        )
      }
    </PageContainer>
  );
};

export default ProductUpdateScreen;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.div`
  color: #ff4d4f;
`;
