import { Form, Checkbox, InputNumber, FormInstance } from 'antd';
import { FormButtonLayout } from 'config/constants';
import ProductType from "core/product/enum/ProductType";

// render config base on ProductType. TIME_AND_NUMBER, TIME, NUMBER_LIMIT supported
const renderConfig = (productType: ProductType | undefined, form: FormInstance): JSX.Element | null => {
  switch (productType) {
    case ProductType.TIME_AND_NUMBER:
      return (
        <>
          <Form.Item
            {...FormButtonLayout}
            valuePropName="checked"
            name="basePermanent"
          >
            <Checkbox
              onChange={(event) => {
                if (event.target.checked) {
                  form.setFieldsValue({
                    baseProductCount: undefined,
                    baseProductDays: undefined,
                  });
                }
              }}
            >
              Permanent
              </Checkbox>
          </Form.Item>
          <Form.Item 
            name="baseProductCount"
            label="Count of uses"
            rules={[{
              validator: () => {
                if (!form.getFieldsValue().basePermanent && !form.getFieldsValue().baseProductCount?.toString().length && !form.getFieldsValue().baseProductDays?.toString().length) {
                  return Promise.reject('One of configuration is required');
                } else {
                  return Promise.resolve();
                }
              }},
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              controls={false}
              type="number"
              disabled={form.getFieldsValue().basePermanent}
              placeholder="Please enter count of uses"
            />
          </Form.Item>
          <Form.Item 
            name="baseProductDays"
            label="Days"
            rules={[{
              validator: () => {
                if (!form.getFieldsValue().basePermanent && !form.getFieldsValue().baseProductCount?.toString().length && !form.getFieldsValue().baseProductDays?.toString().length) {
                  return Promise.reject('One of configuration is required');
                } else {
                  return Promise.resolve();
                }
              }},
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              controls={false}
              type="number"
              disabled={form.getFieldsValue().basePermanent}
              placeholder="Please enter count of days"
            />
          </Form.Item>
        </>
      );
    case ProductType.TIME:
      return (
        <>
          <Form.Item
            {...FormButtonLayout}
            valuePropName="checked"
            name="basePermanent"
          >
            <Checkbox
              onChange={(event) => {
                if (event.target.checked) {
                  form.setFieldsValue({ baseProductDays: undefined });
                }
              }}
            >
              Permanent
              </Checkbox>
          </Form.Item>
          <Form.Item 
            name="baseProductDays"
            label="Days" 
            rules={[{ required: !form.getFieldsValue().basePermanent, message: 'Missing days' }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              controls={false}
              type="number"
              disabled={form.getFieldsValue().basePermanent}
              placeholder="Please enter count of days"
            />
          </Form.Item>
        </>
      );
    case ProductType.NUMBER_LIMIT:
      return (
        <>
          <Form.Item
            {...FormButtonLayout}
            valuePropName="checked"
            name="basePermanent"
          >
            <Checkbox
              onChange={(event) => {
                if (event.target.checked) {
                  form.setFieldsValue({ baseProductCount: undefined });
                }
              }}
            >
              Permanent
            </Checkbox>
          </Form.Item>
          <Form.Item 
            name="baseProductCount"
            label="Count of uses"
            rules={[{ required: !form.getFieldsValue().basePermanent, message: 'Missing days' }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              controls={false}
              type="number"
              disabled={form.getFieldsValue().basePermanent}
              placeholder="Please enter count of uses"
            />
          </Form.Item>
        </>
      );
    default:
      return null;
  }
};

// render dynamic additional products.
// using [name, 'permanent'] for name; and [fieldKey, 'permanent'] for fieldKey
const renderDynamicConfig = (productType: ProductType | undefined, restField: any, name: number, fieldKey: number, form: FormInstance) => {
  switch (productType) {
    case ProductType.TIME_AND_NUMBER:
      return (
        <>
          <Form.Item
            {...restField}
            {...FormButtonLayout}
            valuePropName="checked"
            name={[name, 'permanent']}
            fieldKey={[fieldKey, 'permanent']}
          >
            <Checkbox
              onChange={(event) => {
                if (event.target.checked) {
                  form.setFields([
                    {
                      name: ["configurations", name, 'count'],
                      value: undefined,
                    },
                    {
                      name: ["configurations", name, 'days'],
                      value: undefined,
                    },
                  ]);
                }
              }}
            >
              Permanent
            </Checkbox>
          </Form.Item>
          <Form.Item
            {...restField}
            name={[name, 'count']}
            label="Count of uses"
            fieldKey={[fieldKey, 'count']}
            rules={[
              { validator: (rule, value) => {
                if (!form.getFieldsValue().configurations[name].permanent && !value?.toString().length && !form.getFieldsValue().configurations[name]?.days?.toString().length) {
                  return Promise.reject('One of configuration is required');
                } else {
                  return Promise.resolve();
                }
              }}
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              controls={false}
              type="number"
              disabled={form.getFieldsValue().configurations[name].permanent}
              placeholder="Please enter count of uses"
            />
          </Form.Item>
          <Form.Item 
            {...restField}
            name={[name, 'days']}
            label="Days" 
            fieldKey={[fieldKey, 'days']}
            rules={[
              { validator: (rule, value) => {
                if (!form.getFieldsValue().configurations[name].permanent && !value?.toString().length && !form.getFieldsValue().configurations[name]?.count?.toString().length) {
                  return Promise.reject('One of configuration is required');
                } else {
                  return Promise.resolve();
                }
              }}
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              controls={false}
              type="number"
              disabled={form.getFieldsValue().configurations[name].permanent}
              placeholder="Please enter count of days"
            />
          </Form.Item>
        </>
      );
    case ProductType.TIME:
      return (
        <>
          <Form.Item
            {...restField}
            {...FormButtonLayout}
            valuePropName="checked"
            name={[name, 'permanent']}
            fieldKey={[fieldKey, 'permanent']}
          >
            <Checkbox
              onChange={(event) => {
                if (event.target.checked) {
                  form.setFields([
                    {
                      name: ["configurations", name, 'days'],
                      value: undefined,
                    }
                  ]);
                }
              }}
            >
              Permanent
            </Checkbox>
          </Form.Item>
          <Form.Item 
            {...restField}
            name={[name, 'days']}
            label="Days"
            fieldKey={[fieldKey, 'days']}
            rules={[{ required: !form.getFieldsValue().configurations[name].permanent, message: 'Missing count of days' }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              controls={false}
              type="number"
              disabled={form.getFieldsValue().configurations[name].permanent}
              placeholder="Please enter count of days"
            />
          </Form.Item>
        </>
      );
    case ProductType.NUMBER_LIMIT:
      return (
        <>
          <Form.Item
            {...restField}
            {...FormButtonLayout}
            valuePropName="checked"
            name={[name, 'permanent']}
            fieldKey={[fieldKey, 'permanent']}
          >
            <Checkbox
              onChange={(event) => {
                if (event.target.checked) {
                  form.setFields([
                    {
                      name: ["configurations", name, 'count'],
                      value: undefined,
                    }
                  ]);
                }
              }}
            >
              Permanent
            </Checkbox>
          </Form.Item>
          <Form.Item
            {...restField}
            name={[name, 'count']}
            label="Count of uses"
            fieldKey={[fieldKey, 'count']}
            rules={[{ required: !form.getFieldsValue().configurations[name].permanent, message: 'Missing count of uses' }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              controls={false}
              type="number"
              disabled={form.getFieldsValue().configurations[name].permanent}
              placeholder="Please enter count of uses"
            />
          </Form.Item>
        </>
      );
    default:
      return null;
  }
};

export {
  renderConfig,
  renderDynamicConfig,
}