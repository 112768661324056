import { useCallback, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, notification, Popconfirm, Tooltip } from 'antd';
import { Link, useHistory, useParams } from "react-router-dom";
import { Typography } from 'antd';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { selectDeleteProductStatus, selectProductById, selectSetEnableProductStatus } from 'store/product/productSelector';
import { deleteProduct, getProductById, setEnableProduct } from 'store/product/productThunk';
import { resetDeleteProductStatus, resetGetProductById, resetSetEnableProductStatus } from 'store/product/productSlice';
import { selectUserData } from 'store/user/userSelector';
import UserRole from 'core/shared/enum/UserRole';
import PRODUCT_TYPE_ENUM_LABEL from 'core/product/ProductTypeEnumLabel';
import PageContainer from 'components/layouts/PageContainer';

const { Title } = Typography;

const ProductProfileScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams<{ id: string }>()

  const product = useSelector(selectProductById)
  const setEnableProductStatus = useSelector(selectSetEnableProductStatus);
  const userData = useSelector(selectUserData);
  const deleteProductStatus = useSelector(selectDeleteProductStatus);

  const isDeleteSuccess = deleteProductStatus === RequestStatus.SUCCEESS;
  const isSetEnableProductLoading = setEnableProductStatus === RequestStatus.LOADING;
  const isSetEnableProductSuccess = setEnableProductStatus === RequestStatus.SUCCEESS;

  useEffect(() => {
    if (isSetEnableProductSuccess) {
      notification.success({
        message: `Success`,
        description: 'Product status changed successfully.',
        placement: 'bottomRight',
      });
      dispatch(resetSetEnableProductStatus());
      dispatch(getProductById(id));
    }
  }, [dispatch, isSetEnableProductSuccess, id]);

  useEffect(() => {
    if (isDeleteSuccess) {
      notification.success({
        key: 'product',
        message: `Success`,
        description: 'Product successfully deleted',
        placement: 'bottomRight',
      });
      dispatch(resetDeleteProductStatus());
      history.goBack();
    }
  }, [dispatch, history, isDeleteSuccess]);

  useEffect(() => {
    dispatch(getProductById(id));
    return () => {
      dispatch(resetGetProductById());
    }
  }, [dispatch, id]);

  const onClose = useCallback(() => {
    if (history.length < 3) {
      history.push("/products");
    } else {
      history.goBack();
    }
  }, [history]);

  return (
    <PageContainer title="Product Profile">
      {product &&
        <>
          <Profile>
            <Title level={3}>Product <b>{product.name}</b></Title>
            <Line>
              <Label>Product:</Label>
              <Info>{product.name || '<No info>'}</Info>
            </Line>
            {
              userData.userRole === UserRole.ROLE_SUPER_USER && (
                <Line>
                  <Label>Technical Code:</Label>
                  <Info>{product.technicalCode || '<No info>'}</Info>
                </Line>
              )
            }
            <Line>
              <Label>Usage Type:</Label>
              <Info>{PRODUCT_TYPE_ENUM_LABEL[product.usageType]}</Info>
            </Line>
            <Line>
              <Label>Base:</Label>
              <Info>{product.base ? 'Yes' : 'No'}</Info>
            </Line>
            <Line>
              <Label>Status:</Label>
              <Info><ActiveData isActive={!!product.active}>{!!product.active ? 'Active' : 'Not active'}</ActiveData></Info>
            </Line>
            <Line>
              <Label>Related exclusively to products:</Label>
              <Info>{product.productsRelatedTo.map(item => item.name).join(', ') || '<No info>'}</Info>
            </Line>
            <Line>
              <Label>Licenses count:</Label>
              <Info>{product.licenses}</Info>
            </Line>
          </Profile>
          <ButtonContainer>
            {userData.userRole === UserRole.ROLE_SUPER_USER && (
              <>
                <Link style={{ margin: '0 10px' }} to={`${product.id}/update`}>
                  <Button type="primary" size="middle">Update</Button>
                </Link>
                <Popconfirm
                  title={`Are you sure to delete this product?`}
                  onConfirm={() => dispatch(deleteProduct(product.id))}
                  okText="Yes"
                  cancelText="No"
                  disabled={!!product.licenses}
                >
                  <Tooltip
                    title={!!product.licenses ? "Product can't be deleted, due to already assigned licenses" : ""}
                    trigger="hover"
                    color="white"
                    overlayInnerStyle={{ color: "#000000d9", padding: "12px 16px", textAlign: "justify" }}
                  >
                    <Button
                      type="primary"
                      size="middle"
                      style={{ margin: '0 10px' }}
                      danger
                      disabled={!!product.licenses}
                    >
                      Delete
                    </Button>
                  </Tooltip>
                </Popconfirm>
                <Popconfirm
                  title={`Are you sure to ${product?.active ? 'deactivate' : 'activate'} this product?`}
                  onConfirm={() => dispatch(setEnableProduct(id, !product.active))}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    loading={isSetEnableProductLoading}
                    type="primary"
                    size="middle"
                    style={{ margin: '0 10px', backgroundColor: '#52C41A', color: 'white' }}
                  >
                    {product.active ? 'Deactivate' : 'Activate'}
                  </Button>
                </Popconfirm>
              </>
            )}
            <Button type="default" size="middle" style={{ margin: '0 10px' }} onClick={onClose}>Close</Button>
          </ButtonContainer>
        </>
      }
    </PageContainer>
  );
};

export default ProductProfileScreen;

const Profile = styled.div`
  width: 400px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ActiveData = styled.span<{ isActive: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? '#52C41A' : '#FF4D4F')}; 
`;

const ButtonContainer = styled.div`
  max-width: 400px;
  display: flex;
  justify-content: flex-end;
  margin: 10px auto;
`;

const Line = styled.div`
  display: flex;
`;

const Label = styled.div`
  width: 150px;
  font-weight: bold;
  line-height: 22px;
  border-bottom: 1px solid #F0F0F0;
  margin-right: 10px;
  padding: 10px 0 10px;
  text-align: right;
`;

const Info = styled.div`
  width: 240px;
  text-align: left;
  line-height: 22px;
  border-bottom: 1px solid black;
  padding: 10px 0 10px;
  border-bottom: 1px solid #F0F0F0;
  max-width: 240px;
  line-break: auto;
  word-break: break-word;
`;
