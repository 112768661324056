import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { PageHeader, Layout, Table, Space, Button, Form, Select, Radio } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import { Client } from 'core/client/Client';
import RequestStatus from 'core/shared/enum/RequestStatus';
import { selectClients, selectClientsItemsPerPage, selectClientsPage, selectClientsTotalItems, selectGetClientsStatus } from 'store/client/clientSelector';
import { selectAccountManagersBase, selectAccountManagersBaseStatus } from 'store/account-manager/accountManagerSelector';
import { getClients } from 'store/client/clientThunk';
import { resetGetClients, updateClientsItemsPerPage, updateClientsPage } from 'store/client/clientSlice';
import { useAppConfig } from 'components/context/AppConfigContext';
import { selectUserData } from 'store/user/userSelector';
import UserRole from 'core/shared/enum/UserRole';
import ActivityStatus from 'core/shared/enum/ActivityStatus';
import { getAccountManagersBase } from 'store/account-manager/accountManagerThunk';
import { resetGetAccountManagersBase } from 'store/account-manager/accountManagerSlice';
import { setClientStatusCF, setAccountManagerCF, setOrganizationCF, resetClientsFilters } from 'store/filter/filterSlice';
import { selectClientStatusCF, selectAccountManagerCF, selectOrganizationOF } from 'store/filter/filterSelector';
import { getOrganizationsBase } from 'store/organization/organizationThunk';
import { resetGetOrganizationsBase } from 'store/organization/organizationSlice';
import { selectOrganizationsBase, selectOrganizationsBaseStatus } from 'store/organization/organizationSelector';

const { Content } = Layout;

const getColumns = (role: OrNull<UserRole>): ColumnsType<Client> => {
  return [
    {
      title: 'Email (username)',
      dataIndex: 'username',
      render: (text, record) => (
        <Link to={`clients/${record.id}`}>{record.email}</Link>
      ),
      fixed: 'left',
    },
    {
      title: 'Name',
      render: (text, record) => (
        <span>{record.title} {record.firstName} {record.lastName}</span>
      ),
    },
    {
      title: 'Status',
      key: 'active',
      render: (text, record) => (
        <>
          <Space size="middle" style={{ wordBreak: "normal"}}>
            <ActiveData isActive={record.active}>{record.active ? 'Active' : 'Not active'}</ActiveData>
          </Space>
        </>
      ),
    },
    {
      title: 'Organization',
      dataIndex: 'Organization',
      render: (text, record) => (
        <Link to={`/organizations/${record.organization.id}`}>{record.organization.name}</Link>
      ),
    },
    {
      title: 'Managed by',
      dataIndex: 'managedBy',
      render: (text, record) => (
        <span>
          <Link to={`/account-managers/${record.organization.managedBy.id}`}>{record.organization.managedBy.firstName} {record.organization.managedBy.lastName}</Link>
          {' '}
          ({record.organization.managedBy.email})
        </span>
      ),
    },
    ...(role === UserRole.ROLE_ACCOUNT_MANAGER ? [
      {
        title: 'Action',
        key: 'action',
        width: '100px',
        render: (text: string, record: Client) => (
          <>
            <Space size="middle" style={{ wordBreak: "normal"}}>
              {
                role === UserRole.ROLE_ACCOUNT_MANAGER && (
                  <Link to={`clients/${record.id}/update`}>Update</Link>
                )
              }
            </Space>
          </>
        ),
      },
    ] : []),
  ];
};

const ClientsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { routes } = useAppConfig();

  const userData = useSelector(selectUserData);

  const filterSetting = userData.userRole === UserRole.ROLE_ACCOUNT_MANAGER ? "MY" : "ALL";

  const status = useSelector(selectClientStatusCF);
  const accountManager = useSelector(selectAccountManagerCF);
  const organizationId = useSelector(selectOrganizationOF);

  const accountManagersStatus = useSelector(selectAccountManagersBaseStatus);
  const accountManagers = useSelector(selectAccountManagersBase);

  const organizationsStatus = useSelector(selectOrganizationsBaseStatus);
  const organizations = useSelector(selectOrganizationsBase);

  const getClientsStatus = useSelector(selectGetClientsStatus);
  const clients = useSelector(selectClients);
  const clientsPage = useSelector(selectClientsPage);
  const clientsTotalItems = useSelector(selectClientsTotalItems);
  const clientsItemsPerPage = useSelector(selectClientsItemsPerPage);

  const clientsColumns = getColumns(userData.userRole);

  useEffect(() => {
    dispatch(getAccountManagersBase());
    dispatch(getOrganizationsBase());
    return () => {
      dispatch(resetGetAccountManagersBase());
      dispatch(resetGetOrganizationsBase());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!accountManager) {
      dispatch(setAccountManagerCF(filterSetting));
    }
  }, [dispatch, filterSetting, accountManager]);

  useEffect(() => {
    if (accountManager) {
      dispatch(getClients(accountManager, status, organizationId, clientsPage, clientsItemsPerPage));
    }
  }, [dispatch, status, accountManager, organizationId, clientsPage, clientsItemsPerPage]);

  useEffect(() => {
    return () => {
      dispatch(resetGetClients());
    }
  }, [dispatch]);

  const onPageChange = (page: number) => dispatch(updateClientsPage(page));

  return (
    <>
      <PageHeader
        title="Clients"
        ghost={false}
      >
        
      </PageHeader>
      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          backgroundColor: '#fff',
        }}
      >
        <StyledForm
          layout="horizontal"
        >
          <DropdownContainer>
            <Form.Item label="Account Managers">
              <Select
                value={accountManager}
                style={{ minWidth: '250px', maxWidth: '300px' }}
                onChange={(data) => {dispatch(updateClientsPage(1)); dispatch(setAccountManagerCF(data.toString()));}}
                loading={accountManagersStatus === RequestStatus.LOADING}
              >
                {userData.userRole === UserRole.ROLE_ACCOUNT_MANAGER && (
                  <Select.Option key="MY" value="MY">
                  Me
                  </Select.Option>
                )}
                <Select.Option key="ALL" value="ALL">
                  All
                </Select.Option>
                {accountManagers.map(item => (
                  <Select.Option key={item.id} value={item.id} style={{ color: item.active ? 'inherit' : '#FF4D4F'}}>
                    {item.firstName + ' ' + item.lastName}{!item.active && ' [not active]'}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Organization"
              style={{ marginBottom: '0' }}
            >
              <Select
                value={organizationId}
                style={{ minWidth: '250px', maxWidth: '300px' }}
                onChange={(data) => { dispatch(updateClientsPage(1)); dispatch(setOrganizationCF(data?.toString() || ''))}}
                loading={organizationsStatus === RequestStatus.LOADING}
              >
                <Select.Option key="ALL" value="ALL">
                  All
                </Select.Option>
                {organizations.map(item => (
                  <Select.Option key={item.id} value={item.id} style={{ color: item.active ? 'inherit' : '#FF4D4F'}}>
                    {item.name}{!item.active && ' [not active]'}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              style={{ marginTop: '30px'}}
              type="dashed"
              onClick={() => {
                dispatch(updateClientsPage(1));
                dispatch(resetClientsFilters());
              }}
            >
              Clear filters
            </Button>
          </DropdownContainer>
          <div>
            <Form.Item label="Status">
              <Radio.Group
                value={status}
                onChange={(data) => { dispatch(updateClientsPage(1)); dispatch(setClientStatusCF(data.target.value))}}
              >
                <Radio.Button value={ActivityStatus.ACTIVE}>Active</Radio.Button>
                <Radio.Button value={ActivityStatus.ALL}>All</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
        </StyledForm>
      </Content>
      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          minHeight: 280,
          backgroundColor: '#fff',
        }}
      >
        <Table
          scroll={{ x: "max-content" }}
          title={() => (<TitleContainer>
            <h2>Clients</h2>
            {userData.userRole === UserRole.ROLE_ACCOUNT_MANAGER &&
              <Link to={routes.clientsCreate.path}>
                <Button
                  type="primary"
                  size="large"
                >
                  <PlusOutlined />
                  Add new
                </Button>
              </Link>
            }
          </TitleContainer>)}
          loading={getClientsStatus === RequestStatus.LOADING}
          rowKey={record => record.id}
          pagination={{
            defaultPageSize: clientsItemsPerPage,
            current: clientsPage,
            total: clientsTotalItems,
            onChange: onPageChange,
            showSizeChanger: true,
            onShowSizeChange: (current, size) => {dispatch(updateClientsPage(1)); dispatch(updateClientsItemsPerPage(size))},
            showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`
          }}
          columns={clientsColumns}
          dataSource={clients}
        />
      </Content>
    </>
  );
};

export default ClientsScreen;

const ActiveData = styled.span<{ isActive: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? '#52C41A' : '#FF4D4F')}; 
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DropdownContainer = styled.div`
  margin-right: 30px;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;

    & > div:first-of-type {
      margin: 0;
      margin-bottom: 20px;
    }
  }
`;