import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Form, notification } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import routes from 'config/Route';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { getSuperuserById, updateSuperuser } from 'store/superuser/superuserThunk';
import { resetGetSuperuserById, resetUpdateSuperuserStatus } from 'store/superuser/superuserSlice';
import { selectSuperuserActionsError, selectSuperuserActionsMessage, selectSuperuserById, selectUpdateSuperuserStatus } from 'store/superuser/superuserSelector';
import { SuperuserForm } from 'core/superuser/Superuser';
import { FormButtonLayout, FormErrorLabelLayout, FormLayout } from 'config/constants';
import PageContainer from 'components/layouts/PageContainer';

const SuperuserUpdateScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const superuser = useSelector(selectSuperuserById);
  const superuserActionsMessage = useSelector(selectSuperuserActionsMessage);
  const superuserUpdateError = useSelector(selectSuperuserActionsError);
  const superuserUpdateStatus = useSelector(selectUpdateSuperuserStatus);
  const isUpdateLoading = superuserUpdateStatus === RequestStatus.LOADING;
  const isUpdateSuccess = superuserUpdateStatus === RequestStatus.SUCCEESS;

  const { id } = useParams<{ id: string }>();

  const onFinish = async (values: SuperuserForm) => {
    dispatch(updateSuperuser(values, id))
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      notification.success({
        key: 'superuser',
        message: `Success`,
        description: 'Superuser successfully updated',
        placement: 'bottomRight',
      });
      if (superuserActionsMessage) {
        notification.warning({
          key: 'superuserWarning',
          message: `Email notification was failed`,
          description: superuserActionsMessage,
          placement: 'bottomRight',
        });
      }
      history.push(routes.superusers.path);
    }
  }, [history, isUpdateSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  
  useEffect(() => {
    dispatch(getSuperuserById(id));
    return () => {
      dispatch(resetUpdateSuperuserStatus());
      dispatch(resetGetSuperuserById());
    }
  }, [dispatch, id]);

  return (
    <PageContainer title="Superser Update">
      {
        superuser && (
          <Container>
            <Form
              initialValues={superuser}
              {...FormLayout}
              layout="horizontal"
              onFinish={onFinish}
            >
              <Form.Item rules={[{ required: true }]} name="username" label="Username">
                <Input placeholder="Please enter username" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    // eslint-disable-next-line
                    pattern: new RegExp("^[A-Za-z0-9\"\'!#$%&()*+,-./:;<=>?@^_`{|}~]*$"),
                    message: "Password should contain only latin letters, numbers and special characters!"
                  },
                  {
                    pattern: new RegExp("(?=.*[A-Z])"),
                    message: "At least one uppercase letter required!"
                  },
                  {
                    pattern: new RegExp("(?=.*[a-z])"),
                    message: "At least one lowercase letter required!"
                  },
                  {
                    // eslint-disable-next-line
                    pattern: new RegExp("(?=.*[\"\'!#$%&()*+,-./:;<=>?@^_`{|}~])"),
                    message: "At least one special character required!"
                  },
                  {
                    pattern: new RegExp(".*[0-9].*"),
                    message: "At least one digit required!"
                  },
                  {
                    min: 8,
                    message: "At least 8 symbols required!"
                  },
                ]}
                name="password"
                label="Password"
              >
                <Input.Password
                  autoComplete="new-password"
                  placeholder="Please enter password"
                  visibilityToggle={true}
                />
              </Form.Item>
              <Form.Item rules={[{ required: true }]} name="email" label="Email">
                <Input type="email" placeholder="Please enter email" />
              </Form.Item>
              <Form.Item rules={[{ required: true }]} name="firstName" label="First name">
                <Input placeholder="Please enter first name" />
              </Form.Item>
              <Form.Item rules={[{ required: true }]} name="lastName" label="Last name">
                <Input placeholder="Please enter last name" />
              </Form.Item>
              <Form.Item rules={[{ required: false }]} name="contactAddress" label="Contact address (optional)">
                <Input placeholder="Please enter your contact address" />
              </Form.Item>
              <Form.Item rules={[{ required: false }]} name="phoneNumber" label="Phone number (optional)">
                <Input placeholder="Please enter your phone number" />
              </Form.Item>
              <Form.Item {...FormErrorLabelLayout}>
                <ErrorLabel>{superuserUpdateError}</ErrorLabel>
              </Form.Item>
              <Form.Item {...FormButtonLayout}>
                <Button loading={isUpdateLoading} style={{ marginRight: '10px' }} htmlType="submit" type="primary">Update</Button>
                <Button type="default" onClick={() => history.goBack()}>Cancel</Button>
              </Form.Item>
            </Form>
          </Container>
        )
      }
    </PageContainer>
  );
};

export default SuperuserUpdateScreen;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.div`
  color: #ff4d4f;
`;