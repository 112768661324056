import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from 'store/user/userSlice';
import superuserReducer from 'store/superuser/superuserSlice';
import productReducer from 'store/product/productSlice';
import accountManagerReducer from 'store/account-manager/accountManagerSlice';
import organizationReducer from 'store/organization/organizationSlice';
import clientReducer from 'store/client/clientSlice';
import licenseReducer from 'store/license/licenseSlice';
import filterReducer from 'store/filter/filterSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    superuser: superuserReducer,
    product: productReducer,
    organization: organizationReducer,
    accountManager: accountManagerReducer,
    client: clientReducer,
    license: licenseReducer,
    filter: filterReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
