import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { PageHeader, Layout, Table, Button, Select, Space, Form, Radio } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import { useAppConfig } from 'components/context/AppConfigContext';
import RequestStatus from 'core/shared/enum/RequestStatus';
import ActivityStatus from 'core/shared/enum/ActivityStatus';
import { getLicenses } from 'store/license/licenseThunk';
import { License } from 'core/license/License';
import { selectGetLicensesStatus, selectGetLicenses, selectLicensesPage, selectLicensesTotalItems, selectLicensesItemsPerPage } from 'store/license/licenseSelector';
import { selectOrganizationsBase, selectOrganizationsBaseStatus } from 'store/organization/organizationSelector';
import { selectAccountManagersBase, selectAccountManagersBaseStatus } from 'store/account-manager/accountManagerSelector';
import { selectGetProductsBaseStatus, selectProductsBase } from 'store/product/productSelector';
import { getOrganizationsBase } from 'store/organization/organizationThunk';
import { selectUserData } from 'store/user/userSelector';
import UserRole from 'core/shared/enum/UserRole';
import { resetGetLicenses, updateLicensesItemsPerPage, updateLicensesPage } from 'store/license/licenseSlice';
import { getAccountManagersBase } from 'store/account-manager/accountManagerThunk';
import { resetGetAccountManagersBase } from 'store/account-manager/accountManagerSlice';
import { resetGetOrganizationsBase } from 'store/organization/organizationSlice';
import { selectLicenseStatusLF, selectAccountManagerLF, selectOrganizationLF, selectProductLF } from 'store/filter/filterSelector';
import { setLicenseStatusLF, setAccountManagerLF, setOrganizationLF, setProductLF, resetLicenseFilters } from 'store/filter/filterSlice';
import { getProductsBase } from 'store/product/productThunk';
import { resetGetProductsBase } from 'store/product/productSlice';

const columns: ColumnsType<License> = [
  {
    title: 'Organization',
    dataIndex: 'Organization',
    render: (text, record) => (
      <Link to={`/organizations/${record.organization.id}`}>{record.organization.name}</Link>
    ),
    fixed: 'left',
  },
  {
    title: 'License Name',
    dataIndex: 'name',
    render: (text, record) => (
      <Link to={`licenses/${record.id}`}>{record.name}</Link>
    ),
    fixed: 'left',
  },
  {
    title: 'License Description',
    dataIndex: 'description',
    render: (text, record) => (
      <DescriptionInfo>
        <span>{text}</span>
      </DescriptionInfo>
    ),
  },
  {
    title: 'Status',
    key: 'active',
    render: (text, record) => (
      <>
        <Space size="middle" style={{ wordBreak: "normal"}}>
          <ActiveData isActive={record.active}>{record.active ? 'Active' : 'Not active'}</ActiveData>
        </Space>
      </>
    ),
  },
  {
    title: 'Configuration',
    dataIndex: 'configuration',
      render: (text, record) => (
        <div>
          <ProductType>{record.baseConfiguration.productName}{' '}</ProductType>
          (<span>
            {!record.baseConfiguration.count && !record.baseConfiguration.days && <span>Permanent</span>}
            {record.baseConfiguration.count && <span>Count of uses: {record.baseConfiguration.count};</span>}
            {record.baseConfiguration.count && record.baseConfiguration.days && ' '}
            {record.baseConfiguration.days && <span>Days: {record.baseConfiguration.days};</span>}
          </span>)
          <span>
            {
              record.configurations.map((config, index) => (
                <div key={index}>
                  <ProductType>+ {config.productName}{' '}</ProductType>
                  (<span>
                    {!config.count && !config.days && <span>Permanent</span>}
                    {config.count && <span>Count of uses: {config.count};</span>}
                    {config.count && config.days && ' '}
                    {config.days && <span>Days: {config.days};</span>}
                  </span>)
                </div>
              ))
            }
          </span>
        </div>
      ),
  },
  {
    title: 'Download Count',
    dataIndex: 'downloadsCount',
    key: 'downloadsCount',
  },
];

const { Content } = Layout;

const LicenseScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { routes } = useAppConfig();

  const userData = useSelector(selectUserData);

  const filterSetting = userData.userRole === UserRole.ROLE_ACCOUNT_MANAGER ? "MY" : "ALL";

  const status = useSelector(selectLicenseStatusLF);
  const accountManagerId = useSelector(selectAccountManagerLF);
  const organizationId = useSelector(selectOrganizationLF);
  const productId = useSelector(selectProductLF);

  const getLicensesStatus = useSelector(selectGetLicensesStatus);
  const licenses = useSelector(selectGetLicenses);
  const licensesPage = useSelector(selectLicensesPage);
  const licensesTotalItems = useSelector(selectLicensesTotalItems);
  const licensesItemsPerPage = useSelector(selectLicensesItemsPerPage);

  const accountManagersStatus = useSelector(selectAccountManagersBaseStatus);
  const accountManagers = useSelector(selectAccountManagersBase);

  const organizationsStatus = useSelector(selectOrganizationsBaseStatus);
  const organizations = useSelector(selectOrganizationsBase);

  const productsStatus = useSelector(selectGetProductsBaseStatus);
  const products = useSelector(selectProductsBase);

  useEffect(() => {
    dispatch(getAccountManagersBase());
    dispatch(getOrganizationsBase());
    dispatch(getProductsBase(ActivityStatus.ALL));
    return () => {
      dispatch(resetGetAccountManagersBase());
      dispatch(resetGetOrganizationsBase());
      dispatch(resetGetProductsBase());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!accountManagerId) {
      dispatch(setAccountManagerLF(filterSetting));
    }
  }, [dispatch, filterSetting, accountManagerId]);

  useEffect(() => {
    if (accountManagerId) {
      dispatch(getLicenses(accountManagerId, status, organizationId, productId, licensesPage, licensesItemsPerPage));
    }
  }, [dispatch, accountManagerId, status, organizationId, productId, licensesPage, licensesItemsPerPage]);

  useEffect(() => {
    return () => {
      dispatch(resetGetLicenses())
    }
  }, [dispatch]);
  
  const onPageChange = (page: number) => dispatch(updateLicensesPage(page));

  return (
    <>
      <PageHeader
        title="Licenses"
        ghost={false}
      >
        
      </PageHeader>
      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          backgroundColor: '#fff',
        }}
      >
        <StyledForm
          layout="horizontal"
        >
          <div style={{ marginRight: '30px' }}>
            <Form.Item
              label="Organization"
            >
              <Select
                style={{ minWidth: '250px', maxWidth: '300px' }}
                onChange={(data) => { dispatch(updateLicensesPage(1)); dispatch(setOrganizationLF(data?.toString() || ''))}}
                loading={organizationsStatus === RequestStatus.LOADING}
                value={organizationId}
              >
                <Select.Option key="ALL" value="ALL">
                  All
                </Select.Option>
                {organizations.map(item => (
                  <Select.Option key={item.id} value={item.id} style={{ color: item.active ? 'inherit' : '#FF4D4F'}}>
                    {item.name}{!item.active && ' [not active]'}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Account Managers"
              style={{ marginBottom: '0' }}
            >
              <Select
                style={{ minWidth: '250px', maxWidth: '300px' }}
                onChange={(data) => { dispatch(updateLicensesPage(1)); dispatch(setAccountManagerLF(data.toString()))}}
                loading={accountManagersStatus === RequestStatus.LOADING}
                value={accountManagerId}
              >
                {userData.userRole === UserRole.ROLE_ACCOUNT_MANAGER && (
                  <Select.Option key="MY" value="MY">
                  Me
                  </Select.Option>
                )}
                <Select.Option key="ALL" value="ALL">
                  All
                </Select.Option>
                {accountManagers.map(item => (
                  <Select.Option key={item.id} value={item.id} style={{ color: item.active ? 'inherit' : '#FF4D4F'}}>
                    {item.firstName + ' ' + item.lastName}{!item.active && ' [not active]'}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              style={{ marginTop: '30px'}}
              type="dashed"
              onClick={() => {
                dispatch(updateLicensesPage(1));
                dispatch(resetLicenseFilters());
              }}
            >
              Clear filters
            </Button>
          </div>
          <div>
            <Form.Item
              label="Products"
            >
              <Select
                style={{ minWidth: '250px', maxWidth: '250px' }}
                onChange={(data) => { dispatch(updateLicensesPage(1)); dispatch(setProductLF(data.toString()))}}
                loading={productsStatus === RequestStatus.LOADING}
                value={productId}
              >
                <Select.Option key="ALL" value="ALL">
                  All
                </Select.Option>
                {products.map(item => (
                  <Select.Option key={item.id} value={item.id} style={{ color: item.active ? 'inherit' : '#FF4D4F'}}>
                    {item.name}{!item.active && ' [not active]'}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Status"
              style={{ marginBottom: '0' }}
            >
              <Radio.Group value={status} onChange={(data) => { dispatch(updateLicensesPage(1)); dispatch(setLicenseStatusLF(data.target.value))}}>
                <Radio.Button value={ActivityStatus.ACTIVE}>Active</Radio.Button>
                <Radio.Button value={ActivityStatus.ALL}>All</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
        </StyledForm>
      </Content>
      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          minHeight: 280,
          backgroundColor: '#fff',
        }}
      >
        <Table
          scroll={{ x: "max-content" }}
          title={() => (<TitleContainer>
            <h2>Licenses</h2>
            {userData.userRole === UserRole.ROLE_ACCOUNT_MANAGER &&
              <Link to={routes.licenseCreate.path}>
                <Button
                  type="primary"
                  size="large"
                >
                  <PlusOutlined />
                  Add new
                </Button>
              </Link>
            }
          </TitleContainer>)}
          loading={getLicensesStatus === RequestStatus.LOADING}
          rowKey={record => record.id}
          pagination={{
            defaultPageSize: licensesItemsPerPage,
            current: licensesPage,
            total: licensesTotalItems,
            onChange: onPageChange,
            showSizeChanger: true,
            onShowSizeChange: (current, size) => {dispatch(updateLicensesPage(1)); dispatch(updateLicensesItemsPerPage(size))},
            showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`
          }}
          columns={columns}
          dataSource={licenses}
        />
      </Content>
    </>
  );
};

export default LicenseScreen;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActiveData = styled.span<{ isActive: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? '#52C41A' : '#FF4D4F')}; 
`;

const ProductType = styled.span`
  font-weight: 500;
  font-style: italic;
`;

const DescriptionInfo = styled.div`
  line-break: auto;
  word-break: break-word;
  max-width: 400px;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;

    & > div:last-of-type {
      margin-bottom: 20px;
    }

    & > div:first-of-type {
      order: 2;
    }
  }
`;