import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { License } from 'core/license/License';
import { LicenseStatistic } from 'core/license/License';
import ItemsPerPage from 'core/shared/enum/ItemsPerPage';
import RequestStatus from 'core/shared/enum/RequestStatus';

import { LicensesState, LicenseByIdState, LicenseStatisticState, LicenseActionsState } from './licenseTypes';

const licensesInitialState: LicensesState = {
  getLicenseStatus: RequestStatus.IDLE,
  licenses: [],
  page: 1,
  totalItems: 0,
  itemsPerPage: ItemsPerPage.TEN,
  error: '',
};

export const licensesSlice = createSlice({
  name: 'licensesSlice',
  initialState: licensesInitialState,
  reducers: {
    getLicensesRequest: (state) => {
      state.getLicenseStatus = RequestStatus.LOADING;
    },
    getLicensesSucceess: (state, action: PayloadAction<{ licenses: License[], totalItems: number }>) => {
      const { payload } = action;
      state.getLicenseStatus = RequestStatus.SUCCEESS;
      state.licenses = payload.licenses;
      state.totalItems = payload.totalItems;
    },
    getLicensesFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.getLicenseStatus = RequestStatus.FAILURE;
    },
    updateLicensesPage: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.page = payload;
    },
    updateLicensesItemsPerPage: (state, action: PayloadAction<ItemsPerPage>) => {
      const { payload } = action;
      state.itemsPerPage = payload;
    },
    resetGetLicenses: (state) => {
      state.getLicenseStatus = RequestStatus.IDLE;
      state.licenses = [];
      state.error = '';
    },
  },
});

const licenseStatisticInitialState: LicenseStatisticState = {
  getLicenseStatisticStatus: RequestStatus.IDLE,
  statistic: null,
  page: 1,
  totalItems: 0,
  itemsPerPage: ItemsPerPage.TEN,
  error: '',
};

export const licenseStatisticSlice = createSlice({
  name: 'licenseStatisticSlice',
  initialState: licenseStatisticInitialState,
  reducers: {
    getLicenseStatisticRequest: (state) => {
      state.getLicenseStatisticStatus = RequestStatus.LOADING;
    },
    getLicenseStatisticSucceess: (state, action: PayloadAction<{ statistic: LicenseStatistic[], totalItems: number }>) => {
      const { payload } = action;
      state.getLicenseStatisticStatus = RequestStatus.SUCCEESS;
      state.statistic = payload.statistic
      state.totalItems = payload.totalItems;
    },
    getLicenseStatisticFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.getLicenseStatisticStatus = RequestStatus.FAILURE;
    },
    updateLicenseStatisticPage: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.page = payload;
    },
    updateLicenseStatisticItemsPerPage: (state, action: PayloadAction<ItemsPerPage>) => {
      const { payload } = action;
      state.itemsPerPage = payload;
    },
    resetGetLicenseStatistic: () => ({ ...licenseStatisticInitialState }),
  },
});

const licenseByIdInitialState: LicenseByIdState = {
  getLicenseByIdStatus: RequestStatus.IDLE,
  license: null,
  error: '',
};

export const licenseByIdSlice = createSlice({
  name: 'licenseByIdSlice',
  initialState: licenseByIdInitialState,
  reducers: {
    getLicenseByIdRequest: (state) => {
      state.getLicenseByIdStatus = RequestStatus.LOADING;
    },
    getLicenseByIdSucceess: (state, action: PayloadAction<License>) => {
      const { payload } = action;
      state.license = payload;
      state.getLicenseByIdStatus = RequestStatus.SUCCEESS;
    },
    getLicenseByIdFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.getLicenseByIdStatus = RequestStatus.FAILURE;
    },
    resetGetLicenseById: () => ({ ...licenseByIdInitialState }),
  },
});

const licenseActionsInitialState: LicenseActionsState = {
  setEnableLicenseStatus: RequestStatus.IDLE,
  createLicenseStatus: RequestStatus.IDLE,
  updateLicenseStatus: RequestStatus.IDLE,
  deleteLicenseStatus: RequestStatus.IDLE,
  message: '',
  error: '',
};

export const licenseActionsSlice = createSlice({
  name: 'licenseActionsSlice',
  initialState: licenseActionsInitialState,
  reducers: {
    setEnableLicenseRequest: (state) => {
      state.setEnableLicenseStatus = RequestStatus.LOADING;
    },
    setEnableLicenseSucceess: (state) => {
      state.setEnableLicenseStatus = RequestStatus.SUCCEESS;
    },
    setEnableLicenseFailure: (state) => {
      state.setEnableLicenseStatus = RequestStatus.FAILURE;
    },
    resetSetEnableLicenseStatus: (state) => {
      state.setEnableLicenseStatus = RequestStatus.IDLE;
    },
    createLicenseRequest: (state) => {
      state.createLicenseStatus = RequestStatus.LOADING;
      state.error = '';
      state.message = '';
    },
    createLicenseSucceess: (state, action: PayloadAction<{message: string}>) => {
      state.createLicenseStatus = RequestStatus.SUCCEESS;
      state.message = action.payload.message;
    },
    createLicenseFailure: (state, action: PayloadAction<string>) => {
      state.createLicenseStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetCreateLicenseStatus: (state) => {
      state.createLicenseStatus = RequestStatus.IDLE;
      state.error = '';
      state.message = '';
    },
    deleteLicenseRequest: (state) => {
      state.deleteLicenseStatus = RequestStatus.LOADING;
    },
    deleteLicenseSucceess: (state) => {
      state.deleteLicenseStatus = RequestStatus.SUCCEESS;
    },
    deleteLicenseFailure: (state, action: PayloadAction<string>) => {
      state.deleteLicenseStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetDeleteLicenseStatus: (state) => {
      state.deleteLicenseStatus = RequestStatus.IDLE;
      state.error = '';
    },
  },
});

export const {
  getLicensesRequest,
  getLicensesSucceess,
  getLicensesFailure,
  updateLicensesPage,
  updateLicensesItemsPerPage,
  resetGetLicenses,
} = licensesSlice.actions;

export const {
  getLicenseStatisticRequest,
  getLicenseStatisticSucceess,
  getLicenseStatisticFailure,
  updateLicenseStatisticPage,
  updateLicenseStatisticItemsPerPage,
  resetGetLicenseStatistic
} = licenseStatisticSlice.actions;

export const {
  getLicenseByIdRequest,
  getLicenseByIdSucceess,
  getLicenseByIdFailure,
  resetGetLicenseById
} = licenseByIdSlice.actions;

export const {
  setEnableLicenseRequest,
  setEnableLicenseSucceess,
  setEnableLicenseFailure,
  resetSetEnableLicenseStatus,
  createLicenseRequest,
  createLicenseSucceess,
  createLicenseFailure,
  resetCreateLicenseStatus,
  deleteLicenseRequest,
  deleteLicenseSucceess,
  deleteLicenseFailure,
  resetDeleteLicenseStatus,
} = licenseActionsSlice.actions;

export default combineReducers({
  licenseList: licensesSlice.reducer,
  licenseById: licenseByIdSlice.reducer,
  licenseStatistic: licenseStatisticSlice.reducer,
  licenseActions: licenseActionsSlice.reducer
});
