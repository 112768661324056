import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';

import ActivityStatus from 'core/shared/enum/ActivityStatus';

import { OrganizationsFilter, ClientsFilter, LicensesFilter, ProductsFilter } from './filterTypes';

const productsFilterInitial: ProductsFilter = {
  productStatus: ActivityStatus.ACTIVE,
};

export const productsFilterSlice = createSlice({
  name: 'productsFilterSlice',
  initialState: productsFilterInitial,
  reducers: {
    setProductStatusPF: (state, action: PayloadAction<ActivityStatus>) => {
      state.productStatus = action.payload;
    },
    resetProductsFilters: () => ({ ...productsFilterInitial }),
  },
});

const organizationFilterInitial: OrganizationsFilter = {
  organizationStatus: ActivityStatus.ACTIVE,
  accountManager: '',
};

export const organizationsFilterSlice = createSlice({
  name: 'organizationFilterSlice',
  initialState: organizationFilterInitial,
  reducers: {
    setOrganizationStatusOF: (state, action: PayloadAction<ActivityStatus>) => {
      state.organizationStatus = action.payload;
    },
    setAccountManagerOF: (state, action: PayloadAction<string>) => {
      state.accountManager = action.payload;
    },
    resetOrganizationFilters: () => ({ ...organizationFilterInitial }),
  },
});

const clientsFilterInitial: ClientsFilter = {
  clientStatus: ActivityStatus.ACTIVE,
  accountManager: '',
  organization: 'ALL',
};

export const clientsFilterSlice = createSlice({
  name: 'clientFilterSlice',
  initialState: clientsFilterInitial,
  reducers: {
    setClientStatusCF: (state, action: PayloadAction<ActivityStatus>) => {
      state.clientStatus = action.payload;
    },
    setAccountManagerCF: (state, action: PayloadAction<string>) => {
      state.accountManager = action.payload;
    },
    setOrganizationCF: (state, action: PayloadAction<string>) => {
      state.organization = action.payload;
    },
    resetClientsFilters: () => ({ ...clientsFilterInitial }),
  },
});

const licensesFilterInitial: LicensesFilter = {
  licenseStatus: ActivityStatus.ACTIVE,
  accountManager: '',
  organization: 'ALL',
  product: 'ALL',
};

export const licensesFilterSlice = createSlice({
  name: 'licensesFilterSlice',
  initialState: licensesFilterInitial,
  reducers: {
    setLicenseStatusLF: (state, action: PayloadAction<ActivityStatus>) => {
      state.licenseStatus = action.payload;
    },
    setAccountManagerLF: (state, action: PayloadAction<string>) => {
      state.accountManager = action.payload;
    },
    setOrganizationLF: (state, action: PayloadAction<string>) => {
      state.organization = action.payload;
    },
    setProductLF: (state, action: PayloadAction<string>) => {
      state.product = action.payload;
    },
    resetLicenseFilters: () => ({ ...licensesFilterInitial }),
  },
});

export const {
  setProductStatusPF,
  resetProductsFilters,
} = productsFilterSlice.actions;

export const {
  setOrganizationStatusOF,
  setAccountManagerOF,
  resetOrganizationFilters,
} = organizationsFilterSlice.actions;

export const {
  setClientStatusCF,
  setAccountManagerCF,
  setOrganizationCF,
  resetClientsFilters,
} = clientsFilterSlice.actions;

export const {
  setLicenseStatusLF,
  setAccountManagerLF,
  setOrganizationLF,
  setProductLF,
  resetLicenseFilters,
} = licensesFilterSlice.actions;

export default combineReducers({
  organizationsFilter: organizationsFilterSlice.reducer,
  clientsFilter: clientsFilterSlice.reducer,
  licensesFilter: licensesFilterSlice.reducer,
  productsFilter: productsFilterSlice.reducer,
});
