import { AppThunk, AppDispatch } from 'store/store';
import {
  getOrganizationsRequest,
  getOrganizationsSucceess,
  getOrganizationsFailure,
  createOrganizationRequest,
  createOrganizationSucceess,
  createOrganizationFailure,
  getOrganizationByIdRequest,
  getOrganizationByIdSucceess,
  getOrganizationByIdFailure,
  setEnableOrganizationRequest,
  setEnableOrganizationSucceess,
  setEnableOrganizationFailure,
  updateOrganizationRequest,
  updateOrganizationSucceess,
  updateOrganizationFailure,
  deleteOrganizationRequest,
  deleteOrganizationSucceess,
  deleteOrganizationFailure,
  getOrganizationsBaseRequest,
  getOrganizationsBaseSucceess,
  getOrganizationsBaseFailure,
} from './organizationSlice';
import OrganizationService from 'services/api/OrganizationService';
import ActivityStatus from 'core/shared/enum/ActivityStatus';
import { OrganizationCreateForm, OrganizationUpdateForm } from 'core/organization/Organization';

const getOrganizationsBase = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getOrganizationsBaseRequest());
    const response = await OrganizationService.getOrganizationsBase();
    dispatch(getOrganizationsBaseSucceess({
      organizations: response.data,
    }));
  } catch (err) {
    dispatch(getOrganizationsBaseFailure(err.toString()));
  }
};

const getOrganizations = (accountManager: string, status: ActivityStatus, page: number, itemsPerPage: number): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getOrganizationsRequest());
    const response = await OrganizationService.getOrganizations(accountManager, status, page, itemsPerPage);
    dispatch(getOrganizationsSucceess({
      organizations: response.data.data,
      totalItems: response.data.totalItems
    }));
  } catch (err) {
    dispatch(getOrganizationsFailure(err.toString()));
  }
};

const getOrganizationById = (id: string): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getOrganizationByIdRequest());
    const response = await OrganizationService.getOrganizationById(id);
    dispatch(getOrganizationByIdSucceess(response.data));
  } catch (err) {
    dispatch(getOrganizationByIdFailure(err.toString()));
  }
};

const createOrganization = (data: OrganizationCreateForm) => async (dispatch: AppDispatch) => {
  try {
    dispatch((createOrganizationRequest()));
    const userId = localStorage.getItem('lms_user_id') || "";
    await OrganizationService.createOrganization({
      ...data,
      managedBy: userId,
    });
    dispatch(createOrganizationSucceess());
  } catch (error) {
    dispatch(createOrganizationFailure(error.message));
  }
};

const updateOrganization = (data: OrganizationUpdateForm, id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch((updateOrganizationRequest()));
    await OrganizationService.updateOrganization(data, id);
    dispatch(updateOrganizationSucceess());
  } catch (error) {
    dispatch(updateOrganizationFailure(error.message));
  }
};

const deleteOrganization = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(deleteOrganizationRequest());
    await OrganizationService.deleteOrganization(id);
    dispatch(deleteOrganizationSucceess());
  } catch (err) {
    dispatch(deleteOrganizationFailure(err.message));
  }
};

const setEnableOrganization = (id: string, isActive: boolean) => async (dispatch: AppDispatch) => {
  try {
    dispatch((setEnableOrganizationRequest()));
    await OrganizationService.setEnableOrganization(id, isActive);
    dispatch(setEnableOrganizationSucceess());
  } catch (err) {
    dispatch(setEnableOrganizationFailure());
  }
};

export {
  getOrganizations,
  getOrganizationsBase,
  getOrganizationById,
  createOrganization,
  updateOrganization,
  deleteOrganization,
  setEnableOrganization
};
