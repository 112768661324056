import { ProductForm } from 'core/product/Product';
import { AppThunk, AppDispatch } from 'store/store';
import {
  getProductsRequest,
  getProductsSucceess,
  getProductsFailure,
  getProductByIdRequest,
  getProductByIdSucceess,
  getProductByIdFailure,
  setEnableProductRequest,
  setEnableProductSucceess,
  setEnableProductFailure,
  createProductRequest,
  createProductSucceess,
  createProductFailure,
  updateProductRequest,
  updateProductSucceess,
  updateProductFailure,
  deleteProductRequest,
  deleteProductSucceess,
  deleteProductFailure,
  getProductsBaseRequest,
  getProductsBaseSucceess,
  getProductsBaseFailure,
} from './productSlice';
import ProductService from 'services/api/ProductService';
import ActivityStatus from 'core/shared/enum/ActivityStatus';

const getProducts = (status: ActivityStatus, page: number, itemsPerPage: number): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getProductsRequest());
    const response = await ProductService.getProducts(status, page, itemsPerPage);
    dispatch(getProductsSucceess({
      products: response.data.data,
      totalItems: response.data.totalItems
    }));
  } catch (err) {
    dispatch(getProductsFailure(err.toString()));
  }
};

const getProductsBase = (status: ActivityStatus): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getProductsBaseRequest);
    const response = await ProductService.getProductsBase(status);
    dispatch(getProductsBaseSucceess({ products: response.data }));
  } catch (err) {
    dispatch(getProductsBaseFailure(err.toString()));
  }
};

const getProductById = (id: string): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getProductByIdRequest());
    const response = await ProductService.getProductById(id);
    dispatch(getProductByIdSucceess(response.data));
  } catch (err) {
    dispatch(getProductByIdFailure(err.toString()));
  }
};

const updateProduct = (data: ProductForm, id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(updateProductRequest());
    await ProductService.updateProduct(data, id);
    dispatch(updateProductSucceess());
  } catch (err) {
    dispatch(updateProductFailure(err.message));
  }
};

const deleteProduct = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(deleteProductRequest());
    await ProductService.deleteProduct(id);
    dispatch(deleteProductSucceess());
  } catch (err) {
    dispatch(deleteProductFailure(err.message));
  }
};

const createProduct = (data: ProductForm) => async (dispatch: AppDispatch) => {
  try {
    dispatch((createProductRequest()));
    await ProductService.createProduct(data);
    dispatch(createProductSucceess());
  } catch (err) {
    dispatch(createProductFailure(err.message));
  }
};

const setEnableProduct = (id: string, isActive: boolean) => async (dispatch: AppDispatch) => {
  try {
    dispatch((setEnableProductRequest()));
    await ProductService.setEnableProduct(id, isActive);
    dispatch(setEnableProductSucceess());
  } catch (err) {
    dispatch(setEnableProductFailure());
  }
};

export {
  getProducts,
  getProductsBase,
  getProductById,
  createProduct,
  updateProduct,
  deleteProduct,
  setEnableProduct,
};
