import { useCallback, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import dateFormat from "dateformat";
import { useDispatch, useSelector } from 'react-redux';
import { Button, notification, Table, Popconfirm, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link, useHistory, useParams } from "react-router-dom";
import { Typography } from 'antd';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { deleteLicense, getLicenseById, getLicenseStatistic, setEnableLicense } from 'store/license/licenseThunk';
import { selectGetLicenseStatisticStatus, selectLicenseById, selectLicenseStatistic, selectSetEnableLicenseStatus, selectDeleteLicenseStatus, selectLicenseStatisticPage, selectLicenseStatisticTotalItems, selectLicenseStatisticPerPage } from 'store/license/licenseSelector';
import { resetDeleteLicenseStatus, resetGetLicenseById, resetGetLicenseStatistic, resetSetEnableLicenseStatus, updateLicenseStatisticItemsPerPage, updateLicenseStatisticPage } from 'store/license/licenseSlice';
import { LicenseStatistic } from 'core/license/License';
import PageContainer from 'components/layouts/PageContainer';

const { Title } = Typography;

const columns: ColumnsType<LicenseStatistic> = [
  {
    title: 'Downloaded Date',
    dataIndex: 'downloadedDate',
    render: (text, record) => (
      <span>
        {dateFormat(new Date(record.downloadedDate), "dd.mm.yyyy HH:MM:ss")}
      </span>
    ),
  },
  {
    title: 'User',
    dataIndex: 'user',
    render: (text, record) => (
      <span>
        <Link to={`/clients/${record.user.id}`}>{record.user.firstName} {record.user.lastName}</Link>
        {' '}
        ({record.user.email})
      </span>
    ),
  },
];

const LicenseProfileScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams<{ id: string }>()

  const license = useSelector(selectLicenseById)
  const licenseStatistic = useSelector(selectLicenseStatistic);
  const licenseStatisticPage = useSelector(selectLicenseStatisticPage);
  const licenseStatisticTotalItems = useSelector(selectLicenseStatisticTotalItems);
  const licenseStatisticPerPage = useSelector(selectLicenseStatisticPerPage);

  const getLicenseStatisticStatus = useSelector(selectGetLicenseStatisticStatus);
  const isLicenseStatisticLoading = getLicenseStatisticStatus === RequestStatus.LOADING;

  const setEnableLicenseStatus = useSelector(selectSetEnableLicenseStatus);
  const isSetEnableLicenseLoading = setEnableLicenseStatus === RequestStatus.LOADING;
  const isSetEnableLicenseSuccess = setEnableLicenseStatus === RequestStatus.SUCCEESS;

  const deleteLicenseStatus = useSelector(selectDeleteLicenseStatus);
  const isDeleteLicenseSuccess = deleteLicenseStatus === RequestStatus.SUCCEESS;

  const setActiveHandler = async (id: string, isActive: boolean) => {
    dispatch(setEnableLicense(id, isActive))
  };

  useEffect(() => {
    if (isSetEnableLicenseSuccess) {
      notification.success({
        message: `Success`,
        description: 'License status changed successfully.',
        placement: 'bottomRight',
      });
      dispatch(resetSetEnableLicenseStatus());
      dispatch(getLicenseById(id));
      dispatch(updateLicenseStatisticPage(1));
    }
  }, [dispatch, isSetEnableLicenseSuccess, id]);

  useEffect(() => {
    if (isDeleteLicenseSuccess) {
      notification.success({
        message: `Success`,
        description: 'License successfully deleted',
        placement: 'bottomRight',
      });
      dispatch(resetDeleteLicenseStatus());
      history.goBack();
    }
  }, [dispatch, history, isDeleteLicenseSuccess]);

  useEffect(() => {
    dispatch(getLicenseById(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getLicenseStatistic(id, licenseStatisticPage, licenseStatisticPerPage));
  }, [dispatch, id, licenseStatisticPage, licenseStatisticPerPage]);

  useEffect(() => {
    return () => {
      dispatch(resetGetLicenseById());
      dispatch(resetGetLicenseStatistic());
    }
  }, [dispatch]);

  const onPageChange = (page: number) => dispatch(updateLicenseStatisticPage(page));

  const onClose = useCallback(() => {
    if (history.length < 3) {
      history.push("/licenses");
    } else {
      history.goBack();
    }
  }, [history]);

  return (
    <PageContainer title="License Profile" contentMaxWidth={850}>
      {license &&
        <>
          <Profile>
            <Title level={3}>License <b>{license.name}</b></Title>
            <Line>
              <Label>Name:</Label>
              <Info>{license.name || '<No info>'}</Info>
            </Line>
            <Line>
              <Label>Description:</Label>
              <Info>{license.description || '<No info>'}</Info>
            </Line>
            <Line>
              <Label>Configuration:</Label>
              <Info>
                <ProductType>{license.baseConfiguration.productName}{' '}</ProductType>
                    (<span>
                  {!license.baseConfiguration.count && !license.baseConfiguration.days && <span>Permanent</span>}
                  {license.baseConfiguration.count && <span>Count of uses: {license.baseConfiguration.count};</span>}
                  {license.baseConfiguration.count && license.baseConfiguration.days && ' '}
                  {license.baseConfiguration.days && <span>Days: {license.baseConfiguration.days};</span>}
                </span>)
                    <span>
                  {
                    license.configurations.map((config, index) => (
                      <div key={index}>
                        <ProductType>+ {config.productName}{' '}</ProductType>
                          (<span>
                          {!config.count && !config.days && <span>Permanent</span>}
                          {config.count && <span>Count of uses: {config.count};</span>}
                          {config.count && config.days && ' '}
                          {config.days && <span>Days: {config.days};</span>}
                        </span>)
                      </div>
                    ))
                  }
                </span>
              </Info>
            </Line>
            <Line>
              <Label>Created by:</Label>
              <Info>
                {
                  !license.createdBy ? '<No info>' : (
                    <span>
                      <Link to={`/account-managers/${license.createdBy.id}`}>{license.createdBy.firstName} {license.createdBy.lastName}</Link>
                      {' '}({license.createdBy.email})
                    </span>
                  )
                }
              </Info>
            </Line>
            <Line>
              <Label>Status:</Label>
              <Info><ActiveData isActive={!!license.active}>{!!license.active ? 'Active' : 'Not active'}</ActiveData></Info>
            </Line>
            <Line>
              <Label>Generated by:</Label>
              <Info>
                {
                  !license.generatedBy ? '<No info>' : (
                    <span>
                      <Link to={`/clients/${license.generatedBy.id}`}>{license.generatedBy.firstName} {license.generatedBy.lastName}</Link>
                      {' '}({license.generatedBy.email})
                    </span>
                  )
                }
              </Info>
            </Line>
            <Line>
              <Label>Generation date:</Label>
              <Info>
                {
                  !license.generatedBy ? '<No info>' : (
                    <span>
                      {dateFormat(new Date(license.generationDate), "dd.mm.yyyy HH:MM:ss")}
                    </span>
                  )
                }
              </Info>
            </Line>
          </Profile>
          <ButtonContainer>
            <Popconfirm
              title={`Are you sure to delete this license?`}
              onConfirm={() => dispatch(deleteLicense(license.id))}
              okText="Yes"
              cancelText="No"
              disabled={license.active}
            >
              <Tooltip
                title={license.active ? "A License can be deleted if it was previously deactivated." : ""}
                trigger="hover"
                color="white"
                overlayInnerStyle={{ color: "#000000d9", padding: "12px 16px", textAlign: "justify" }}
              >
                <Button
                  type="primary"
                  size="middle"
                  danger
                  disabled={license.active}
                  style={{ margin: '0 10px' }}
                >
                  Delete
                  </Button>
              </Tooltip>
            </Popconfirm>
            <Popconfirm
              title={`Are you sure to ${license?.active ? 'deactivate' : 'activate'} this license?`}
              onConfirm={() => setActiveHandler(license.id, !license.active)}
              okText="Yes"
              cancelText="No"
            >
              <Button loading={isSetEnableLicenseLoading} type="primary" size="middle" style={{ margin: '0 10px', backgroundColor: '#52C41A', color: 'white' }}>
                {license.active ? 'Deactivate' : 'Activate'}
              </Button>
            </Popconfirm>
            <Button type="default" size="middle" style={{ margin: '0 10px' }} onClick={onClose}>Close</Button>
          </ButtonContainer>
        </>
      }
      <Table
        title={() => (
          <h2>License statistic</h2>
        )}
        loading={isLicenseStatisticLoading}
        rowKey={record => record.downloadedDate}
        pagination={{
          defaultPageSize: licenseStatisticPerPage,
          current: licenseStatisticPage,
          total: licenseStatisticTotalItems,
          onChange: onPageChange,
          showSizeChanger: true,
          onShowSizeChange: (current, size) => { dispatch(updateLicenseStatisticPage(1)); dispatch(updateLicenseStatisticItemsPerPage(size)) },
          showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`
        }}
        columns={columns}
        dataSource={licenseStatistic || []}
      />
    </PageContainer>
  );
};

export default LicenseProfileScreen;

const Profile = styled.div`
  width: 400px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ActiveData = styled.span<{ isActive: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? '#52C41A' : '#FF4D4F')}; 
`;

const ButtonContainer = styled.div`
  max-width: 400px;
  display: flex;
  justify-content: flex-end;
  margin: 10px auto;
`;

const Line = styled.div`
  display: flex;
`;

const Label = styled.div`
  width: 150px;
  font-weight: bold;
  line-height: 22px;
  border-bottom: 1px solid #F0F0F0;
  margin-right: 10px;
  padding: 10px 0 10px;
  text-align: right;
`;

const Info = styled.div`
  width: 240px;
  text-align: left;
  line-height: 22px;
  border-bottom: 1px solid black;
  padding: 10px 0 10px;
  border-bottom: 1px solid #F0F0F0;
  max-width: 240px;
  line-break: auto;
  word-break: break-word;
`;

const ProductType = styled.span`
  font-weight: 500;
  font-style: italic;
`;