import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';
import BaseLayout from 'components/layouts/BaseLayout';

const NotFoundScreen: React.FC = () => (
  <BaseLayout>
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Link style={{ margin: '10px 10px' }} replace to="/">
          <Button type="primary">Home Page</Button>
        </Link>
      }
    />
  </BaseLayout>
);

export default NotFoundScreen;
