import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import RouteType from 'core/shared/enum/RouteType';
import { useAppConfig } from 'components/context/AppConfigContext';

import UserRole from 'core/shared/enum/UserRole';

interface IAuthRoute {
  type: RouteType;
  isAuth: boolean;
  path: string | string[];
  exact: boolean;
  userRole?: UserRole;
  requiredRoles? : UserRole[];
  component: React.ComponentType;
}

interface LocationState {
  from?: string
};

const AuthRoute: React.FC<IAuthRoute> = ({ type, isAuth, component, path, exact, userRole, requiredRoles = [] }) => {
  const { routes } = useAppConfig();
  const { state, pathname } = useLocation();
  
  const from = (state as LocationState)?.from || "";

  if (type === RouteType.GUEST && isAuth) return <Redirect to={{pathname: routes.index.path, state: { from: from }}} />;
  if (type === RouteType.PRIVATE && !isAuth) return <Redirect to={{pathname: routes.signin.path, state: { from: pathname }}} />;

  if (userRole && requiredRoles.length && !requiredRoles.includes(userRole)) {
    if (userRole === UserRole.ROLE_ACCOUNT_MANAGER) return <Redirect to={routes.products.path} />;
    if (userRole === UserRole.ROLE_SUPER_USER) return <Redirect to={routes.superusers.path} />;
  }

  return <Route path={path} exact={exact} component={component} />;
};
export default AuthRoute;
