import { RootState } from 'store/store';

export const selectGetClientsStatus = (state: RootState) => state.client.clientList.getClientsStatus;
export const selectClients = (state: RootState) => state.client.clientList.clients;
export const selectClientsPage = (state: RootState) => state.client.clientList.page;
export const selectClientsTotalItems = (state: RootState) => state.client.clientList.totalItems;
export const selectClientsItemsPerPage = (state: RootState) => state.client.clientList.itemsPerPage;
export const selectGetCliensError = (state: RootState) => state.client.clientList.error;

export const selectGetClientByIdStatus = (state: RootState) => state.client.clientById.getClientByIdStatus;
export const selectClientById = (state: RootState) => state.client.clientById.client;
export const selectGetClienByIdError = (state: RootState) => state.client.clientById.error;

export const selectAccountManagerData = (state: RootState) => state.client.clientActions.accountManagerData;
export const selectisAccountManagerExistsStatus = (state: RootState) => state.client.clientActions.createClientStatus;

export const selectSetEnableClientStatus = (state: RootState) => state.client.clientActions.setEnableClientStatus;
export const selectCreateClientStatus = (state: RootState) => state.client.clientActions.createClientStatus;
export const selectUpdateClientStatus = (state: RootState) => state.client.clientActions.updateClientStatus;
export const selectDeleteClientStatus = (state: RootState) => state.client.clientActions.deleteClientStatus;
export const selectClientActionsError = (state: RootState) => state.client.clientActions.error;
export const selectClientActionsMessage = (state: RootState) => state.client.clientActions.message;