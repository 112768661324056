import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { Client } from 'core/client/Client';
import { AccountManager } from 'core/account-manager/AccountManager';

import { ClientsState, ClientByIdState, ClientActionsState } from './clientTypes';
import { ClientById } from 'core/client/Client';
import ItemsPerPage from 'core/shared/enum/ItemsPerPage';

const clientsInitialState: ClientsState = {
  getClientsStatus: RequestStatus.IDLE,
  clients: [],
  page: 1,
  totalItems: 0,
  itemsPerPage: ItemsPerPage.TEN,
  error: '',
};

export const clientsSlice = createSlice({
  name: 'clientsSlice',
  initialState: clientsInitialState,
  reducers: {
    getClientsRequest: (state) => {
      state.getClientsStatus = RequestStatus.LOADING;
    },
    getClientsSucceess: (state, action: PayloadAction<{ clients: Client[], totalItems: number }>) => {
      const { payload } = action;
      state.getClientsStatus = RequestStatus.SUCCEESS;
      state.clients = payload.clients;
      state.totalItems = payload.totalItems;
    },
    getClientsFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.getClientsStatus = RequestStatus.FAILURE;
    },
    updateClientsPage: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.page = payload;
    },
    updateClientsItemsPerPage: (state, action: PayloadAction<ItemsPerPage>) => {
      const { payload } = action;
      state.itemsPerPage = payload;
    },
    resetGetClients: (state) => {
      state.getClientsStatus = RequestStatus.IDLE;
      state.clients = [];
      state.error = '';
    },
  },
});

const clientByIdInitialState: ClientByIdState = {
  getClientByIdStatus: RequestStatus.IDLE,
  client: null,
  error: '',
};

export const clientByIdSlice = createSlice({
  name: 'clientByIdSlice',
  initialState: clientByIdInitialState,
  reducers: {
    getClientByIdRequest: (state) => {
      state.getClientByIdStatus = RequestStatus.LOADING;
    },
    getClientByIdSucceess: (state, action: PayloadAction<ClientById>) => {
      const { payload } = action;
      state.client = payload;
      state.getClientByIdStatus = RequestStatus.SUCCEESS;
    },
    getClientByIdFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.getClientByIdStatus = RequestStatus.FAILURE;
    },
    resetGetClientById: () => ({ ...clientByIdInitialState }),
  },
});

const clientActionsInitialState: ClientActionsState = {
  isAccountManagerExistsStatus: RequestStatus.IDLE,
  accountManagerData: null,
  setEnableClientStatus: RequestStatus.IDLE,
  createClientStatus: RequestStatus.IDLE,
  updateClientStatus: RequestStatus.IDLE,
  deleteClientStatus: RequestStatus.IDLE,
  message: '',
  error: '',
};

export const clientActionsSlice = createSlice({
  name: 'clientActionsSlice',
  initialState: clientActionsInitialState,
  reducers: {
    setEnableClientRequest: (state) => {
      state.setEnableClientStatus = RequestStatus.LOADING;
    },
    setEnableClientSucceess: (state) => {
      state.setEnableClientStatus = RequestStatus.SUCCEESS;
    },
    setEnableClientFailure: (state) => {
      state.setEnableClientStatus = RequestStatus.FAILURE;
    },
    resetSetEnableClientStatus: (state) => {
      state.setEnableClientStatus = RequestStatus.IDLE;
    },
    createClientRequest: (state) => {
      state.createClientStatus = RequestStatus.LOADING;
      state.error = '';
      state.message = '';
    },
    createClientSucceess: (state, action: PayloadAction<{ message: string}>) => {
      state.createClientStatus = RequestStatus.SUCCEESS;
      state.message = action.payload.message;
    },
    createClientFailure: (state, action: PayloadAction<string>) => {
      state.createClientStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetCreateClientStatus: (state) => {
      state.createClientStatus = RequestStatus.IDLE;
      state.error = '';
      state.message = '';
    },
    isAccountManagerExistsRequest: (state) => {
      state.isAccountManagerExistsStatus = RequestStatus.LOADING;
    },
    isAccountManagerExistsSucceess: (state, action: PayloadAction<AccountManager>) => {
      state.isAccountManagerExistsStatus = RequestStatus.SUCCEESS;
      state.accountManagerData = action.payload;
    },
    isAccountManagerExistsFailure: (state, action: PayloadAction<string>) => {
      state.isAccountManagerExistsStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetIsAccountManagerExists: (state) => {
      state.isAccountManagerExistsStatus = RequestStatus.IDLE;
      state.accountManagerData = null;
      state.error = '';
    },
    updateClientRequest: (state) => {
      state.updateClientStatus = RequestStatus.LOADING;
      state.error = '';
      state.message = '';
    },
    updateClientSucceess: (state, action: PayloadAction<{message: string}>) => {
      state.updateClientStatus = RequestStatus.SUCCEESS;
      state.message = action.payload.message;
    },
    updateClientFailure: (state, action: PayloadAction<string>) => {
      state.updateClientStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetUpdateClientStatus: (state) => {
      state.updateClientStatus = RequestStatus.IDLE;
      state.error = '';
      state.message = '';
    },
    deleteClientRequest: (state) => {
      state.deleteClientStatus = RequestStatus.LOADING;
    },
    deleteClientSucceess: (state) => {
      state.deleteClientStatus = RequestStatus.SUCCEESS;
    },
    deleteClientFailure: (state, action: PayloadAction<string>) => {
      state.deleteClientStatus = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    resetDeleteClientStatus: (state) => {
      state.deleteClientStatus = RequestStatus.IDLE;
      state.error = '';
    },
  },
});

export const {
  getClientsRequest,
  getClientsSucceess,
  getClientsFailure,
  updateClientsPage,
  updateClientsItemsPerPage,
  resetGetClients,
} = clientsSlice.actions;

export const {
  getClientByIdRequest,
  getClientByIdSucceess,
  getClientByIdFailure,
  resetGetClientById,
} = clientByIdSlice.actions;

export const {
  setEnableClientRequest,
  setEnableClientSucceess,
  setEnableClientFailure,
  resetSetEnableClientStatus,
  createClientRequest,
  createClientSucceess,
  createClientFailure,
  resetCreateClientStatus,
  isAccountManagerExistsRequest,
  isAccountManagerExistsSucceess,
  isAccountManagerExistsFailure,
  resetIsAccountManagerExists,
  updateClientRequest,
  updateClientSucceess,
  updateClientFailure,
  resetUpdateClientStatus,
  deleteClientRequest,
  deleteClientSucceess,
  deleteClientFailure,
  resetDeleteClientStatus
} = clientActionsSlice.actions;

export default combineReducers({
  clientList: clientsSlice.reducer,
  clientById: clientByIdSlice.reducer,
  clientActions: clientActionsSlice.reducer
});
